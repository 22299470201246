import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import PrimaryButton from "../button/PrimaryButton";
import SecondaryButton from "../button/SecondaryButton";
import CircleExit from "../../assets/images/icon/CircleExit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import BottomNavigation from "@mui/material/BottomNavigation";
import CustomSwitch from "../../components/button/CustomSwitch";
import { SearchId } from "../../services/SearchId";
import {
  SearchPetOwnerProfile,
  postUpdatePetOwnerProfile,
} from "../../services/Dogbook";
import { useNavigate, useLocation } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { useLiff } from "react-liff";

import PolicyPopup from "./PolicyPopup";

export default function CookieSettingPopup(props) {
  const navigate = useNavigate();
  const [cookie, setCookie] = useState(false);
  const [isTerm, setIsTerm] = useState(false);
  let [memberId, setMemberId] = useState(null);
  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  const [policy, setPolicy] = React.useState("cookie");
  const [open2, setOpen2] = React.useState(false);

  useEffect(() => {
    if (props["open"]) {
      getSearchId();
    }
    //
  }, [props["open"]]);

  async function getSearchId() {
    return await SearchId({
      params: { lineId: lineId || null },
    })
      .then(async (response) => {
        if (!response.data.body.memberId) {
          navigate("/register");
        } else {
          getOwnerProfile(response.data.body.memberId);
          setMemberId(response.data.body.memberId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getOwnerProfile(member_id) {
    return await SearchPetOwnerProfile({
      params: { lineId: lineId || null },
      member_id: member_id,
    })
      .then(async (response) => {
        const resBody = response.data?.body;
        setCookie(!!resBody.cookie);
        setIsTerm(!!resBody.isTerm);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function postUpdatePetDetail(allowAll = false) {
    const payload = {
      memberId: memberId,
      cookie: allowAll || cookie,
      isTerm: allowAll || isTerm,
    };

    try {
      const { data } = await postUpdatePetOwnerProfile({ data: payload });
      console.log("resData.data >>", data);
      if (data.resultCode === 20000) {
        onClose(selectedValue);
      }
    } catch (error) {
      console.log("postUpdatePetOwnerProfile error", error);
    }
  }

  const {
    onClose,
    selectedValue,
    open,
    // confirmType = true,
  } = props;

  const handleClose = () => {
    onClose(selectedValue);
    postUpdatePetDetail();
  };

  const policyHandleYes = (value) => {
    if (value === "cookie") {
      setCookie(true);
    } else {
      setIsTerm(true);
    }
  };

  const policyHandleNo = (value) => {
    if (value === "cookie") {
      setCookie(false);
    } else {
      setIsTerm(false);
    }
  };

  const listCookie = (txt1 = "", txt2 = "", value, setValue, viewpage) => {
    return (
      <div className="px-[17px] pt-[10px] pb-[24px] flex justify-between">
        <div>
          <p className="text-darkGray text-[20px] font-medium leading-[120%]">
            {txt1}
          </p>
          <p
            className="text-blue text-[18px] leading-[120%]"
            onClick={() => {
              if (viewpage === "cookie") {
                setPolicy("cookie");
              } else {
                setPolicy("term");
              }
              setOpen2(true);
            }}
          >
            {txt2}
          </p>
        </div>
        <div>
          <CustomSwitch
            checked={value}
            handleFunction={() => setValue(!value)}
          />
        </div>
      </div>
    );
  };
  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="xl">
      {/* cookie >> {cookie?'AA':'BB'}<br/> */}
      {/* isTerm >> {isTerm?'AA':'BB'} */}
      <div className="pb-[16px] pt-[36px] px-[17px] rounded-[4px] flex justify-between">
        <div>
          <p className="text-lightBlue text-[28px] text-left leading-[33.6px]">
            ตั้งค่านโยบายความเป็นส่วนตัว
          </p>
          <p className="text-lightBlue text-[28px] text-left leading-[33.6px]">
            และคุกกี้
          </p>
        </div>
        <div>
          <button onClick={handleClose}>
            <CircleExit />
          </button>
        </div>
      </div>
      <div className="bg-lightGray2 min-h-[334px]">
        {listCookie(
          "ยอมรับนโยบายความเป็นส่วนตัว",
          "อ่านรายละเอียดนโยบายความเป็นส่วนตัว",
          isTerm,
          setIsTerm,
          "term"
        )}
        <div className="px-[17px]">
          <hr className="border-b-1px border-lightGray w-full" />
        </div>
        {listCookie(
          "ยอมรับนโยบายการใช้คุกกี้",
          "อ่านรายละเอียดนโยบายการการใช้คุกกี้",
          cookie,
          setCookie,
          "cookie"
        )}
      </div>
      <PolicyPopup
        open={open2}
        value={policy}
        setOpen={(e) => setOpen2(e)}
        onYes={policyHandleYes}
        onNo={policyHandleNo}
      />
      <div style={{ marginTop: 52 }}>
        <Box>
          <Paper
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1,
            }}
            elevation={1}
          >
            <BottomNavigation
              className="px-[10%]"
              style={{ gap: "12px", alignItems: "center", height: "52px" }}
            >
              <button
                onClick={handleClose}
                className="flex justify-center items-center border-[1px] border-blue rounded-[28px] w-full gap-[10px] py-[4px]"
              >
                {/* <DogPaw width={20} color="#FFFFFF" /> */}
                <p className="text-[16px] text-blue text-center">
                  ยินยอมตามที่เลือก
                </p>
              </button>
              <button
                onClick={() => postUpdatePetDetail(true)}
                className="flex justify-center items-center bg-blue rounded-[28px] w-full gap-[10px] py-[4px]"
              >
                {/* <DogPaw width={20} color="#FFFFFF" /> */}
                <p className="text-[16px] text-white text-center">
                  ยินยอมทั้งหมด
                </p>
              </button>
            </BottomNavigation>
          </Paper>
        </Box>
      </div>
    </Dialog>
  );
}

import {createSlice} from '@reduxjs/toolkit'

const defaultData = {
  memberId: null,
  currentPoint: 0,
  selectedImage: null
}

export const MyPointSlice = createSlice({
  name: 'myPoint',
  initialState: defaultData,
  reducers: {
    setMyPoint: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        console.log(`${key}: ${value}`);
        state[key] = value;
      }
    },
    resetMyPoint: () => defaultData
  },
})

// Action creators are generated for each case reducer function
export const {
  setMyPoint,
  resetMyPoint
} = MyPointSlice.actions

export default MyPointSlice.reducer
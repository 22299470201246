import React, { useEffect, useState } from "react";
import { SearchId } from "../../services/SearchId";
import { useLiff } from "react-liff";
import { useNavigate } from "react-router-dom";
import { SearchDdlEvent } from "../../services/Dropdown";
import { postCreatRedeemRecommendPoint } from "../../services/Dogbook";

export default function InviteFriendConfirm() {
  const navigate = useNavigate();

  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;
  const [eventPoint, setEventPoint] = useState(0);
  const [memberId, seyMemberId] = useState(0);

  useEffect(() => {
    getSearchByLineId();
    //
  }, []);

  const getSearchByLineId = async () => {
    const memberData = await getSearchId();
    if (!memberData.result.memberId) {
      navigate("/register");
    } else {
      seyMemberId(memberData.result.memberId);
      const eventData = await getSearchDdlEvent("COLLECT_POINT_RECOMM");
      if (eventData.status) {
        if (eventData.result.length) {
          setEventPoint(eventData.result[0].point);
        } else {
          setEventPoint(0);
        }
      }
    }
  };

  const getSearchId = async () => {
    let _return = {
      status: false,
      result: null,
    };
    try {
      const { data } = await SearchId({ params: { lineId: lineId || null } });
      if (data.resultCode === 20000) {
        const resData = data.body;
        _return.status = true;
        _return.result = resData;
      }
    } catch (error) {
      console.log(error);
    }
    return _return;
  };

  const getSearchDdlEvent = async (type) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await SearchDdlEvent({ params: { type } });
      if (data.resultCode === 20000) {
        const resData = data.body;
        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  const onSwopPointRecommend = async () => {
    const payloadRedeem = payloadRedeemPoint();
    const redeemPointData = await postRedeemRecommendPoint(payloadRedeem);
    if (redeemPointData.status) {
      navigate("/dogbook-invite-friend-success", {
        state: {
          point: redeemPointData.result.point,
          createdOn: redeemPointData.result.createdOn,
        },
      });
    } else {
      alert("เกิดข้อผิดพลาด");
    }
  };

  const payloadRedeemPoint = () => {
    const payload = {
      memberId: memberId,
    };
    return payload;
  };

  const postRedeemRecommendPoint = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await postCreatRedeemRecommendPoint({ data: payload });
      if (data.resultCode === 20000) {
        const resData = data.resultData;
        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  return (
    <div id="inviteFriendConfirmPage">
      <div className="content gradientBg">
        <div className="pInviteConfirmMain">
          <div className="bInviteConfirmContent">
            <h1>ยืนยันการแลก NexCoin</h1>
            <div className="bScore">
              <p className="tLabel">NexCoin ที่จะได้รับ</p>
              <p className="tPoint">{eventPoint}</p>
              <p className="tPointUnit">คะแนน</p>
            </div>
            <div className="bInviteConfirmDesc">
              <h2>เงื่อนไขการรับ NexCoin</h2>
              <div
                className="tDesc"
                dangerouslySetInnerHTML={{
                  __html: `
                <ul>
                  <li>ทางบริษัทฯ จะดำเนินการส่งของรางวัลภายใน 30 วัน</li>
                  <li>การจัดส่งของรางวัล จะถูกจัดส่งไปยัง ที่อยู่จัดส่ง ที่บันทึกไว้ เมื่อสมัครสมาชิก หากต้องการตรวจสอบ เปลี่ยน หรือแก้ไข ให้ไปที่เมนู สมุดพกน้องหมาและข้อมูลผู้ใช้ ก่อนกดใช้คะแนน</li>
                </ul>
              `,
                }}
              ></div>
            </div>
          </div>
          <div className="bInviteConfirmFooter">
            <button
              className="btn "
              // onClick={() => navigate("/dogbook-invite-friend-success")}
              onClick={onSwopPointRecommend}
            >
              ยืนยันแลกรับ NexCoin
            </button>
            <button className="btn _outline" onClick={() => navigate(-1)}>
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

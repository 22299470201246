import React from "react";
import LogoHeader from "../../assets/images/logo-header.png";

export default function TopLogo() {
  return (
    <>
      <div className="flex justify-center text-center top-logo">
        <img
          src={LogoHeader}
          alt="logo"
          className="w-[80px]"
          style={{ objectFit: "contain" }}
        />
      </div>
    </>
  );
}

import { Dialog } from "@mui/material";
import React from "react";
import PrimaryButton from "../button/PrimaryButton";
import SecondaryButton from "../button/SecondaryButton";
import NexFamily from "../../assets/images/nex-family.png";
// import Dog from "../../assets/images/logo-dog-register.png";
import Dog from "../../assets/images/logo-dog-star.png";

export default function PopupRegisterSuccess(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="pb-[36px] pt-[24px] px-[23px] rounded-[4px]">
        <p className="primary-color text-[28px] text-center">
          ยินดีที่คุณเข้ามาเป็นส่วนหนึ่งของ
        </p>
        <p className="primary-color text-[32px] text-center">
          ครอบครัว
          <img
            src={NexFamily}
            alt="NexFamily"
            style={{
              height: "25px",
              display: "inline-block",
              marginLeft: "10px",
            }}
          ></img>
        </p>
        <img
          src={Dog}
          alt="Dog"
          style={{
            width: "50%",
            margin: "0 auto",
          }}
        ></img>
        <p className="text-[24px] text-center">
          คุณได้รับ NexCoin จากการสมัครสมาชิก
        </p>
        <p className="primary-color text-[42px] text-center">
          {props.point} คะแนน
        </p>
        <div className="flex itesm-center gap-[13px] justify-center mt-[24px]">
          <SecondaryButton
            variant="outlined"
            className="w-[50%]"
            onClick={() => props.actionFunction()}
          >
            ปิด
          </SecondaryButton>
        </div>
      </div>
    </Dialog>
  );
}

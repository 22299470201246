import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import BottomNavigation from '@mui/material/BottomNavigation';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';

import TopLogo from '../../components/layout/TopLogo';
import location from "../../assets/images/icon/location.svg";
import paperplane from "../../assets/images/icon/paperplane.png";
import telephone from "../../assets/images/icon/telephone.png";
import facebook from "../../assets/images/icon/facebook.svg";
import line from "../../assets/images/icon/line.png";
import LogoHeader from "../../assets/images/logo-header.png"
import correct from "../../assets/images/correct.png"
import PrimaryButton from "../../components/button/PrimaryButton";

const ShowRouteButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "showLabel"
})({
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '21px',
    height: '48px',
    borderRadius: '28px',
    backgroundColor: '#0A4197',
    color: 'white',
    minWidth: '100px',
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1',
    display: 'flex',
    padding: '15px',
})

const BackButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "showLabel"
})({
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '24px',
    height: '48px',
    borderRadius: '28px',
    backgroundColor: 'white',
    color: '#0A4197',
    minWidth: '100px',
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1',
    display: 'flex',
    padding: '15px',
})

export default function SpectraNearYouDetail(props) {
    const send_data_back = () => {
        console.log("send_data_back");
        props.func(true);
    }

    const [distance, setDistance] = React.useState(null);
    const HaveTime = props.funz.openTime !== null && props.funz.openTime !== undefined && props.funz.openTime !== '';

    const handleDistance = () => {
        if (props.funz.distance < 1000) {
            setDistance(Math.round(props.funz.distance) + ' ม.')
        } else {
            setDistance((Math.round(props.funz.distance) / 1000).toFixed(1) + ' กม.')
        }
    }

    const handleRoute = () => {
        if (props.distance < 1000) {
            return 'https://www.google.com/maps/dir/?api=1&destination=' + props.funz.latitude + ',' + props.funz.longitude;
        } else {
            return 'https://www.google.com/maps/dir/?api=1&destination=' + props.funz.latitude + ',' + props.funz.longitude;
        }
    }

    React.useEffect(() => {
        window.scrollTo(0, 0);
        handleDistance();
    }, []);

    return (
        <React.Fragment>
            <TopLogo />
            <div
                style={{
                    background:
                        "linear-gradient(152.04deg, rgb(10, 65, 151) -0.97%, rgb(32, 159, 237) 81.57%)",
                    minHeight: "calc(100vh)",
                    padding: '50px 10px 10px 10px'
                }}
            >
                <Card sx={{ minWidth: 275, marginBottom: '15px' }}>
                    <CardContent>
                        <Typography sx={{
                            fontFamily: `'DB Helvethaica X', 'Kanit', sans-serif`,
                            fontSize: 30
                        }} color="text.primary"
                            component={'div'}
                        >
                            {props.funz.shopName}
                        </Typography>

                        <Typography sx={{
                            mb: 1.5,
                            fontSize: 20
                        }} color="text.secondary" component={'div'}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <p>ระยะทาง:</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p style={{
                                        textAlign: 'end',
                                        color: '#29A3EE',
                                        fontSize: 35
                                    }}>{distance}</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p>เวลาทำการ:</p>
                                </Grid>
                                <Grid item xs={6}>
                                    {HaveTime ?
                                        (<p style={{ textAlign: 'end' }}>{`${props.funz.openTime.substring(0, 5)} - ${props.funz.closeTime.substring(0, 5)}`} น.</p>) :
                                        (<p style={{ textAlign: 'end' }}>ไม่มีข้อมูล</p>)
                                    }
                                </Grid>
                            </Grid>
                        </Typography>

                        <Typography sx={{ mb: 1.5 }} color="text.secondary" component={'div'}>
                            <p>ช่องทางการติดต่อ</p>
                            {props.funz.mobileNumber &&
                                <Link href={"tel:" + props.funz.mobileNumber} target="_blank" underline="none" style={{
                                    float: 'left', clear: 'left'
                                }}><img src={telephone} alt="telephone" style={{ display: 'inline' }} /> {props.funz.mobileNumber}</Link>
                            }
                            {props.funz.facebook &&
                                <Link href={props.funz.facebook} target="_blank" underline="none" style={{
                                    float: 'left', clear: 'left'
                                }}><img src={facebook} alt="facebook" style={{ display: 'inline' }} /> {props.funz.shopName}</Link>
                            }
                            {props.funz.line &&
                                <Link href="#" underline="none" style={{
                                    float: 'left', clear: 'left', cursor: 'default'
                                }}><img src={line} alt="line" style={{ display: 'inline' }} /> {props.funz.line}</Link>
                            }
                        </Typography>

                        <Typography sx={{ mb: 1.5 }} color="text.secondary" component={'div'}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                                    <ShowRouteButton
                                        variant="contained"
                                        href={handleRoute()}
                                        target="_blank"
                                        style={{
                                            fontSize: '22px',
                                        }}
                                    >
                                        <img src={paperplane} alt="arrow" /> แสดงเส้นทาง
                                    </ShowRouteButton>
                                </Grid>
                            </Grid>
                        </Typography>
                    </CardContent>
                </Card>
                {/* <PrimaryButton
                    variant="contained"
                    style={{
                        minWidth: "50px",
                        background: "#197FC5",
                        color: "white",
                        width: "90%",
                    }}
                // onClick={handleOpen2}
                >
                    coupon
                </PrimaryButton> */}


                {/* <div className="container">
            <div className="card">
                <div className="main">
                    <div className="co-img">
                        <img
                            src="https://i.pinimg.com/originals/c7/84/67/c78467db9ff497393cb548a48f02d451.png"
                            alt=""
                        />
                    </div>
                    <div className="vertical"></div>
                    <div className="content">
                        <h2>Mcdonalds</h2>
                        <h1>10% <span>Coupon</span></h1>
                        <p>Valid till 30 April 2021</p>
                    </div>
                </div>
                <div className="copy-button">
                    <input id="copyvalue" type="text" readonly value="GOFREE" />
                    <button onclick="copyIt()" className="copybtn">COPY</button>
                </div>
            </div>
        </div> */}


                <Paper sx={{
                    position: 'fixed', bottom: 0, left: 0, right: 0
                }} elevation={1}>
                    <BottomNavigation
                        style={{
                            alignItems: 'center', height: '90px'
                        }}
                    >
                        <BackButton
                            variant="outlined"
                            style={{
                                minWidth: "90px",
                                margin: "0px 15px",
                            }}
                            onClick={send_data_back}
                        >
                            ย้อนกลับ
                        </BackButton>
                    </BottomNavigation>
                </Paper>
            </div>
        </React.Fragment>
    )
}
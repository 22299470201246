import Banner from "../../assets/images/banner.png";
import DogbookBanner from "../../assets/images/dogbook/banner.png";
import MyPointBanner from "../../assets/images/my-point/banner.png";
import MyPointShopBanner from "../../assets/images/my-point-shop/banner.png";
import MemberCardOwner from "../../assets/images/membercardowner/banner.png";
import redeemGiftBanner from "../../assets/images/redeem-gift/banner.png";
import redeemGiftMemberBanner from "../../assets/images/redeem-gift/banner-member.png";
import quiz from "../../assets/images/quiz3.png";
import inviteFriend from "../../assets/images/topbanner/invite-friend.svg";
import myPointStamp from "../../assets/images/topbanner/my-point-stamp.png";
import quizMania from "../../assets/images/topbanner/quiz-mania.png";
import React from "react";

export default function TopBanner({type}) {
  let bannerImage;
  switch (type) {
    case 'dogbook':
      bannerImage = DogbookBanner;
      break;
    case 'myPoint':
      bannerImage = MyPointBanner;
      break;
    case 'memberCardOwner':
      bannerImage = MemberCardOwner;
      break;
    case 'redeemGift':
      bannerImage = redeemGiftBanner;
      break;
    case 'redeemGiftMember':
      bannerImage = redeemGiftMemberBanner;
      break;
    case 'myPointShop':
      bannerImage = MyPointShopBanner;
      break;
    case 'quiz':
      bannerImage = quiz;
      break;
    case 'inviteFriend':
      bannerImage = inviteFriend;
      break;
    case 'myPointStamp':
      bannerImage = myPointStamp;
      break;
    case 'quizMania':
      bannerImage = quizMania;
      break;
    default:
      bannerImage = Banner;
  }
  return (
    <>
      <div className="text-center">
        <img
          src={bannerImage}
          alt="logo"
          className="w-full"
        />
      </div>
    </>
  );
}

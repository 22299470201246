import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";


export default function QRCodeItem(props) {
    const { value } = props
    return (
        <div className="flex justify-center">
            <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100px" }}
                value={value}
                viewBox={`0 0 256 256`}
                {...props}
            />
        </div>
    )
}
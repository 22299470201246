import React, { useState } from "react";
import moment from "moment/moment";

export default function QuizManiaRuleDesc() {
  const [isOpen, setIsOpen] = useState(false);

  const Label = isOpen ? 'ปิด' : 'อ่านเพิ่มเติม'

  return (
      <div className="bQuizDescMain">
        <div className="bQuizDesc">
          <div className="bDescTitle">
            <div className="bTitle">
              <div className="bMaxContent">
                <p>อ่านครบ ตอบถูก รับแต้ม NEXCoin ไปสะสม <br/>ลุ้นรับรางวัลใหญ่ประจำซีซั่นกับ Quiz Mania!!</p>
              </div>
            </div>
            { isOpen && (
              <div className="bSubTitle">
                <div className="bMaxContent">
                  <p>ใครสายเก็บล่ารางวัล บอกเลยว่าคุ้มมม เพราะ Quiz Mania เขาเล่นใหญ่เล่นจริง​</p>
                </div>
              </div>
              )
            }
            
          </div>
          {
            isOpen
            ? (
              <div className="bDescContent">
                <div className="bRule">
                  <h5>กติกา</h5>
                  <ul>
                    <li>เพียงอ่านบทความใน Line Spectra For Dog เฉพาะคอนเทนต์ที่มีคำว่า “คลิก” (มุมล่างขวา) รับไปเลย 10 NEXCoin</li>
                    <li>คุ้มต่อที่ 2 กับช่วงตอบคำถามท้ายบทความ Quiz Mania (คำถามจะถูกส่งหลังบทความขึ้นไปแล้ว 30 นาที) ถ้าตอบถูกรับแต้ม NEXCoin ไปเลย ข้อละ 10 คะแนน​</li>
                    <li>ติดตามเฉลยคำตอบได้ที่โพสต์ Line Voom ของ Spectra For Dog ในช่วงต้นเดือน​</li>
                  </ul>
                  <p>*ระยะเวลาการแลกรับของรางวัล ตั้งแต่วันนี้ ถึง 31 มกราคม 2568</p>
                </div>

                <div className="bTime">
                  <h5>ระยะเวลา</h5>
                  <p>ระยะเวลาการเล่น Quiz Mania จะเปิดให้เล่น 7 วัน โดยเริ่มนับตั้งแต่วันแรกที่ ลงโพสต์ใน Line Spectra For Dog เท่านั้น แบ่งเป็น 2 season ดังนี้​</p>

                  <ul className="disc">
                    <li>Season 1 เริ่มเดือน สิงหาคม 67 และเดือน กันยายน 67​</li>
                    <li>Season 2 เริ่มเดือน ตุลาคม 67, พฤศจิกายน 67 และ ธันวาคม 67 ​</li>
                  </ul>

                  <p>หากทำ Quiz Mania ครบทุกข้อตลอดทั้งซีซั่น ก็มีโอกาสเข้าไปติดอันดับ 1 ใน 10 โดยจะประกาศรายชื่อ TOP 10 เดือนสุดท้ายของซีซั่นนั้น ๆ ทางหน้าเพจ Facebook Spectra For Dog ตามลำดับเซ็ทของรางวัล​</p>
                </div>
                <div className="bRemark">
                  <div className="bNoSpace">
                    <p>**หมายเหตุที่ 1 การติดอันดับ TOP 10 จำเป็นต้องตอบถูกทุกข้อ และตอบเร็วที่สุด**</p>
                    <p>**หมายเหตุที่ 2 Quiz Mania มีเกณฑ์การลดคะแนน NEXCoin ตามจำนวนวันที่ user เข้ามาร่วมเล่น** ดังนี้​</p>
                  </div>

                  <p>1.) user ที่เริ่มทำ Quiz Mania ตั้งแต่วันที่หนึ่ง จะได้จำนวน NEXCoin เท่ากับจำนวนข้อที่ตอบถูก โดยไม่ถูกลดคะแนน NEXCoin​</p>
                  <p>2.) user ที่เริ่มทำ Quiz Mania วันที่สอง จะถูกลดจำนวน NEXCoin -1 คะแนน จากคะแนนเต็มที่ตอบคำถามถูกทุกข้อ ​</p>
                  <div className="bNoSpace">
                    <p>3.) user ที่เริ่มทำ Quiz Mania วันที่สาม จะถูกลดจำนวน NEXCoin -2 คะแนน จากคะแนนเต็มที่ตอบคำถามถูกทุกข้อ และ user ที่เข้ามาทำ Quiz Mania ในวันถัดไป ก็จะถูกลดคะแนนลงมาตามลำดับ​</p>
                    <p>ตัวอย่างที่ : ผู้เล่น B เข้ามาทำ Quiz Mania วันที่ห้า แต่ตอบคำถามถูกเพียง 1 ข้อ = ได้ NEXCoin 10 คะแนน และจะถูกหัก -4 คะแนนตามจำนวนวันที่เข้ามา = ได้ NEXCoin 6 คะแนน </p>
                  </div>
                </div>
              </div>
            )
            : <></>
          }
          
        </div>
        
        <button
          className="btn _outline toggle"
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        >
          {Label}
        </button>
      </div>
  );
}

import React from "react";
import { useLiff } from "react-liff";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { useNavigate, useLocation } from "react-router-dom";
import { Autocomplete } from '@mui/material';
import FormControlLabel from "@mui/material/FormControlLabel";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { Radio } from "@mui/material";
import RadioGroup from '@mui/material/RadioGroup';

import TopLogo from '../../components/layout/TopLogo';
import globalStyle from "../../assets/styles/constants";
import ShopBanner from "../../assets/images/membercardowner/banner.png";
import lineBlue from "../../assets/images/icon/lineBlue.svg";
import PrimaryButton from "../../components/button/PrimaryButton";
import SecondaryButton from "../../components/button/SecondaryButton";
import CustomPopupShopMember from "../../components/popup/CustomPopupShopMember";
import { SearchDdlAddress } from "../../services/Dropdown";
import {
    PostUpdateEmployee
} from "../../services/MemberCardOwner";

export default function MemberCardOwnerEditEmployee() {
    const payload = useLocation()?.state?.payload;

    // console.log("payload EDIT EMPLOYEE", payload);

    const navigate = useNavigate();

    const [hasOwner, setHasOwner] = React.useState(payload.isOwner);

    // console.log("hasOwner", hasOwner);

    const [openCancelPopup, setOpenCancelPopup] = React.useState(false);
    const [openEditedPopup, setOpenEditedPopup] = React.useState(false);

    const { register, formState: { errors }, handleSubmit, setValue, control, watch } = useForm({
        shouldFocusError: false,
        shouldUnregister: true,
        defaultValues: {
            firstName: payload.firstName,
            lastName: payload.lastName,
            isOwner: payload.isOwner,
        }
    });

    const onSubmit = async (data) => {
        // console.log("submit data", data);
        const myPayload = {
            employeeId: parseInt(payload.employeeId),
            firstName: data.firstName,
            lastName: data.lastName,
            isOwner: data.isOwner,
            email: payload.email,
            mobileNumber: payload.mobileNumber,
        };

        // console.log("myPayload", myPayload);

        try {
            const { data } = await PostUpdateEmployee({ data: myPayload });
            console.log("resData.data >>", data);
            if (data.resultCode === 20000) {
                setOpenEditedPopup(true);
            }
        } catch (error) {
            console.log("PostUpdateEmployee error", error);
        }
    };

    const handleRadioButton = (event) => {
        if (event === "true") {
            setHasOwner(true);
            setValue("isOwner", true, {
                shouldValidate: true,
            });
        } else {
            setHasOwner(false);
            setValue("isOwner", false, {
                shouldValidate: true,
            });
        }
    }

    return (
        <div id="shop-edit-employee-page">
            <TopLogo />
            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <CustomPopupShopMember
                    title={"บันทึกการแก้ไขเรียบร้อย"}
                    desc={"ข้อมูลผู้ใช้ที่คุณแก้ไขได้รับการบันทึกแล้ว"}
                    cancelText={"ปิด"}
                    cancelFunction={() => {
                        window.open("/member-card-owner", "_self");
                        setOpenEditedPopup(false);
                    }}
                    open={openEditedPopup}
                    onClose={() => {
                        window.open("/member-card-owner", "_self");
                        setOpenEditedPopup(false);
                    }}
                />

                <CustomPopupShopMember
                    title={"ยกเลิกการแก้ไขข้อมูล"}
                    desc={
                        "ข้อมูลผู้ใช้ที่คุณเปลี่ยนแปลงจะยังไม่ถูกบันทึก หากมั่นใจแล้วกรุณากด ยืนยัน"
                    }
                    cancelFunction={() => {
                        setOpenCancelPopup(false);
                    }}
                    actionFunction={() => {
                        navigate(-1);
                        setOpenCancelPopup(false);
                    }}
                    open={openCancelPopup}
                    onClose={() => {
                        setOpenCancelPopup(false);
                    }}
                />

                <img src={ShopBanner} alt="shopbanner" className="w-full" />
                <div id="employee-edit" className="relative mt-[-20px] px-[20px]">

                    <div className="bg-white py-[40px] rounded-[4px] mb-[20px] px-[17px] ">
                        <p className="text-lightBlue text-[28px] text-left">
                            แก้ไขข้อมูลพนักงาน
                        </p>
                        <div className="flex flex-col mt-[16px] gap-[16px]">
                            <div className="flex gap-[15px] mb-[20px]">
                                <TextField
                                    required
                                    fullWidth
                                    label="ชื่อ"
                                    variant="standard"
                                    error={!!errors?.firstName}
                                    helperText={errors?.firstName?.message}
                                    {...register("firstName", { required: "กรุณากรอกชื่อ" })}
                                />

                                <TextField
                                    required
                                    fullWidth
                                    label="นามสกุล"
                                    variant="standard"
                                    error={!!errors?.lastName}
                                    helperText={errors?.lastName?.message}
                                    {...register("lastName", { required: "กรุณากรอกนามสกุล" })}
                                />
                            </div>
                        </div>



                        {payload.isOwner && (
                            <div id="checkOwner">
                                <div className="text-black text-[25px] text-left mt-[32px] mb-[10px">
                                    เปลี่ยนตำแหน่ง <p className="inline-block text-red">*</p>
                                </div>
                                <Controller
                                    rules={{ required: false }}
                                    control={control}
                                    name="isOwner"
                                    render={({ field: { onChange } }) => (
                                        <RadioGroup
                                        >
                                            <FormControlLabel
                                                checked={hasOwner === true}
                                                value={true}
                                                control={<Radio />}
                                                label="เจ้าของร้าน/ผู้จัดการฝ่ายจัดซื้อ/ผู้จัดการสาขา"
                                                onChange={(event) => handleRadioButton(event.target.value)}
                                            />
                                            <FormControlLabel
                                                checked={hasOwner === false}
                                                value={false}
                                                control={<Radio />}
                                                label="พนักงาน"
                                                onChange={(event) => handleRadioButton(event.target.value)}
                                            />
                                        </RadioGroup>
                                    )}
                                />
                            </div>)
                        }

                    </div>
                </div>

                <div className="absolute bottom-0 w-[100%]">
                    <div className="flex itesm-center gap-[13px] justify-center bg-white px-[20px] pt-[16px] pb-[24px]">
                        <SecondaryButton
                            variant="outlined"
                            className="w-full"
                            onClick={() => setOpenCancelPopup(true)}
                        >
                            ยกเลิก
                        </SecondaryButton>
                        <PrimaryButton
                            variant="outlined"
                            className="w-full"
                            type="submit"
                        >
                            บันทึก
                        </PrimaryButton>
                    </div>
                </div>
            </form>
        </div>
    )
}

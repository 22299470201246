import { get, post } from "./Api";

export function SearchQuarterLeaderboard({ params }) {
  return get({ url: `/quarterLeaderboard`, params });
}

export function SearchQuizLeaderboard({ params }) {
  return get({ url: `/quizLeaderBoard`, params });
}

export function SearchMyQuizLeaderboard({ params }) {
  return get({ url: `/myQuizLeaderBoard`, params });
}

export function SearchQuizQuestion({ params }) {
  return get({ url: `/searchQuizQuestionnaire`, params });
}

export function CreateQuizUserAnswer({ data }) {
  return post({ url: `/createQuizUserAnswer`, data });
}

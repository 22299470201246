import React from "react";
import Grid from "@mui/material/Grid";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import checkmark from "../../assets/images/checkmark.png";
import TopLogo from "../../components/layout/TopLogo";

export default function MyPointSuccess(props) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { title, points, listDetail = [] } = props;
  const { open, toPage, hasStamp, countStamp } = props;
  console.log("props >>", props);
  function DetailInfo(title, data) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={3}>
          {title}:
        </Grid>
        {data ? (
          <Grid item xs={9} className="text-end">
            {data}
          </Grid>
        ) : (
          <Grid item xs={9} className="text-end">
            -
          </Grid>
        )}
      </Grid>
    );
  }

  function gotoStampPage() {
    navigate("/my-point-stamp");
    // navigate(-1);
    // setTimeout(() => {
    //   navigate("/my-point-stamp");
    // }, 10);
  }

  const isOpen = open ?? true;
  if (isOpen) {
    return (
      <div className="fixed h-screen w-screen z-10">
        <TopLogo />
        <div
          id="get-point-success"
          className="gradientBg justify-between py-[42px] px-[30px] flex flex-col"
          style={{ minHeight: "calc(100vh - 60px)" }}
        >
          <div className="bHead">
            <div className="flex justify-center">
              <img
                className="mx-auto"
                src={checkmark}
                alt=""
                width="52px"
                height="52px"
              />
            </div>

            <p className="text-center text-white text-[36px]">{title}</p>
            {hasStamp && (
              <p className="text-center text-white text-[24px]">
                ยินดีด้วย! คุณได้รับแสตมป์ 1 ดวง
              </p>
            )}
            <div id="pStampSuccessMain">
              <div className="bg-darkBlue pPoint">
                <div className="text-white text-center">
                  <p className="text-xl leading-tight mb-[2px]">
                    NexCoin ที่ได้รับ
                  </p>
                  <p className="text-[52px] leading-9 ">
                    {points} {hasStamp ? <br /> : <></>}{" "}
                    <span className="text-[36px]">คะแนน</span>
                  </p>
                </div>
              </div>
              {hasStamp ? (
                <div className="pStampSuccess">
                  <p className="tStamp">แสตมป์ที่คุณได้รับ</p>
                  {/* data-item="1" ใส่ได้ 1-12  ตาม CountStamp*/}
                  <i className="bImgStamp" data-item={countStamp}></i>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="text-white text-xl mt-[16px]">
              <p style={{ fontSize: "24px" }}>รายละเอียด</p>
              <div className="py-3">
                <hr className="opacity-100 border-darkBlue" />
              </div>

              <div className="">
                {listDetail.map((item) => DetailInfo(item.name, item.value))}
              </div>
            </div>
          </div>

          <div className="bFoot">
            {/* <p className="text-left text-white text-[16px] leading-[84%] mb-[10%]">
              *คุณสามารถร่วมกิจกรรมสแกนรับพ้อยท์ได้เดือนละครั้งเท่านั้น
            </p> */}
            <button
              onClick={() => {
                navigate(toPage);
              }}
              className="btn _outline w-full !border-[#FFF] mb-[12px]"
            >
              เสร็จสิ้น
            </button>
            <button
              className="btn _outline transparent w-full"
              onClick={gotoStampPage}
            >
              ไปยังอัลบั้มแสตมป์ของฉัน
            </button>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
}

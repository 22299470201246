import React, { useEffect, useState, useRef } from "react";
import { Button, Checkbox, TextField } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TopBanner from "../../components/layout/TopBanner";
import TopLogo from "../../components/layout/TopLogo";
// import { tempLineId1 } from "../../services/Api";
import { useLiff } from "react-liff";
import liff from "@line/liff";

import { Controller, useForm } from "react-hook-form";
import helper from "../../functions/helper";
import CustomPopup from "../../components/popup/CustomPopup";
import dayjs from "dayjs";
import BottomNavigation from "@mui/material/BottomNavigation";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDispatch, useSelector } from "react-redux";
import globalStyle from "../../assets/styles/constants";
import PrimaryButton from "../../components/button/PrimaryButton";
import {
  SearchQuizQuestion,
  CreateQuizUserAnswer,
} from "../../services/QuizMania";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import useWindowDimensions from "../../functions/getScreen";
import { setQuizMania } from "../../features/QuizManiaSlice";
import foreBackground from "../../assets/images/fore_background.jpeg";
import QuizManiaLock from "./QuizManiaLock";
import moment from "moment";

const initialQuizListX = [
  {
    quizId: "$id",
    quizNo: 1,
    startDate: "2023-01-01",
    endDate: "2023-02-01",
    quizQuestion: [
      {
        quizQuestionId: "$id",
        questionNo: 1,
        answerType: 1,
        question: "คุณอาบน้ำให้น้องหมาเดือนละกี่ครั้ง",
        point: "2",
        quizAnswer: [
          {
            quizAnswerId: "$id",
            answerNo: 1,
            answer: "2 ครั้ง",
          },
        ],
      },
    ],
  },
];
const initialQuizList = [
  {
    quizQuestionId: 1,
    questionNo: 1,
    answerType: 1,
    question: "คุณอาบน้ำให้น้องหมาเดือนละกี่ครั้ง",
    point: 2,
    urlPicture: null,
    quizAnswer: [
      {
        quizAnswerId: 1,
        answerNo: 1,
        answer: "มากกว่า 5 ครั้ง",
        urlPicture: null,
      },
      {
        quizAnswerId: 2,
        answerNo: 2,
        answer: "4 ครั้ง",
        urlPicture: null,
      },
      {
        quizAnswerId: 3,
        answerNo: 3,
        answer: "2 ครั้ง",
        urlPicture: null,
      },
      {
        quizAnswerId: 4,
        answerNo: 4,
        answer: "1 ครั้ง",
        urlPicture: null,
      },
    ],
  },
  {
    quizQuestionId: 2,
    questionNo: 1,
    answerType: 2,
    question: "คุณอาบน้ำให้น้องหมาเดือนละกี่ครั้ง",
    point: 1,
    urlPicture: null,
    quizAnswer: [
      {
        quizAnswerId: 5,
        answerNo: 1,
        answer: "มากกว่า 5 ครั้ง",
        urlPicture: null,
      },
      {
        quizAnswerId: 6,
        answerNo: 2,
        answer: "4 ครั้ง",
        urlPicture: null,
      },
      {
        quizAnswerId: 7,
        answerNo: 3,
        answer: "2 ครั้ง",
        urlPicture: null,
      },
      {
        quizAnswerId: 8,
        answerNo: 4,
        answer: "1 ครั้ง",
        urlPicture: null,
      },
    ],
  },
];

const initialAlertPopup = {
  title: "แจ้งเตือน",
  desc: "",
  open: false,
};

export default function QuizMania() {
  const store = useSelector((state) => state.quiz);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [quizId, setQuizId] = React.useState(0);
  const [quizName, setQuizName] = React.useState("");
  const [quizNo, setQuizNo] = React.useState(0);
  const [quizList, setQuizList] = React.useState([]);
  const [ansList, setAnsList] = React.useState({});
  const [sumPoint, setSumPoint] = React.useState({});

  const [memberId, setMemberId] = React.useState(null);
  const [alertPopup, setAlertPopup] = React.useState(initialAlertPopup);

  const [isQuizMania, setIsQuizMania] = React.useState(true); // ถ้าเป็น set อันนี้ mania
  const [isStart, setIsStart] = React.useState(false); // ถ้าเป็น set อันนี้ true
  const [startTime, setStartTime] = React.useState(false); // ถ้าเป็น set อันนี้ เริ่มจับเวลา
  const [lineId, setLineId] = useState(null);

  const { liff } = useLiff();
  // const lineId = liff.getDecodedIDToken().sub;
  const qQuizId = searchParams.get("quiz");

  useEffect(() => {
    getLineLiff();
  }, []);

  useEffect(() => {
    getSearchQuizQuestion();
  }, [lineId]);

  const getLineLiff = async () => {
    const path = window.location.pathname;
    const liffId = helper.getLiffIdByENV(path, "dev");
    // alert("liffId +" + liffId);
    await liff.init({ liffId: liffId });
    if (!liff.isLoggedIn()) {
      const destinationUrl = window.location.href;
      liff.login({ redirectUri: destinationUrl });
    }

    const _lineId = liff.getDecodedIDToken().sub;
    window.localStorage.removeItem("sub");
    window.localStorage.setItem("sub", _lineId);
    setLineId(_lineId);
  };

  async function getSearchQuizQuestion() {
    return await SearchQuizQuestion({
      params: { lineId: lineId || null, quizId: qQuizId },
    })
      .then(async (res) => {
        const resData = res.data;
        console.log("resData >>", resData);
        if (resData.resultCode == 20000) {
          // alert("CASE A")
          setQuizId(resData.body[0]?.quizId);
          setQuizNo(resData.body[0]?.quizNo);
          setQuizName(resData.body[0]?.quizName);
          console.log("resData.body[0].quizQuestion >>", resData.body[0]);
          setSumPoint(resData.body[0]?.point ?? 0);
          setQuizList(resData.body[0]?.quizQuestion);
          setMemberId(resData.memberId);
        } else if (resData.resultCode == 40309) {
          // alert("CASE B")
          fnSetData("memberId", null);
          fnSetData("points", 0);
          fnSetData("payload", {});
          // navigate('/questionnaire-not-found')
          let ans = { memberId: null, points: 0, payload: {} };
          // window.localStorage.setItem('p', btoa(JSON.stringify(ans)))
          window.localStorage.setItem("p", helper.encodeJWT(ans));
          navigate("/questionnaire-not-found");
        } else {
          // alert("CASE C")
          fnSetData("memberId", null);
          fnSetData("points", 0);
          fnSetData("payload", {});
          // navigate('/quiz-success')
          let ans = { memberId: null, points: 0, payload: {} };
          // window.localStorage.setItem('p', btoa(JSON.stringify(ans)))
          window.localStorage.setItem("p", helper.encodeJWT(ans));
          navigate(`/quiz-mania?quiz=${qQuizId}`);
          // window.location.replace("/questionnaire-success");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fnSetData(name, value) {
    const tmpObj = {};
    tmpObj[name] = value;
    dispatch(setQuizMania(tmpObj));
  }

  const onSubmit = async () => {
    const endTime = moment().format("YYYY-MM-DD HH:mm:ss");
    const startDiffTime = moment(startTime);
    const endDiffTime = moment(endTime);
    const totalTiem = endDiffTime.diff(startDiffTime, "second");
    const year = moment().format("YYYY");
    const payload = ansToPayload(
      { ...ansList },
      quizId,
      lineId,
      [...quizList],
      sumPoint,
      startTime,
      endTime,
      totalTiem,
      year
    );
    console.log("payload", payload);

    let points = 0;
    let quarterId = 0;
    if (memberId) {
      const resData = await PostCreateQuizUserAnswer(payload);
      points = resData?.body?.points ?? 0;
      quarterId = resData?.body?.quarter ?? 0;
    }
    fnSetData("memberId", memberId ?? null);
    fnSetData("points", points);
    fnSetData("payload", payload);
    let ans = { memberId, points, payload };
    // window.localStorage.setItem('p', btoa(JSON.stringify(ans)))
    window.localStorage.setItem("p", helper.encodeJWT(ans));

    // window.location.replace('/quiz-success')
    // window.location = `/quiz-success?registerAns=${JSON.stringify(ans)}`
    navigate(
      `/questionnaire-success?quiz=${quizId}&year=${year}&quarter=${quarterId}`
    );
  };

  const PostCreateQuizUserAnswer = (payload = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await CreateQuizUserAnswer({ data: payload });
        console.log("resData.data >>", data);
        if (data.resultCode === 20000) {
          console.log("resData.data >>", data.body.points);
          resolve(data);
        } else {
          resolve(null);
        }
      } catch (error) {
        resolve(null);
        console.log("create pet error", error);
      }
    });
  };

  const ansToPayload = (
    ans = {},
    quiz_id,
    line_id,
    quiz_list = [],
    _sumPoint = 0,
    startTime,
    toTime,
    totalTiem,
    year
  ) => {
    let sumPoint = _sumPoint;
    let quizQuestion = [];
    let quizPoint = {};

    console.log("ans -->", ans);
    console.log("quiz_list --->", quiz_list);

    for (const k in ans) {
      const findQuizQuestion = quiz_list.find(
        (ele) => ele.quizQuestionId === k
      );
      const pointAnswer = findQuizQuestion.quizAnswer.find(
        (ele) => ele.quizAnswerId == ans[k]
      );
      let tmp = {
        quizQuestionId: k,
        point: pointAnswer.point,
        quizAnswerId: Array.isArray(ans[k]) ? ans[k] : [Number(ans[k])],
      };
      if (`${k}`.endsWith("|3")) {
        const subId = `${k}`.replace("|3", "");
        tmp = {
          quizQuestionId: subId,
          point: pointAnswer.point,
          answerText: ans[k],
        };
      }
      quizQuestion.push(tmp);
    }

    return {
      lineId: line_id,
      quizId: quiz_id,
      quizQuestion: quizQuestion,
      quizManiaPoint: sumPoint,
      startTime: startTime,
      toTime: toTime,
      totalTime: totalTiem,
      year: year,
    };
  };

  const handleChange = (value, name, type) => {
    let _ansList = { ...ansList };
    if (_ansList[name]) {
      if (type === 1) {
        _ansList[name] = value;
      } else if (type === 3) {
        if (value) {
          _ansList[name] = value;
        } else {
          delete _ansList[name];
        }
      } else {
        if (Array.isArray(_ansList[name]) && _ansList[name].includes(value)) {
          _ansList[name] = _ansList[name].filter((item) => item !== value);
          if (_ansList[name].length === 0) {
            delete _ansList[name];
          }
        } else {
          _ansList[name].push(value);
        }
      }
    } else {
      if (type === 1) {
        _ansList[name] = value;
      } else if (type === 3) {
        _ansList[name] = value;
      } else {
        _ansList[name] = [value];
      }
    }
    setAnsList(_ansList);
  };
  const ImageTitle = (urlPicture) => {
    return (
      <>
        {urlPicture ? (
          <img
            className="w-full h-[158px] object-cover mb-[12px] rounded-[5px]"
            src={urlPicture}
            alt=""
          />
        ) : (
          <></>
        )}
      </>
    );
  };
  const ImageChoice = (urlPicture) => {
    return (
      <>
        {urlPicture ? (
          <img
            className="w-full h-[148px] object-cover mb-[12px] rounded-[5px] ml-[15px]"
            src={urlPicture}
            alt=""
          />
        ) : (
          <></>
        )}
      </>
    );
  };

  const onStartQuiz = (quarter) => {
    console.log("value quarter >>>", quarter);
    const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
    console.log("currentTime >>", currentTime);
    setStartTime(currentTime);
    setIsStart(true);
  };

  if (!isStart) {
    return (
      <>
        {/* _lineId{lineId} */}
        <QuizManiaLock onStartQuiz={onStartQuiz} />
      </>
    );
  }

  return (
    <>
      <CustomPopup
        title={alertPopup.title}
        desc={alertPopup.desc}
        cancelText={"ปิด"}
        cancelFunction={() => {
          let tmp = { ...alertPopup };
          tmp.open = false;
          setAlertPopup(tmp);
        }}
        open={alertPopup.open}
        onClose={() => {
          let tmp = { ...alertPopup };
          tmp.open = false;
          setAlertPopup(tmp);
        }}
      />
      <div id="quiz-page">
        <TopLogo />
        {/* {JSON.stringify(ansList)} */}
        {/* {JSON.stringify(store)} */}
        <div className="body-container rounded-[0px] px-[13px] pb-[30px] overflow-hidden bg-[#F2F2F2]">
          <div className="flex flex-col h-full">
            <div className="relative z-10">
              <div className="top-[10]">
                <div className="absolute w-full text-center mt-[10%]">
                  <p
                    className="relative title-quiz text-white mx-[10%] leading-[80%] text2-line z-[100]"
                    // style={{ fontSize: (36 * width) / 375 }}
                    dangerouslySetInnerHTML={{ __html: `${quizName}` }}
                  ></p>
                </div>
                <div id="pQuizMania">
                  <TopBanner type="quizMania" />
                </div>
              </div>
              {/* <div className="flex justify-center mt-[-12px] flex-none">
              <div className="quiz-no">
                <p className="">{quizNo}</p>
              </div>
            </div> */}
            </div>
            <div className="bg-white mt-[-20px] flex-1 pt-[36px] pb-[36px] px-[17px] overflow-y-auto">
              {quizList.map((item, i) => {
                const no = i + 1;
                console.log("item.answerType >>", item.answerType);
                if (item.answerType === 1) {
                  return (
                    <div key={i} className="mb-[24px]">
                      {ImageTitle(item.urlPicture)}
                      <h2 className="text-darkGray text-[24px] font-bold">
                        ข้อที่ {no} : {item.question}
                      </h2>
                      <div className="px-[12px]">
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                          onChange={(e) =>
                            handleChange(e.target.value, item.quizQuestionId, 1)
                          }
                        >
                          {item.quizAnswer.map((choice, i2) => {
                            return (
                              <>
                                <FormControlLabel
                                  key={"A" + i2}
                                  value={choice.quizAnswerId}
                                  control={<Radio />}
                                  label={choice.answer}
                                />
                                {ImageChoice(choice.urlPicture)}
                              </>
                            );
                          })}
                        </RadioGroup>
                      </div>
                    </div>
                  );
                }
                if (item.answerType === 2) {
                  return (
                    <div key={"B" + i} className="mb-[24px]">
                      {ImageTitle(item.urlPicture)}
                      <h2 className="text-darkGray text-[24px] font-bold leading-[100%]">
                        ข้อที่ {no} : {item.question}
                      </h2>
                      <h3 className="text-gray text-[20px] leading-[100%] mb-[12px]">
                        (เลือกตอบได้มากกว่า 1 ข้อ)
                      </h3>
                      <div className="px-[12px]">
                        {item.quizAnswer.map((choice, i2) => {
                          const isChecked = (ansArr = [], cur) => {
                            return (
                              Array.isArray(ansArr) && ansArr.includes(cur)
                            );
                          };
                          return (
                            <>
                              <FormControlLabel
                                key={"B" + i2}
                                sx={{ display: "block" }}
                                control={
                                  <Checkbox
                                    checked={isChecked(
                                      ansList[`${item.quizQuestionId}`],
                                      choice.quizAnswerId
                                    )}
                                    onChange={(e) =>
                                      handleChange(
                                        choice.quizAnswerId,
                                        item.quizQuestionId,
                                        2
                                      )
                                    }
                                  />
                                }
                                label={choice.answer}
                              />
                              {ImageChoice(choice.urlPicture)}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  );
                }
                if (item.answerType === 3) {
                  return (
                    <div key={"B" + i} className="mb-[24px]">
                      {ImageTitle(item.urlPicture)}
                      <h2 className="text-darkGray text-[24px] font-bold leading-[100%]">
                        ข้อที่ {no} : {item.question}
                      </h2>
                      <h3 className="text-gray text-[20px] leading-[100%] mb-[12px]">
                        (กรอกได้ไม่เกิน 255 ตัวอักษร)
                      </h3>
                      {/* {ansList[`${item.quizQuestionId}|3`]} */}
                      <div className="px-[12px]">
                        <div className="flex flex-col gap-[0px] mt-[24px]">
                          {/* <p className="text-[24px] text-left">บันทึกประจำวัน</p> */}
                          <TextField
                            style={{ height: "auto" }}
                            className="w-full text-[20px] border border-indigo-600 text-area"
                            value={ansList[`${item.quizQuestionId}|3`]}
                            multiline
                            rows={3}
                            label=""
                            onChange={(event) => {
                              let txt =
                                event.target?.value == ""
                                  ? undefined
                                  : event.target?.value;
                              console.log("txt >>", txt);
                              if (txt) {
                                txt = txt.substring(0, 255);
                                console.log("txt.length >>", txt.length);
                              }
                              handleChange(
                                txt,
                                item.quizQuestionId + "|" + 3,
                                3
                              );
                            }}
                            variant="standard"
                          />
                        </div>
                      </div>
                    </div>
                  );
                }
                return "-";
              })}
              {/* {JSON.stringify(ansList)}
              <br/>
              {Object.keys(ansList).length}|
              {quizList.length} */}
            </div>
          </div>
        </div>
        <img
          src={foreBackground}
          alt="bg"
          style={{
            position: "fixed",
            margin: "auto",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: -1,
            width: "100vw",
            height: "100vh",
          }}
        />

        <Paper
          sx={{
            position: "fixed",
            maxWidth: "500px",
            margin: "auto",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
          }}
          elevation={1}
        >
          <BottomNavigation
            className="px-[17px]"
            style={{ gap: "10px", alignItems: "center", height: "90px" }}
          >
            <PrimaryButton
              variant="contained"
              className="w-[70%]"
              disabled={
                Object.keys(ansList).length == 0 ||
                Object.keys(ansList).length !== quizList.length
              }
              onClick={onSubmit}
            >
              ต่อไป
            </PrimaryButton>
          </BottomNavigation>
        </Paper>
      </div>
    </>
  );
}

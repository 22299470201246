import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import { useLiff } from "react-liff";
// import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SearchId } from "../../services/SearchId";
import { QrReader } from "react-qr-reader";
import { CreateCollectPointByQR } from "../../services/MyPoint";
import CustomPopupQr from "../../components/popup/CustomPopupQr";
import CustomPopup from "../../components/popup/CustomPopup";
import MyPointSuccess from "./MyPointSuccess";
import MyPointSuccessDone from "./MyPointSuccessDone";

import dayjs from "dayjs";

const initialQrCodePopup = {
  title: "แจ้งเตือน",
  desc: "",
  open: false,
};
const initialPointSSPopup = {
  points: 0,
  listDetail: [{ name: "", value: "" }],
  // listDetail: [{ name: "วันที่/เวลา", value: "10/04/2024 13:07:11" },{ name: "พื้นที่สแกน", value: "814134_90 โรงพยาบาลสัตว์สาเกตุนคร" }],
};

export default function MyPointScan() {
  const navigate = useNavigate();

  const [qrcode, setQrCode] = useState("No result");
  const [memberId, setMemberId] = React.useState(null);
  const [openCancelPopup, setOpenCancelPopup] = React.useState(true);
  const [openSSPopup, setOpenSSPopup] = useState(false);
  const [openSSDPopup, setOpenSSDPopup] = useState(false);
  const [pointSSPopup, setPointSSPopup] = useState(initialPointSSPopup);

  const [qrCodePopup, setQrCodePopup] = React.useState(initialQrCodePopup);
  const [isOpenQRInput, setIsOpenQRInput] = React.useState(false);
  const [ishasStamp, setIshasStamp] = React.useState(false); // ถ้าเกิดร้านเข้าร่วม Stamp ตอนpopup เช็ดอันนี้ลงไปด้วย
  const [countStamp, setCountStamp] = React.useState(1); // count Stamp ที่มี

  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  useEffect(() => {
    getSearchId();
    //
  }, []);

  useEffect(() => {
    console.log("detect QR CODE >> ", qrcode);
    if (qrcode && qrcode !== "No result") {
      createCollectPointByQR();
    }
  }, [qrcode]);

  async function getSearchId() {
    return await SearchId({
      params: { lineId: lineId || null },
    })
      .then(async (response) => {
        if (!response.data.body.memberId) {
          window.location.replace("/register");
        } else {
          setMemberId(response.data.body.memberId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function createCollectPointByQR() {
    const payload = { memberId, qrCode: qrcode };
    console.log("payload Success >>", payload);

    try {
      const { data } = await CreateCollectPointByQR({ data: payload });
      console.log("resData.data >>", data);
      if (data.resultCode === 20000) {
        const { points, shopNo, shopName } = data.body;
        const createdOn = dayjs(data.body.createdOn).format(
          "DD/MM/YYYY HH:mm:ss"
        );

        let tmp = { ...pointSSPopup };
        tmp.points = points;
        tmp.listDetail = [
          { name: "วันที่/เวลา", value: createdOn },
          { name: "พื้นที่สแกน", value: `${shopNo} ${shopName}` },
        ];
        setPointSSPopup(tmp);

        // let isStamp = data.body.isHaveStamp // แก้ตรงนี้ถ้ามี Stamp
        // if (isStamp !== 0) {
        //   setIshasStamp(isStamp)
        //   setCountStamp(2)
        // }
        setOpenSSPopup(true);
        setIshasStamp(data.body.isHaveStamp === 1);
        setCountStamp(data.body.lengthQrStamp);
      } else if (data.resultCode === 40308) {
        let tmp = { ...qrCodePopup };
        tmp.open = true;
        tmp.desc = data.resultDescription;
        setQrCodePopup(tmp);
      } else {
        let tmp = { ...qrCodePopup };
        tmp.open = true;
        tmp.desc = data.resultDescription;
        setQrCodePopup(tmp);
        setOpenSSDPopup(true);
      }
    } catch (error) {
      console.log("create pet error", error);
    }
  }

  const openQrCodeInput = () => {
    // navigate('/my-qrcode');
    setIsOpenQRInput(true);
  };

  function ScanOverlay() {
    return (
      <svg
        viewBox="-10 -10 120 120"
        className="z-[1] relative"
        style={{
          boxSizing: "border-box",
          border: "35px solid rgba(0, 0, 0, 0.3)",
        }}
      >
        <path
          fill="none"
          d="M13,0 L0,0 L0,13"
          stroke="rgb(196, 196, 196)"
          strokeWidth="2"
        />
        <path
          fill="none"
          d="M0,87 L0,100 L13,100"
          stroke="rgb(196, 196, 196)"
          strokeWidth="2"
        />
        <path
          fill="none"
          d="M87,100 L100,100 L100,87"
          stroke="rgb(196, 196, 196)"
          strokeWidth="2"
        />
        <path
          fill="none"
          d="M100,13 L100,0 87,0"
          stroke="rgb(196, 196, 196)"
          strokeWidth="2"
        />
      </svg>
    );
  }

  const pull_data = (data) => {
    console.log("data >>", data.qrCode);
    setIsOpenQRInput(false);
    setQrCode(data.qrCode);
  };
  if (openSSPopup) {
    return (
      <MyPointSuccess
        title={"สะสม NexCoin สำเร็จแล้ว"}
        points={pointSSPopup.points}
        listDetail={pointSSPopup.listDetail}
        toPage={-1}
        hasStamp={ishasStamp}
        countStamp={countStamp}
      />
    );
  }

  if (openSSDPopup) {
    return (
      <MyPointSuccessDone
        title={"สแกนสำเร็จ"}
        subtitle={"ขอบคุณที่ร่วมกิจกรรม"}
        points={pointSSPopup.points}
        listDetail={pointSSPopup.listDetail}
        toPage={-1}
      />
    );
  }

  return (
    <>
      <CustomPopup
        title={qrCodePopup.title}
        desc={qrCodePopup.desc}
        cancelText={"ปิด"}
        cancelFunction={() => {
          let tmp = { ...qrCodePopup };
          tmp.open = false;
          setQrCodePopup(tmp);
        }}
        open={qrCodePopup.open}
        onClose={() => {
          let tmp = { ...qrCodePopup };
          tmp.open = false;
          setQrCodePopup(tmp);
        }}
      />

      {isOpenQRInput && (
        // <div className="bg-white h-[100vh] text-center">
        <CustomPopupQr
          title={"กรอก QR Code"}
          desc={"กรุณากรอกรหัส QR Code และกดยืนยันสำหรับการสะสมคะแนน"}
          cancelFunction={() => {
            setIsOpenQRInput(false);
          }}
          actionFunction={() => {
            setIsOpenQRInput(false);
          }}
          open={openCancelPopup}
          onClose={() => {
            setIsOpenQRInput(false);
          }}
          func={pull_data}
        />
        // </div>
      )}
      <div className="bg-black h-[100vh] text-center">
        <div className="flex justify-items-start items-center">
          <div
            className="absolute flex text-white text-[20px] mb-7 ml-[18px]"
            onClick={() => navigate(-1)}
          >
            <p className="mr-[5px]">←</p>
            <p>กลับ</p>
          </div>
          <div className="flex-1 mb-10 pt-5">
            <p className="text-lightBlue text-[28px]">สะสมแสตมป์</p>
          </div>
        </div>
        <div className="text-[24px] text-white mb-4">
          วาง QR Code ให้อยู่ในพื้นที่ที่กำหนด
        </div>
        <div className="mx-[30px]">
          <QrReader
            className="qr-read-tag"
            onResult={(result, error) => {
              if (!!result) {
                setQrCode(result?.text);
              }

              if (!!error) {
                console.info(error);
              }
            }}
            constraints={{ facingMode: "environment" }}
            ViewFinder={ScanOverlay}
            videoContainerStyle={{ paddingTop: "0%" }}
            videoStyle={{ width: "100%" }}
          />

          <a className="text-white" href={qrcode} target="_blank">
            {qrcode}
          </a>
        </div>
        <div
          className="text-lightBlue text-[20px] my-3 underline decoration-1 underline-offset-4"
          onClick={openQrCodeInput}
        >
          กรอก QR Code
        </div>
      </div>
    </>
  );
}

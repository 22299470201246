import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import PrimaryButton from "../../components/button/PrimaryButton";
import TopLogo from "../../components/layout/TopLogo";
import { CreateUsePoint, SearchFreebiesById } from "../../services/RedeemGift";
import SecondaryButton from "../../components/button/SecondaryButton";
import RedeemGiftMemberError from "./RedeemGiftMemberError";
import RedeemGiftMemberFreebieOut from "./RedeemGiftMemberFreebieOut";
import RedeemGiftMemberSuccess from "./RedeemGiftMemberSuccess";
import dayjs from "dayjs";
import SelectChannelDialog from "../../components/dialog/SelectChannelDialog";
import { SearchPetOwnerProfile } from "../../services/Dogbook";
import CustomPopup from "../../components/popup/CustomPopup";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

export default function RedeemGiftMemberDetail() {
  const navigate = useNavigate();
  let { freebiesId, memberId } = useParams();
  const [detail, setDetail] = React.useState({});
  const [errorMsg, setErrorMsg] = React.useState("");
  const [isStmapError, setIsStmapError] = React.useState(false);
  const [usePointError, setUsePointError] = React.useState(false);
  const [freebieOutStock, setFreebieOutStock] = React.useState(false);
  const [successData, setSuccessData] = React.useState(null);
  const [openSelectChannelDialog, setOpenSelectChannelDialog] =
    React.useState(false);
  const [openEditedPopup, setOpenEditedPopup] = React.useState(false);
  const [openConfirmPopup, setOpenConfirmPopup] = React.useState(false);
  const [isStamp, setIsStamp] = React.useState(false);

  const [redeemStampOption, setRedeemStampOption] = React.useState([]); // แก้อันนี้ด้วยนะ
  const [redeemStampId, setRedeemStampId] = React.useState(""); // แก้อันนี้ด้วยนะ
  const [redeemStampName, setRedeemStampName] = React.useState(""); // แก้อันนี้ด้วยนะ

  useEffect(() => {
    (async () => {
      try {
        const detail = await getSearchFreebiesById();
        console.log("detail", detail);
        setDetail(detail);
        setIsStamp(detail["isRedeemByQrStamp"] === 1 ? true : false); //แก้ตรงนี้ ระหว่าง ดวงกับคะแนน ขา get

        setRedeemStampOption(detail.clinicOptionStamp);
      } catch (error) {
        console.log("fetchData error", error);
      }
    })();
  }, []);

  async function getSearchFreebiesById() {
    const { data } = await SearchFreebiesById({
      params: { freebiesId, memberId },
    });
    if (data.resultCode === 20000) {
      if (data.body.isRedeemByQrStamp === 1) {
        setIsStmapError(false);
        setErrorMsg("กรุณาเลือกร้านค้า");
      } else {
        setErrorMsg("");
      }
      return data.body || {};
    } else if (data.resultCode === 40304) {
      setErrorMsg("คะแนนของคุณไม่เพียงพอ");
      return data.body || {};
    } else if (data.resultCode === 40301) {
      if (data.body.isRedeemByQrStamp === 1) {
        setIsStmapError(true);
        setErrorMsg("ยืนยัน");
      } else {
        setErrorMsg("มีผู้แลกของรางวัลเต็มจำนวนแล้ว");
      }

      return data.body || {};
    } else {
      setErrorMsg("");
      return {};
    }
  }

  async function getSearchPetOwnerProfile() {
    const { data } = await SearchPetOwnerProfile({ member_id: memberId });
    if (+data["resultCode"] === 20000) {
      return data.body || {};
    } else if (+data["resultCode"] === 40304) {
      return data.body || {};
    } else {
      return {};
    }
  }

  async function postCreateUsePoint({ pickUpType = "DELIVERY", shopId = 0 }) {
    const payload = {
      freebiesId: freebiesId,
      memberId: memberId,
      pickUpType,
      clinicPetShopId: isStamp ? redeemStampId : shopId,
    };
    const { data } = await CreateUsePoint({
      data: payload,
    });
    if (+data["resultCode"] === 20000) {
      setUsePointError(false);
      const body = data["body"] || {};
      let address = "-";
      if (pickUpType === "DELIVERY") {
        // set address
        const member = body["member"] || {};
        const deAddressDetail = member["deAddressDetail"] || "";
        const province = member["deAddress"]["province"] || "";
        const amphoe = member["deAddress"]["amphoe"] || "";
        const subDistrict = member["deAddress"]["subDistrict"] || "";
        const zipcode = `${member["deAddress"]["zipcode"] || ""}`;
        address = `${deAddressDetail} ${subDistrict} ${amphoe} ${province} ${zipcode}`;
      }
      // set success data
      setSuccessData({
        code: body["code"],
        shopName: detail["shopName"],
        isPartner: detail["isPartner"],
        urlPicture: detail["urlPicture"],
        name: detail["name"],
        createdDate: dayjs(body["createdDate"]).format("DD/MM/YYYY HH:mm:ss"),
        points: detail["points"]?.toLocaleString(),
        address,
        isStamp: detail["isRedeemByQrStamp"] === 1 ? true : false, // แก้ตรงนี้ ระหว่าง ดวงกับคะแนน ขา Post
      });
    } else if (+data["resultCode"] === 40304) {
      setUsePointError(true);
      setSuccessData(null);
    } else if (+data["resultCode"] === 40301) {
      setFreebieOutStock(true);
      setSuccessData(null);
    } else {
      setUsePointError(true);
      setSuccessData(null);
    }
  }

  const openSelectChannel = () => {
    setOpenSelectChannelDialog(true);
  };

  const openPopupConfirm = () => {
    setOpenConfirmPopup(true);
  };

  const onClickBackFromError = () => {
    setUsePointError(false);
    setSuccessData(null);
  };

  const onClickBackFromErrorFreebie = () => {
    setFreebieOutStock(false);
    setSuccessData(null);
  };

  const toggleSelectChannelDialog = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenSelectChannelDialog(open);
  };

  async function onOk({ channelConfrim, shopId }) {
    setOpenSelectChannelDialog(false);

    console.log("channelConfrim final >>>", channelConfrim);
    console.log("shopId final >>>", shopId);

    let pickUpType = "";
    let dataView = {};
    if (channelConfrim == 1) {
      pickUpType = "DELIVERY";
      dataView = await getSearchPetOwnerProfile();
      dataView["isFormRedeem"] = true;
    } else if (channelConfrim == 2) {
      pickUpType = "SELF";
    } else {
      pickUpType = "";
    }

    if (channelConfrim === 1 && dataView["deAddress"] == null) {
      setOpenEditedPopup(true);
    } else {
      if (channelConfrim === 2 && !shopId) {
        return;
      }
      await postCreateUsePoint({ pickUpType, shopId });
    }
  }

  const handleClinicStamp = (val) => {
    if (val) {
      console.log("event >>", val);
      setErrorMsg("");
      setRedeemStampId(val);

      let option = redeemStampOption;
      const obj = option.find(({ id }) => id == val);
      setRedeemStampName(obj.name);
    } else {
      return;
    }
  };

  return (
    <div id="pRedeemGiftMemberDetailPage">
      <TopLogo />
      {!usePointError && !freebieOutStock && !successData && (
        <div
          style={{
            background:
              "linear-gradient(152.04deg, rgb(10, 65, 151) -0.97%, rgb(32, 159, 237) 81.57%)",
            minHeight: "calc(100vh - 60px)",
            padding: "20px",
          }}
        >
          <div
            className="flex flex-col justify-between bg-white px-5 py-10 rounded-[5px]"
            style={{ minHeight: "calc(100vh - 100px)" }}
          >
            <div className="text-center leading-8">
              {!isStamp && (
                <div className="text-[18px] text-red font-[600]">
                  สิทธิ์การแลกรางวัล {detail["qty"]?.toLocaleString()} สิทธิ์
                </div>
              )}
              {!isStamp && (
                <div className="text-[36px] text-lightBlue">
                  ยืนยันการแลกของรางวัล
                </div>
              )}

              <div className="text-[24px]">{detail["name"]}</div>
              {isStamp && (
                <div>
                  {"แลกรับสิทธิ์ได้ถึง " +
                    dayjs(detail.expireDate).locale("th").format("LL")}
                </div>
              )}
            </div>
            <div className="text-center">
              <img
                src={detail["urlPicture"]}
                alt="redeem image"
                className="w-1/2 inline-block"
              />
            </div>
            <div>
              {isStamp && !isStmapError ? (
                <div className="pb-[8px]">
                  <FormControl
                    fullWidth
                    // error={true}
                  >
                    <InputLabel style={{ marginLeft: "-14px" }}>
                      เลือกร้านค้า
                    </InputLabel>
                    <Select
                      // displayEmpty
                      IconComponent={KeyboardArrowDownIcon}
                      fullWidth
                      value={redeemStampId}
                      variant="standard"
                      onChange={(event) => {
                        handleClinicStamp(event.target.value);
                      }}
                    >
                      {redeemStampOption &&
                        redeemStampOption.map((item, i) => (
                          <MenuItem value={item.id} key={i}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              ) : (
                <></>
              )}

              <div className="text-[24px] text-lightBlue">
                เงื่อนไขการรับของรางวัล
              </div>
              <div
                id="bTextEditor"
                className="pl-4"
                dangerouslySetInnerHTML={{
                  __html: `${detail["description"]}`.replaceAll(
                    "<a href=",
                    '<a target="_blank" href='
                  ),
                }}
              ></div>
            </div>
            <div>
              {!errorMsg &&
                (isStamp ? (
                  <PrimaryButton
                    variant="contained"
                    className="w-full"
                    onClick={openPopupConfirm}
                  >
                    ยืนยัน
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    variant="contained"
                    className="w-full"
                    disabled={+detail["points"] <= 0}
                    onClick={openSelectChannel}
                  >
                    ใช้ {detail["points"]?.toLocaleString()} คะแนน
                  </PrimaryButton>
                ))}

              {!!errorMsg && isStmapError && (
                <div
                  className="text-[18px] text-red font-[600]"
                  style={{ textAlign: "center" }}
                >
                  ขออภัยตอนนี้สินค้าหมดชั่วคราว
                </div>
              )}
              {!!errorMsg && (
                <PrimaryButton variant="contained" className="w-full" disabled>
                  {errorMsg}
                </PrimaryButton>
              )}
              <div className="my-3"></div>
              <SecondaryButton
                variant="outlined"
                className="w-full"
                onClick={() => {
                  navigate(-1);
                }}
              >
                ยกเลิก
              </SecondaryButton>
              <div className="my-3"></div>
            </div>
          </div>
        </div>
      )}
      {usePointError && (
        <RedeemGiftMemberError onClick={onClickBackFromError} />
      )}
      {freebieOutStock && (
        <RedeemGiftMemberFreebieOut onClick={onClickBackFromErrorFreebie} />
      )}
      {!usePointError && !freebieOutStock && successData && (
        <RedeemGiftMemberSuccess detail={successData} />
      )}
      {!isStamp && (
        <SelectChannelDialog
          title="เลือกวิธีการรับของรางวัล"
          open={openSelectChannelDialog}
          toggle={toggleSelectChannelDialog}
          clinicList={detail["clinicList"]}
          onAction={detail["actionPickUpType"]}
          onOk={onOk}
        ></SelectChannelDialog>
      )}

      {isStamp && (
        <CustomPopup
          title={""}
          desc={"คุณยืนยันรับสิทธิ์ที่ " + redeemStampName + " ใช่หรือไม่"}
          cancelText={"ยกเลิก"}
          actionText={"ยืนยัน"}
          cancelFunction={() => {
            setOpenConfirmPopup(false);
          }}
          actionFunction={() => {
            setOpenConfirmPopup(false);
            postCreateUsePoint({ pickUpType: "SELF", shopId: redeemStampId });
          }}
          open={openConfirmPopup}
          onClose={() => {
            setOpenConfirmPopup(false);
          }}
        />
      )}

      <CustomPopup
        title={"ยังไม่มีที่อยู่จัดส่งของคุณในระบบ"}
        desc={
          "กรุณากรอกข้องมูลที่อยู่จัดส่งที่หน้า ‘สมุดพกน้องหมาและข้อมูลผู้ใช้’ โดยกดที่ปุ่มด้านล่างนี้"
        }
        cancelText={"ปิด"}
        actionText={"กรอกข้อมูลที่อยู่"}
        actionFunction={() => {
          navigate("/dogbook-edit-info");
          setOpenEditedPopup(false);
        }}
        open={openEditedPopup}
      />
    </div>
  );
}

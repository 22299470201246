import React from "react";
import PrimaryButton from "../../components/button/PrimaryButton";
import errorCircle from "../../assets/images/icon/error-circle.svg";

export default function RedeemGiftMemberError({onClick}) {
  return (
    <>
      <div className="bg-img-blue h-screen-logo flex flex-col justify-between text-center">
        <div className="m-auto">
          <img src={errorCircle} alt="error icon" className="inline-block py-5"/>
          <div className="text-[36px] text-white leading-[100%]">ขออภัย</div>
          <div className="text-[24px] text-white leading-[100%]">NexCoin ของคุณไม่เพียงพอ</div>
        </div>
        <div className="flex-none h-20">
          <PrimaryButton
            variant="contained"
            className="w-[70%] !bg-[#082e683d]"
            onClick={onClick}
          >
            กลับไปหน้าแลกของรางวัล
          </PrimaryButton>
        </div>
      </div>
    </>
  )
}
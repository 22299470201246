import React from "react";

export default function CircleExit(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_516_2930)">
        <circle cx="10" cy="10" r="9.5" stroke="#8F8F8F" />
        <g clip-path="url(#clip1_516_2930)">
          <path
            d="M7.4287 12.5713L10 10M10 10L12.5713 7.4287M10 10L12.5713 12.5713M10 10L7.4287 7.4287"
            stroke="#8F8F8F"
            stroke-width="1.3"
            stroke-linecap="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_516_2930">
          <rect width="20" height="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_516_2930">
          <rect
            width="10.9091"
            height="10.9091"
            fill="white"
            transform="translate(10 2.28611) rotate(45)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

import React from "react";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import PetShopCircle from "../../assets/images/register/pet-shop-circle.png";

import Stack from "@mui/material/Stack";
import TopBanner from "../../components/layout/TopBanner";
import registerStep3 from "../../assets/images/register/register-step-3.svg";

import { Controller, useForm } from "react-hook-form";
import SecondaryButton from "../../components/button/SecondaryButton";
import PrimaryButton from "../../components/button/PrimaryButton";
import { useNavigate } from "react-router-dom";
import CustomPopup from "../../components/popup/CustomPopup";
import { Autocomplete, Checkbox } from "@mui/material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { SearchDdlAddress } from "../../services/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { setRegisterPetShop } from "../../features/register/RegisterPetShopSlice";
import TopLogo from "../../components/layout/TopLogo";

export default function RegisterPetShopConfirm() {
  const store = useSelector((state) => state.registerPetShop);
  const clinicPetShopData = store["clinicPetShopData"];
  const dispatch = useDispatch();

  const txt = {
    require: "กรุณากรอก",
    province: "จังหวัด",
    amphoe: "เขต/อำเภอ",
    tambol: "แขวง/ตำบล",
    zipcode: "รหัสไปรษณีย์",
  };
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    control,
    watch,
  } = useForm({
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {
      isDeliveryAddress: store["isDeliveryAddress"],
      shippingAddress: store["shippingAddress"],
      shippingTambol: store["shippingTambol"],
      shippingAmphoe: store["shippingAmphoe"],
      shippingProvince: store["shippingProvince"],
      shippingZipcode: store["shippingZipcode"],
      selectedAddress: store["selectedAddress"],
    },
  });
  const [openCancelPopup, setOpenCancelPopup] = React.useState(false);
  const [openShippingTambol, setOpenShippingTambol] = React.useState(false);
  const [openShippingAmphoe, setOpenShippingAmphoe] = React.useState(false);
  const [openShippingProvince, setOpenShippingProvince] = React.useState(false);
  const [openShippingZipcode, setOpenShippingZipcode] = React.useState(false);
  const [addressList, setAddressList] = React.useState([]);
  const [delayId, setDelayId] = React.useState(null);
  const [popupTitle, setPopupTitle] = React.useState("");
  const [popupDesc, setPopupDesc] = React.useState(
    "กรอกรหัสร้านค้าไม่ถูกต้อง\nกรุณาลองใหม่อีกครั้ง"
  );

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name && type) {
        console.log(value, name, type);
        fnSetData(name, value[name]);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  function fnSetData(name, value) {
    const tmpObj = {};
    tmpObj[name] = value;
    dispatch(setRegisterPetShop(tmpObj));
  }

  const onSubmit = (data) => {
    console.log("data", data);
    if (store["isOwner"] === "yes" && !store["isDeliveryAddress"]) {
      const tambolId = data.shippingTambol.id;
      const amphoeId = data.shippingAmphoe.id;
      const provinceId = data.shippingProvince.id;
      const zipcodeId = data.shippingZipcode.id;
      if (tambolId && amphoeId && provinceId && zipcodeId) {
        fnSetData("isPetShop", true);
        navigate("/privacy-policy");
      } else {
        setPopupTitle("ไม่สามารถทำรายการได้");
        setPopupDesc("กรุณาเลือกข้อมูลที่อยู่จัดส่งที่มีอยู่ในระบบ");
        setOpenCancelPopup(true);
      }
    } else {
      fnSetData("isPetShop", true);
      navigate("/privacy-policy");
    }
  };

  async function getSearchDdlAddress(search) {
    try {
      const { data } = await SearchDdlAddress({ params: { search } });
      if (+data["resultCode"] === 20000) {
        const body = data.body || [];
        console.log("body", body);
        if (Object.keys(body).length === 0) {
          setAddressList([]);
        } else {
          setAddressList(body);
        }
      } else {
        setAddressList([]);
      }
    } catch (error) {
      console.log("getSearchDdlAddress error", error);
    }
  }

  function onClickBack() {
    navigate(-1);
  }

  function fnSetOpenAddress(type, open) {
    if (type === txt.tambol) {
      setOpenShippingTambol(open);
    } else if (type === txt.amphoe) {
      setOpenShippingAmphoe(open);
    } else if (type === txt.province) {
      setOpenShippingProvince(open);
    } else if (type === txt.zipcode) {
      setOpenShippingZipcode(open);
    }
  }

  async function onInputChangeAddress(e, type) {
    fnSetOpenAddress(type, false);
    const value = e.target.value;
    if (type === txt.tambol) {
      setValue("shippingTambol", {}, { shouldValidate: true });
    } else if (type === txt.amphoe) {
      setValue("shippingAmphoe", {}, { shouldValidate: true });
    } else if (type === txt.province) {
      setValue("shippingProvince", {}, { shouldValidate: true });
    } else if (type === txt.zipcode) {
      setValue("shippingZipcode", {}, { shouldValidate: true });
    }
    clearTimeout(delayId);
    const timeoutId = setTimeout(async () => {
      if (value) {
        await getSearchDdlAddress(value);
        fnSetOpenAddress(type, true);
      } else {
        setAddressList([]);
      }
    }, 500);
    setDelayId(timeoutId);
  }

  function fnRenderOption(props, option, { inputValue }) {
    const address1 = `${option.subDistrict}, ${option.amphoe}, ${option.zipcode}`;
    const address2 = `จังหวัด ${option.province}`;

    const matches1 = match(address1, inputValue, { insideWords: true });
    const parts1 = parse(address1, matches1);

    const matches2 = match(address2, inputValue, { insideWords: true });
    const parts2 = parse(address2, matches2);

    return (
      <li {...props} className="text-left pl-1 border-address-list">
        {/*display address*/}
        <div>
          {parts1.map((part, index) => (
            <span
              key={"address" + index}
              style={{
                fontWeight: part.highlight ? 700 : 400,
                color: part.highlight ? "#0A4197" : "#8F8F8F",
              }}
            >
              {part.text}
            </span>
          ))}
        </div>
        {/*display province*/}
        <div>
          {parts2.map((part, index) => (
            <span
              key={"province" + index}
              style={{
                fontWeight: part.highlight ? 700 : 400,
                color: part.highlight ? "#0A4197" : "#8F8F8F",
              }}
            >
              {part.text}
            </span>
          ))}
        </div>
      </li>
    );
  }

  function fnOnChangeAddress(e, value) {
    fnSetOpenAddress(txt.tambol, false);
    fnSetOpenAddress(txt.amphoe, false);
    fnSetOpenAddress(txt.province, false);
    fnSetOpenAddress(txt.zipcode, false);

    fnSetData("shippingTambol", value);
    setValue("shippingTambol", value, { shouldValidate: true });

    fnSetData("shippingAmphoe", value);
    setValue("shippingAmphoe", value, { shouldValidate: true });

    fnSetData("shippingProvince", value);
    setValue("shippingProvince", value, { shouldValidate: true });

    fnSetData("shippingZipcode", value);
    setValue("shippingZipcode", value, { shouldValidate: true });
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <TopLogo />
        <TopBanner />
        <Container maxWidth="xl" className="mt-[-20px]">
          <div className="bg-white">
            <img id="registerStep2" src={registerStep3} alt="registerStep" />
            <div className="p-3">
              <div className="text-[30px] text-center">
                ลงทะเบียนสำหรับ
                <span className="font-bold">
                  โรงพยาบาลสัตว์ คลินิก และร้านเพ็ทช็อป
                </span>
              </div>
              <div className="text-center">
                <img
                  src={PetShopCircle}
                  alt="logo"
                  className="w-[25%] inline-block"
                />
              </div>
              <div className="py-[0px] px-[10px]">
                <div className="primary-color title-size">
                  <div className="leading-[110%]">
                    ข้อมูลโรงพยาบาลสัตว์ คลินิก
                    <br />
                    และร้านเพ็ทช็อป
                  </div>
                </div>
                <div className="text-[20px] py-5">
                  <div className="flex flex-row">
                    <div className="basis-1/3 text-gray">รหัสร้านค้า:</div>
                    <div>{clinicPetShopData["shopNo"]}</div>
                  </div>
                  <div className="flex flex-row">
                    <div className="basis-1/3 text-gray">ประเภท:</div>
                    <div>{clinicPetShopData["typeDisplay"]}</div>
                  </div>
                  <div className="flex flex-row">
                    <div className="basis-1/3 text-gray">ชื่อร้านค้า:</div>
                    <div>{clinicPetShopData["shopName"]}</div>
                  </div>
                  <div className="text-gray">ที่อยู่:</div>
                  <div>{store["address"]}</div>
                  {store["isOwner"] === "yes" && (
                    <div id="cbxDeliveryAddress">
                      <Controller
                        name="isDeliveryAddress"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            {...field}
                            checked={store["isDeliveryAddress"]}
                            control={<Checkbox />}
                            label="ใช้ที่อยู่นี้เป็นที่อยู่จัดส่งของรางวัล"
                          />
                        )}
                      />
                    </div>
                  )}
                  {store["isOwner"] === "yes" &&
                    !store["isDeliveryAddress"] && (
                      <>
                        <div className="primary-color title-size">
                          ข้อมูลที่อยู่จัดส่ง
                        </div>
                        <div className="grid gap-y-0.5 grid-cols-1">
                          <div className="h-input">
                            <TextField
                              required
                              fullWidth
                              label="รายละเอียดที่อยู่"
                              variant="standard"
                              error={!!errors?.shippingAddress}
                              helperText={errors?.shippingAddress?.message}
                              {...register("shippingAddress", {
                                required: "กรุณากรอกรายละเอียดที่อยู่",
                              })}
                            />
                          </div>
                          <div className="h-input">
                            <Controller
                              name="shippingTambol"
                              control={control}
                              rules={{
                                required: `${txt.require}${txt.tambol}`,
                              }}
                              render={({ field }) => (
                                <Autocomplete
                                  {...field}
                                  freeSolo
                                  disableClearable
                                  onChange={fnOnChangeAddress}
                                  options={addressList}
                                  open={openShippingTambol}
                                  onClose={() =>
                                    fnSetOpenAddress(txt.tambol, false)
                                  }
                                  getOptionLabel={(option) =>
                                    option.subDistrict
                                  }
                                  value={store["shippingTambol"]}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      required
                                      fullWidth
                                      label={txt.tambol}
                                      variant="standard"
                                      error={!!errors?.shippingTambol}
                                      helperText={
                                        errors?.shippingTambol?.message
                                      }
                                      onInput={(e) =>
                                        onInputChangeAddress(e, txt.tambol)
                                      }
                                    />
                                  )}
                                  renderOption={fnRenderOption}
                                />
                              )}
                            />
                          </div>
                          <div className="h-input">
                            <Controller
                              name="shippingAmphoe"
                              control={control}
                              rules={{
                                required: `${txt.require}${txt.amphoe}`,
                              }}
                              render={({ field }) => (
                                <Autocomplete
                                  {...field}
                                  freeSolo
                                  disableClearable
                                  onChange={fnOnChangeAddress}
                                  options={addressList}
                                  open={openShippingAmphoe}
                                  onClose={() =>
                                    fnSetOpenAddress(txt.amphoe, false)
                                  }
                                  getOptionLabel={(option) =>
                                    option.amphoe || ""
                                  }
                                  value={store["shippingAmphoe"]}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      required
                                      fullWidth
                                      label={txt.amphoe}
                                      variant="standard"
                                      error={!!errors?.shippingAmphoe}
                                      helperText={
                                        errors?.shippingAmphoe?.message
                                      }
                                      onInput={(e) =>
                                        onInputChangeAddress(e, txt.amphoe)
                                      }
                                    />
                                  )}
                                  renderOption={fnRenderOption}
                                />
                              )}
                            />
                          </div>
                          <div className="h-input">
                            <Controller
                              name="shippingProvince"
                              control={control}
                              rules={{
                                required: `${txt.require}${txt.province}`,
                              }}
                              render={({ field }) => (
                                <Autocomplete
                                  {...field}
                                  freeSolo
                                  disableClearable
                                  onChange={fnOnChangeAddress}
                                  options={addressList}
                                  open={openShippingProvince}
                                  onClose={() =>
                                    fnSetOpenAddress(txt.province, false)
                                  }
                                  getOptionLabel={(option) =>
                                    option.province || ""
                                  }
                                  value={store["shippingProvince"]}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      required
                                      fullWidth
                                      label={txt.province}
                                      variant="standard"
                                      error={!!errors?.shippingProvince}
                                      helperText={
                                        errors?.shippingProvince?.message
                                      }
                                      onInput={(e) =>
                                        onInputChangeAddress(e, txt.province)
                                      }
                                    />
                                  )}
                                  renderOption={fnRenderOption}
                                />
                              )}
                            />
                          </div>
                          <div className="h-input">
                            <Controller
                              name="shippingZipcode"
                              control={control}
                              rules={{
                                required: `${txt.require}${txt.zipcode}`,
                              }}
                              render={({ field }) => (
                                <Autocomplete
                                  {...field}
                                  freeSolo
                                  disableClearable
                                  onChange={fnOnChangeAddress}
                                  options={addressList}
                                  open={openShippingZipcode}
                                  onClose={() =>
                                    fnSetOpenAddress(txt.zipcode, false)
                                  }
                                  getOptionLabel={(option) =>
                                    option.zipcode || ""
                                  }
                                  value={store["shippingZipcode"]}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      required
                                      fullWidth
                                      label={txt.zipcode}
                                      variant="standard"
                                      error={!!errors?.shippingZipcode}
                                      helperText={
                                        errors?.shippingZipcode?.message
                                      }
                                      onInput={(e) =>
                                        onInputChangeAddress(e, txt.zipcode)
                                      }
                                    />
                                  )}
                                  renderOption={fnRenderOption}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div className="bg-white mt-[20px] h-[100px] pt-[25px]">
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SecondaryButton
              variant="outlined"
              className="w-[40%]"
              onClick={onClickBack}
            >
              กลับ
            </SecondaryButton>
            <PrimaryButton
              variant="contained"
              className="w-[40%]"
              type="submit"
            >
              ยืนยัน
            </PrimaryButton>
          </Stack>
        </div>
      </form>

      <CustomPopup
        title={popupTitle}
        desc={popupDesc}
        cancelText="ปิด"
        cancelFunction={() => {
          setOpenCancelPopup(false);
        }}
        open={openCancelPopup}
        onClose={() => {
          // setOpenCancelPopup(false);
        }}
      />
    </>
  );
}

import {Dialog} from "@mui/material";
import React from "react";
import PrimaryButton from "../button/PrimaryButton";
import SecondaryButton from "../button/SecondaryButton";

export default function ConfirmShopPopup({title, data, onClose, open, actionFunction, cancelFunction}) {
  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="xl">
      <div className="pb-[36px] pt-[24px] px-[23px] rounded-[4px]">
        <p className="text-lightBlue text-[28px] text-center">{title}</p>
        <div className="text-[20px] py-5">
          <div className="flex flex-row">
            <div className="basis-1/3 text-gray">รหัสร้านค้า:</div>
            <div>{data['shopNo']}</div>
          </div>
          <div className="flex flex-row">
            <div className="basis-1/3 text-gray">ประเภท:</div>
            <div>{data['typeDisplay']}</div>
          </div>
          <div className="flex flex-row">
            <div className="basis-1/3 text-gray">ชื่อร้านค้า:</div>
            <div>{data['shopName']}</div>
          </div>
        </div>
        {/*<p className="text-gray text-[18px] text-center">{desc}</p>*/}
        <div className="flex itesm-center gap-[13px] justify-center mt-[24px]">
          <SecondaryButton
            variant="outlined"
            className="w-full"
            onClick={() => cancelFunction()}
          >
            ยกเลิก
          </SecondaryButton>
          <PrimaryButton
            onClick={() => actionFunction()}
            variant="outlined"
            className="w-full"
          >
            ยืนยัน
          </PrimaryButton>
        </div>
      </div>
    </Dialog>
  );
}

import React from "react";
import Layouts from "./layout/Layout";
import { Route, Routes } from "react-router-dom";
import OTP from "./pages/NotAuth/OTP";
import Asset from "./pages/NotAuth/Asset";
import LiffBasic from "./pages/NotAuth/LiffBasic";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Questionnaire from './pages/questionnaire/QuestionnaireMain';
import QuestionnaireSuccess from './pages/questionnaire/QuestionnaireSuccess';
import QuestionnaireNotFound from './pages/questionnaire/QuestionnaireNotFound';
import LoadingMain from './components/dialog/LoadingMain'
import ArticlePoint from "./pages/auth-redirect/ArticlePoint";
import QuizMania from "./pages/quiz-mania/QuizMania";
import QuizManiaRank from "./pages/quiz-mania/QuizManiaRank";

const theme = createTheme({
  typography: {
    fontFamily: `'DB Helvethaica X', 'Kanit', sans-serif`,
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

function App() {

  return (
    <>
     <ThemeProvider theme={theme}>
          <Layouts>
            <LoadingMain/>
            <Routes>
              <Route path="/otp" element={<OTP />} />
              <Route path="/liff-basic" element={<LiffBasic />} />
              <Route path="/asset" element={<Asset />} />
              <Route path="/questionnaire" element={<Questionnaire />} />
              <Route path="/quiz-mania" element={<QuizMania />} />
              <Route path="/quiz-mania-rank" element={<QuizManiaRank/>} />
              <Route path="/article-point" element={<ArticlePoint />} />
            </Routes>
          </Layouts>
        </ThemeProvider>
    </>
  );
}

export default App;

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/button/PrimaryButton";
import SecondaryButton from "../../components/button/SecondaryButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import calendar from "../../assets/images/icon/calendar.svg";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomPopup from "../../components/popup/CustomPopup";
import { SearchId } from "../../services/SearchId";
import { postUpdatePet, SearchDdlPet } from "../../services/Dogbook";
// import { tempLineId1 } from "../../services/Api";
import { useLiff } from "react-liff";
import TopLogo from "../../components/layout/TopLogo";
import helper from "../../functions/helper";
import dayjs from "dayjs";

export default function EditNotification() {
  const navigate = useNavigate();

  const [openCancelPopup, setOpenCancelPopup] = React.useState(false);
  const [saveNotiPopup, setSaveNotiPopup] = React.useState(false);
  const [pet, setPet] = useState(null);
  const [petId, setPetId] = useState(null);
  const [lastDateProduct, setLastDateProduct] = useState(null);
  const [validateDate, setValidateDate] = useState(false);

  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  useEffect(() => {
    getSearchId();
    //
  }, []);

  async function getSearchId() {
    return await SearchId({
      params: { lineId: lineId || null },
    })
      .then(async (response) => {
        if (!response.data.body.memberId) {
          window.location.replace("/register");
        } else getPet(response.data.body.memberId);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getPet(member_id) {
    return await SearchDdlPet({
      params: { lineId: lineId || null },
      member_id: member_id,
    })
      .then(async (response) => {
        if (response.data.resultCode === 20000) {
          setPet(response.data.body);
          setPetId(response.data.body[0].id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function postUpdatePetLastDate() {
    const payload = {
      petId: petId,
      lastDateProduct: lastDateProduct,
      notifyTime: (() => {
        if (lastDateProduct) {
          const _lastDateProduct = dayjs(lastDateProduct).add(0, "month");
          return helper.postUTC(_lastDateProduct);
        }
      })(),
    };
    try {
      const { data } = await postUpdatePet({ data: payload });
      console.log("resData.data >>", data);
      if (data.resultCode === 20000) {
        console.log(payload);
        setSaveNotiPopup(true);
      }
    } catch (error) {
      console.log("postUpdatePetLastDate error", error);
    }
  }

  return (
    <div id="editnoti-page">
      <CustomPopup
        title={"บันทึกแจ้งเตือนพบคุณหมอ"}
        desc={
          "ระบบจะแจ้งเตือนคุณผ่าน Line @spectrafordog เพื่อให้ได้รับการแจ้งเตือน กรุณาเปิดรับแจ้งเตือนไว้ตลอด"
        }
        cancelText={"ปิด"}
        cancelFunction={() => {
          window.open("/dogbook", "_self");
          setSaveNotiPopup(false);
        }}
        open={saveNotiPopup}
        onClose={() => {
          window.open("/dogbook", "_self");
          setSaveNotiPopup(false);
        }}
      />
      <CustomPopup
        title={"ยกเลิกการตั้งแจ้งเตือน"}
        desc={
          "คุณกำลังยกเลิกตั้งแจ้งเตือนพบคุณหมอให้กับน้องหมา คุณสามารถกลับมาตั้งแจ้งเตือนใหม่ได้ในหน้า สมุดพกน้องหมาและข้อมูลผู้ใช้"
        }
        cancelFunction={() => {
          setOpenCancelPopup(false);
        }}
        actionFunction={() => {
          navigate(-1);
          setOpenCancelPopup(false);
        }}
        open={openCancelPopup}
        onClose={() => {
          setOpenCancelPopup(false);
        }}
      />
      <TopLogo />
      <div className="relative gradientBg min-h-[calc(100vh-60px)] px-[13px] py-[27px]">
        <div className="flex flex-col justify-between bg-white py-[24px] px-[17px] rounded-[4px] min-h-[calc(100vh-123px)]">
          <div>
            <p className="text-lightBlue text-[28px] text-center">
              ตั้งเวลาแจ้งเตือนพบคุณหมอ
            </p>

            <p className="mt-[24px] text-left text-[24px] text-darkGray">
              เลือกน้องหมาที่ต้องการให้แจ้งเตือน
            </p>

            <div className="mt-[10px]">
              <FormControl fullWidth>
                <InputLabel style={{ marginLeft: "-14px" }} required>
                  ชื่อน้องหมา
                </InputLabel>

                <Select
                  displayEmpty
                  IconComponent={KeyboardArrowDownIcon}
                  fullWidth
                  value={petId || ""}
                  variant="standard"
                  onChange={(e) => setPetId(e.target.value)}
                >
                  {pet &&
                    pet.map((item, i) => (
                      <MenuItem value={item.id} key={i}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>

            <div className="relative mt-[15px]">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  className="w-full"
                  label="วันที่นัดพบคุณหมอ"
                  variant="standard"
                  inputFormat="MM/DD/YYYY"
                  value={lastDateProduct}
                  onChange={(value) => {
                    console.log(value);
                    setLastDateProduct(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      required
                      {...params}
                      error={validateDate}
                      helperText="วันที่นัดพบคุณหมอไม่ถูกต้อง"
                    />
                  )}
                />
                <img
                  className={
                    `absolute right-[10px] bottom-[8px] w-[15px] pointer-events-none ` +
                    (validateDate ? "!bottom-[35px]" : null)
                  }
                  src={calendar}
                  alt=""
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="flex itesm-center gap-[13px] justify-center bg-white px-[20px] pt-[16px] pb-[24px]">
            <SecondaryButton
              variant="outlined"
              className="w-full"
              onClick={() => setOpenCancelPopup(true)}
            >
              ยกเลิก
            </SecondaryButton>
            <PrimaryButton
              onClick={() => {
                if (lastDateProduct !== null && petId !== null)
                  postUpdatePetLastDate();
                else setValidateDate(true);
              }}
              variant="outlined"
              className="w-full"
            >
              บันทึก
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
}

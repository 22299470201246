import React, { useCallback, useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import redeemHistoryLabel from "../../assets/images/redeem-gift/redeem-history-label.svg";
import boxEmpty from "../../assets/images/icon/box-empty.svg";
import TopBanner from "../../components/layout/TopBanner";
import { SearchId } from "../../services/SearchId";
import { useLiff } from "react-liff";
import TopLogo from "../../components/layout/TopLogo";
import dayjs from "dayjs";
import {
  SearchFreebies,
  SearchFreebiesHistory,
} from "../../services/RedeemGift";
import InfiniteScroll from "react-infinite-scroller";
import { useNavigate } from "react-router-dom";
import MemberCard from "../../components/card/MemberCard";
import PrimaryButton from "../../components/button/PrimaryButton";

export default function RedeemGiftMember() {
  const navigate = useNavigate();
  let [page, setPage] = useState(1);

  let [freebies, setFreebies] = useState([]);
  let [freebiesHistory, setFreebiesHistory] = useState([]);
  let [member, setMember] = useState({});

  let [freebieStamp, setFreebieStamp] = useState([]); // update ตัวนี้นะ

  const lazyLoadItem = 4;
  const [nextPage, setNextPage] = useState(null);
  const [items, setItems] = useState([]);
  const [fetching, setFetching] = useState(false);

  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  useEffect(() => {
    (async () => {
      try {
        const { memberId } = await getSearchId();
        if (!memberId) throw "memberId not found";
        const { member, freebies, freebiesStamp } = await getSearchFreebies(
          memberId
        );
        setMember(member);
        setFreebies(freebies);
        setFreebieStamp(freebiesStamp);
        setNextPage("0");
        const freebiesHistory = await getSearchFreebiesHistory(memberId);
        setFreebiesHistory(freebiesHistory);
      } catch (error) {
        console.log("fetchData error", error);
      }
    })();
  }, []);

  async function getSearchId() {
    const { data } = await SearchId({ params: { lineId } });
    if (+data["resultCode"] === 20000) {
      return data.body || {};
    } else {
      return {};
    }
  }

  async function getSearchFreebies(memberId) {
    const { data } = await SearchFreebies({
      params: { type: "MEMBER", memberId },
    });
    if (+data["resultCode"] === 20000) {
      return data.body || {};
    } else {
      return {};
    }
  }

  async function getSearchFreebiesHistory(memberId) {
    const { data } = await SearchFreebiesHistory({ params: { memberId } });
    if (+data["resultCode"] === 20000) {
      return data.body || {};
    } else {
      return {};
    }
  }

  function Item(data, toPage = "") {
    return (
      <div
        onClick={() => {
          navigate(
            `/redeem-gift-member-detail/${data["freebiesId"]}/${member["memberId"]}`
          );
        }}
      >
        <img src={data["urlPicture"]} alt="image" className="mb-3" />
        <div className="text-[24px] text-darkGray font-[600] leading-[100%]">
          {data["name"]}
        </div>
        <div className="text-[20px] text-blue font-[600] leading-[100%]">
          {toPage === "STAMP"
            ? "สิทธิ์ของท่าน: " + data["amount"]?.toLocaleString() + " สิทธิ์"
            : data["points"]?.toLocaleString() + " คะแนน"}
        </div>
      </div>
    );
  }

  function ItemHistory(data) {
    const freebies = data["freebies"];
    return (
      <div className="relative">
        <img
          src={redeemHistoryLabel}
          alt="redeem-history-label"
          className="absolute right-0"
        />
        <img src={freebies.urlPicture} alt="image" className="mb-3" />
        <div className="text-[24px] text-darkGray font-[600] leading-[100%]">
          {freebies.name}
        </div>
        {data.isRedeemByQrStamp === 1 ? (
          <div className="text-[20px] text-blue font-[600] leading-[100%]">
            ใช้แสตมป์ {6} ดวง
          </div>
        ) : (
          <div className="text-[20px] text-blue font-[600] leading-[100%]">
            {freebies.points?.toLocaleString()} คะแนน
          </div>
        )}
        {/* <div className="text-[20px] text-blue font-[600] leading-[100%]">
          {freebies.points?.toLocaleString()} คะแนน
        </div> */}
        <div className="text-gray text-[12px]">
          แลกเมื่อวันที่ {dayjs(data["createdDate"]).format("DD/MM/YYYY")}
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (items.length < freebies.length) {
      setNextPage(items.length);
    } else {
      setNextPage(null);
    }
    setFetching(false);
  }, [items]);

  const fetchItems = useCallback(() => {
    if (items.length < freebies.length) {
      console.log("fetching", fetching);
      if (fetching) {
        return;
      }
      setFetching(true);
      setTimeout(() => {
        const sliceItems = freebies.slice(+nextPage, +nextPage + lazyLoadItem);
        setItems([...items, ...sliceItems]);
      }, 1500);
    } else {
      setNextPage(null);
    }
  }, [fetching, freebies, items, nextPage]);

  const hasMoreItems = !!nextPage;

  const loader = (
    <div key="loader" className="loader text-center text-[20px] text-gray">
      Loading ...
    </div>
  );

  return (
    <>
      <div id="redeem-gift-member-page">
        <TopLogo />
        <TopBanner type="redeemGiftMember" />
        <div
          id="redeem-gift-member"
          className="gradientBg mt-[-20px] px-[20px] pb-2"
        >
          <div className="relative">
            <MemberCard data={member} />
            <div className="py-[16px] text-white">
              <div className="flex justify-between text-[20px]">
                <div>NexCoin สะสมรวม</div>
                <div>
                  <span className="text-[24px] font-[600]">
                    {member["currentPoint"]?.toLocaleString()}
                  </span>
                  &nbsp;คะแนน
                </div>
              </div>
              <div className="mt-[-10px] mb-5">
                {/* คะแนนของท่านจะหมดอายุในวันที่ 31/12/{dayjs().year()} */}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-[5px] mx-4 mt-[-30px] mb-10">
          <div className="flex items-center">
            <p
              onClick={() => setPage(1)}
              className={
                `w-full text-[20px] text-gray text-center py-[9px] border-b-[1px] border-lightGray ` +
                (page === 1 && "!text-blue !border-b-[4px] !border-blue")
              }
            >
              แลกของรางวัล
            </p>
            <p
              onClick={() => setPage(2)}
              className={
                `w-full text-[20px] text-gray text-center py-[9px] border-b-[1px] border-lightGray ` +
                (page === 2 && "!text-blue !border-b-[4px] !border-blue")
              }
            >
              ประวัติการแลกของรางวัล
            </p>
          </div>
          <>
            {page === 1 && freebieStamp.length > 0 ? (
              <div className="px-[16px]">
                <p className="text-[28px] text-lightBlue pt-2 pb-2">
                  ของรางวัลจาก NexStamp
                </p>

                <div className="grid grid-cols-2 gap-2 gap-y-[24px] pb-10">
                  {freebieStamp.map((obj) => (
                    <div>{Item(obj, "STAMP")}</div>
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
          {page === 1 && (
            <>
              <div className="px-[16px] pb-[24px]">
                <p className="text-[28px] text-lightBlue pt-2 pb-2">
                  รายการทั้งหมด{" "}
                </p>
                {freebies.length > 0 && (
                  <InfiniteScroll
                    loadMore={fetchItems}
                    hasMore={hasMoreItems}
                    loader={loader}
                  >
                    <div className="grid grid-cols-2 gap-2 gap-y-[24px] pb-10">
                      {items.map((obj) => (
                        <div>{Item(obj)}</div>
                      ))}
                    </div>
                  </InfiniteScroll>
                )}
                {freebies.length === 0 && (
                  <div className="text-center">
                    <img
                      src={boxEmpty}
                      alt="data not found"
                      className="inline-block mb-3"
                    />
                    <div className="text-darkGray text-[24px] font-bold">
                      ยังไม่มีของรางวัลในขณะนี้
                    </div>
                    <div className="text-gray text-[16px] leading-[100%]">
                      กรุณากลับมาเช็กของรางวัลของท่านอีกครั้งในโอกาสหน้า
                      <br />
                      หรือติดตามข่าวสารจาก Line @spectrafordog
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {page === 2 && (
            <div className="p-[16px]">
              <p className="text-[24px] text-lightBlue">
                ประวัติการแลกของรางวัล
              </p>
              {freebiesHistory.length > 0 && (
                <div className="grid grid-cols-2 gap-2 gap-y-[24px] pb-20">
                  {freebiesHistory.map((obj) => (
                    <div>{ItemHistory(obj)}</div>
                  ))}
                </div>
              )}
              {freebiesHistory.length === 0 && (
                <div className="text-center">
                  <img
                    src={boxEmpty}
                    alt="data not found"
                    className="inline-block mb-3"
                  />
                  <div className="text-darkGray text-[24px] font-bold">
                    ยังไม่มีประวัติการแลกของรางวัล
                  </div>
                  <div className="text-gray text-[16px]">
                    คุณสามารถแลกของรางวัลได้ เมื่อมี NexCoin สะสมที่เพียงพอ
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col justify-center bg-white px-[15px] pt-[16px] pb-[24px] fixed bottom-0 left-0 right-0">
        <PrimaryButton
          variant="contained"
          className="w-[100%]"
          onClick={() => navigate("/my-point")}
        >
          เช็กคะแนน/สะสมคะแนน
        </PrimaryButton>
      </div>
    </>
  );
}

import React from "react";

import useWindowDimensions from "../../functions/getScreen";
import getTime from "../../functions/getTime";

import welcomeCard from "../../assets/images/member-card/member/welcome.png";
import blackCard from "../../assets/images/member-card/member/black.png";
import ultimateCard from "../../assets/images/member-card/member/ultimate.png";

export default function ShopCard(props) {
    const { width } = useWindowDimensions();

    const shopCardImage = () => {
        if (props.data) {
            if (props.data.tier === "WELCOME") return welcomeCard;
            else if (props.data.tier === "BLACK") return blackCard;
            else if (props.data.tier === "ULTIMATE") return ultimateCard;
            return null;
        }
    };

    return (
        <React.Fragment>
            {props.data && (
                <div className="relative">
                    <img className="w-full mt-[-20px]" src={shopCardImage()} alt="shopcard" />
                    <span
                        style={{
                            fontSize: (20 * width) / 375,
                            bottom: (10 * width) / 375,
                            left: (22 * width) / 375,
                        }}
                        className="text-white absolute"
                    >
                        <p className="text-[12px] text-medium text-blueGray2 leading-[11px]">
                            {/* ชื่อร้านค้า: */}
                        </p>
                        <p style={{
                            // textShadow: '2px 2px 2px #808080'
                        }}>{`${props.data.shopName}`}</p>
                    </span>

                    <span
                        style={{
                            fontSize: (20 * width) / 375,
                            bottom: (10 * width) / 375,
                            right: (90 * width) / 375,
                        }}
                        className="text-white absolute"
                    >
                        <p className="text-[12px] text-medium text-blueGray2 leading-[11px]">
                            {/* เป็นสมาชิกเมื่อ: */}
                        </p>
                        <p style={{
                            // textShadow: '2px 2px 2px #808080'
                        }}>{getTime.getTimeString(props.data.createdDate)}</p>
                    </span>
                </div>
            )}
        </React.Fragment>
    )
}
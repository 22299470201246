import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import calendar from "../../assets/images/icon/calendar.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SecondaryButton from "../../components/button/SecondaryButton";
import PrimaryButton from "../../components/button/PrimaryButton";
import TopBanner from "../../components/layout/TopBanner";
// import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import CustomPopup from "../../components/popup/CustomPopup";
import { useParams } from "react-router-dom";
import {
  postDeletePet,
  postUpdatePet,
  SearchDogBreed,
  SearchFavoriteProduct,
  SearchPetDetail,
} from "../../services/Dogbook";
import { SearchId } from "../../services/SearchId";
// import { tempLineId1 } from "../../services/Api";
import dogFace from "../../assets/images/icon/dogFace.svg";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { useLiff } from "react-liff";
import TopLogo from "../../components/layout/TopLogo";
import helper from "../../functions/helper";
const { getUTC, postUTC } = helper;

const initialInputValues = {
  urlPicture: null,
  petName: "",
  dogBreedId: "",
  birthday: null,
  weight: "",
  favoriteProductId: "",
};

const initialErrorValues = {
  urlPicture: false,
  petName: false,
  dogBreedId: false,
  birthday: false,
  weight: false,
  favoriteProductId: false,
};

export default function EditDog() {
  const navigate = useNavigate();
  let { petId } = useParams();

  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
  const [openCancelPopup, setOpenCancelPopup] = React.useState(false);
  const [openEditedPopup, setOpenEditedPopup] = React.useState(false);

  const [pet, setPet] = useState(null);
  const [dogBreedData, setDogBreedData] = useState(null);
  const [userInput, setUserInput] = useState(initialInputValues);
  const [favProductData, setFavProductData] = useState(null);
  const [errorInput, setErrorInput] = useState(initialErrorValues);

  useEffect(() => {
    getSearchId();
    getDogBreed();
    getFavProduct();
    //
  }, []);

  async function getSearchId() {
    return await SearchId({
      params: { lineId: lineId || null },
    })
      .then(async (response) => {
        if (!response.data.body.memberId) {
          window.location.replace("/register");
        } else getPet(response.data.body.memberId);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getDogBreed(keyword) {
    return await SearchDogBreed({
      params: { lineId: lineId || null },
      keyword: keyword,
    })
      .then(async (response) => {
        setDogBreedData(response.data.body);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getPet(member_id) {
    return await SearchPetDetail({
      params: { lineId: lineId || null },
      member_id: member_id,
    })
      .then(async (response) => {
        if (response.data.resultCode === 20000) {
          let petRes = response.data.body;
          let selectedPet = petRes
            .map((object) => (object.petId === petId ? object : null))
            .find((object) => object !== null);

          if (selectedPet === undefined) {
            window.open("/dogbook", "_self");
          } else {
            if (selectedPet.birthday) {
              selectedPet.birthday = getUTC(selectedPet.birthday);
            }
          }
          console.log("selectedPet >>", selectedPet);
          setPet(selectedPet);

          if (selectedPet) {
            let updatedInput = {
              ...userInput,
              urlPicture: selectedPet.urlPicture || null,
              petName: selectedPet.petName || null,
              dogBreedId: selectedPet.dogBreed.dogBreedId || null,
              birthday: selectedPet.birthday || null,
              weight: selectedPet.weight || null,
              favoriteProductId:
                selectedPet.favoriteProduct.favoriteProductId || null,
            };
            setUserInput(updatedInput);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getFavProduct() {
    return await SearchFavoriteProduct({
      // params: { lineId: tempLineId1 },
    })
      .then(async (response) => {
        setFavProductData(response.data.body);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleImage = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      let updatedInput = {
        ...userInput,
        urlPicture: reader.result.substring(reader.result.indexOf(",") + 1),
      };
      setUserInput(updatedInput);
      // console.log(updatedInput);
    };
    reader.readAsDataURL(file);
  };

  const yesterday = new Date();

  function fnRenderOption(props, option, { inputValue }) {
    const breed1 = `${option.name}`;

    const matches1 = match(breed1, inputValue, { insideWords: true });
    const parts1 = parse(breed1, matches1);

    return (
      <li {...props} className="text-left pl-1 border-address-list">
        <div>
          {parts1.map((part, index) => (
            <span
              key={index}
              style={{
                fontWeight: part.highlight ? 700 : 400,
                color: part.highlight ? "#0A4197" : "#8F8F8F",
              }}
            >
              {part.text}
            </span>
          ))}
        </div>
      </li>
    );
  }

  async function postUpdatePetDetail() {
    let _urlPicture = (() => {
      if (
        userInput.urlPicture &&
        userInput.urlPicture.startsWith("data:image/png;base64")
      ) {
        return userInput.urlPicture.substring(22);
      } else {
        return userInput.urlPicture;
      }
    })();

    if (_urlPicture) {
      _urlPicture = await helper.toImageSmall(_urlPicture);
    }

    const payload = {
      petId: petId,
      urlPicture: _urlPicture,
      petName: userInput.petName,
      dogBreedId: userInput.dogBreedId,
      birthday: postUTC(userInput.birthday),
      weight: userInput.weight,
      favoriteProductId: userInput.favoriteProductId,
    };
    if (handleValidate()) {
      try {
        const { data } = await postUpdatePet({ data: payload });
        console.log("resData.data >>", data);
        if (data.resultCode === 20000) {
          // console.log(payload);
          setOpenEditedPopup(true);
        }
      } catch (error) {
        console.log("postUpdatePet error", error);
      }
    }
  }

  function handleValidate() {
    let updatedInput = {
      ...errorInput,
      urlPicture: false,
      petName: userInput.petName ? false : true,
      dogBreedId: userInput.dogBreedId ? false : true,
      birthday: userInput.birthday ? false : true,
      weight: userInput.weight ? false : true,
      favoriteProductId: userInput.favoriteProductId ? false : true,
    };

    // console.log(userInput);

    let check = false;
    const entries = Object.entries(updatedInput);
    //
    for (const [key, value] of entries) {
      if (value === true) {
        check = true;
        break;
      }
    }
    if (!check) {
      return true;
    }

    setErrorInput(updatedInput);
    return false;
  }

  async function postDeleteSelectedPet() {
    const payload = {
      petId: petId,
    };

    try {
      const { data } = await postDeletePet({ data: payload });
      console.log("resData.data >>", data);
      if (data.resultCode === 20000) {
        // console.log(payload);
        navigate(-1);
      }
    } catch (error) {
      console.log("postDeletePet error", error);
    }
  }

  return (
    <div id="editdog-page">
      <CustomPopup
        title={"บันทึกการแก้ไขเรียบร้อย"}
        desc={"ข้อมูลของน้องหมาที่คุณแก้ไขได้รับการบันทึกแล้ว"}
        cancelText={"ปิด"}
        cancelFunction={() => {
          window.open("/dogbook", "_self");
          setOpenEditedPopup(false);
        }}
        open={openEditedPopup}
        onClose={() => {
          window.open("/dogbook", "_self");
          setOpenEditedPopup(false);
        }}
      />

      <CustomPopup
        title={"ยกเลิกการแก้ไขข้อมูลน้องหมา"}
        desc={
          "ข้อมูลของน้องหมาที่คุณเปลี่ยนแปลงจะยังไม่ถูกบันทึก หากมั่นใจแล้วกรุณากด ยืนยัน"
        }
        cancelFunction={() => {
          setOpenCancelPopup(false);
        }}
        actionFunction={() => {
          window.open("/dogbook", "_self");
          setOpenCancelPopup(false);
        }}
        open={openCancelPopup}
        onClose={() => {
          setOpenCancelPopup(false);
        }}
      />

      <CustomPopup
        title={"ลบข้อมูลน้องหมา"}
        desc={
          "คุณต้องการลบข้อมูลน้องหมาตัวนี้หรือไม่? หากมั่นใจแล้ว กรุณากด ยืนยัน เพื่อดำเนินการต่อ"
        }
        cancelFunction={() => {
          setOpenDeletePopup(false);
        }}
        actionFunction={() => {
          postDeleteSelectedPet();
          setOpenDeletePopup(false);
        }}
        open={openDeletePopup}
        onClose={() => {
          setOpenDeletePopup(false);
        }}
      />
      <TopLogo />
      <TopBanner type="dogbook" />
      <div id="add-dog" className="relative mt-[-20px] px-[20px]">
        <div className="bg-white py-[24px] rounded-[4px] mb-[36px]">
          <p className="text-lightBlue text-[28px] text-left px-[17px]">
            แก้ไขข้อมูลน้องหมา
          </p>
          {pet && userInput.urlPicture !== null ? (
            <div className="relative flex flex-col justify-center items-center h-[118px] mb-[17px]">
              {userInput.urlPicture ? (
                <img
                  className="rounded-full object-cover h-[118px] w-[118px]"
                  src={
                    userInput.urlPicture.startsWith("data:image/png;base64")
                      ? userInput.urlPicture
                      : `data:image/png;base64,${userInput.urlPicture}`
                  }
                  alt=""
                />
              ) : (
                <img
                  className="rounded-full object-cover h-[118px] w-[118px]"
                  src={`data:image/png;base64,${pet.urlPicture}`}
                  alt=""
                />
              )}
              <p className="absolute bottom-[15px] text-white text-[16px]">
                เปลี่ยนรูปภาพ
              </p>
              <input
                className="opacity-0 absolute left-0 top-0 bottom-0 right-0"
                type="file"
                onChange={(event) => handleImage(event)}
              />
            </div>
          ) : (
            <div className="flex justify-center items-center">
              <div className="relative flex flex-col justify-center items-center gap-[5px] rounded-full h-[118px] w-[118px] bg-blueGray">
                <img className="w-[60px]" src={dogFace} alt="" />
                <p className="text-blue text-[16px]">อัพโหลดรูปภาพ</p>
                <input
                  className="opacity-0 absolute left-0 top-0 bottom-0 right-0"
                  type="file"
                  onChange={(event) => handleImage(event)}
                />
              </div>
            </div>
          )}

          {pet && (
            <div className="px-[20px]">
              <div className="flex flex-col gap-[30px]">
                <TextField
                  className="w-full text-[20px]"
                  label="ชื่อน้องหมา"
                  required
                  error={errorInput.petName}
                  helperText="ชื่อน้องหมาไม่ถูกต้อง"
                  defaultValue={pet.petName}
                  variant="standard"
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      let updatedInput = {
                        ...userInput,
                        petName: event.target.value || null,
                      };
                      // console.log(event.target.value);
                      setUserInput(updatedInput);
                    }
                  }}
                />

                {dogBreedData && (
                  <Autocomplete
                    disablePortal
                    freeSolo
                    defaultValue={pet.dogBreed}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        let updatedInput = {
                          ...userInput,
                          dogBreedId: newValue.id || 0,
                        };
                        setUserInput(updatedInput);
                      } else {
                        let updatedInput = {
                          ...userInput,
                          dogBreedId: null,
                        };
                        setUserInput(updatedInput);
                      }
                    }}
                    options={dogBreedData}
                    getOptionLabel={(item) => item.name || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={(e) => {
                          getDogBreed(e.target.value);
                        }}
                        className="w-full text-[20px]"
                        label="สายพันธุ์"
                        error={errorInput.dogBreedId}
                        helperText="สายพันธุ์ไม่ถูกต้อง"
                        required
                        variant="standard"
                      />
                    )}
                    renderOption={fnRenderOption}
                  />
                )}
                <div className="relative">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      className="w-full"
                      label="วัน เดือน ปี เกิดน้องหมา"
                      variant="standard"
                      inputFormat="MM/DD/YYYY"
                      maxDate={yesterday.setDate(yesterday.getDate() - 1)}
                      value={userInput.birthday}
                      // defaultValue={pet.birthday}
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          let updatedInput = {
                            ...userInput,
                            birthday: event.toISOString() || null,
                          };
                          setUserInput(updatedInput);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          error={errorInput.birthday}
                          helperText="วันเกิดไม่ถูกต้อง"
                          // error={errorInputs[props.index].birthday}
                          // helperText="วันเกิดไม่ถูกต้อง"
                        />
                      )}
                    />
                    <img
                      className={
                        `absolute right-[10px] bottom-[15px] w-[15px] pointer-events-none ` +
                        (false ? "bottom-[35px]" : null)
                      }
                      src={calendar}
                      alt=""
                    />
                  </LocalizationProvider>
                </div>

                <TextField
                  className="w-full text-[20px]"
                  label="น้ำหนัก"
                  required
                  error={errorInput.weight}
                  helperText="น้ำหนักไม่ถูกต้อง"
                  defaultValue={parseInt(pet.weight).toFixed(0)}
                  variant="standard"
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      let updatedInput = {
                        ...userInput,
                        weight: event.target.value || null,
                      };
                      setUserInput(updatedInput);
                    }
                  }}
                />

                <div>
                  <FormControl
                    fullWidth
                    // error={errorInputs[props.index].favoriteProductId}
                  >
                    <InputLabel style={{ marginLeft: "-14px" }}>
                      ชื่อผลิตภัณฑ์ป้องกันปรสิตที่ใช้ประจำ
                    </InputLabel>
                    <Select
                      // displayEmpty
                      IconComponent={KeyboardArrowDownIcon}
                      fullWidth
                      defaultValue={parseInt(
                        pet.favoriteProduct.favoriteProductId
                      )}
                      variant="standard"
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          let updatedInput = {
                            ...userInput,
                            favoriteProductId: event.target.value || null,
                          };
                          setUserInput(updatedInput);
                        }
                      }}
                    >
                      {favProductData &&
                        favProductData.map((item, i) => (
                          <MenuItem value={item.id} key={i}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                    {/* <FormHelperText>ชื่อผลิตภัณฑ์ไม่ถูกต้อง</FormHelperText> */}
                  </FormControl>
                </div>
              </div>

              <button
                onClick={() => {
                  setOpenDeletePopup(true);
                }}
                className="flex gap-[5px] items-center justify-center w-fit py-[2px] px-[24px] rounded-[28px] border-[1px] border-red mt-[26px]"
              >
                <FontAwesomeIcon icon={faXmark} className="w-[11px] text-red" />
                <p className="text-red text-[20px]">ลบข้อมูลน้องหมา</p>
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="flex itesm-center gap-[13px] justify-center bg-white px-[20px] pt-[16px] pb-[24px]">
        <SecondaryButton
          variant="outlined"
          className="w-full"
          onClick={() => setOpenCancelPopup(true)}
        >
          ยกเลิก
        </SecondaryButton>
        <PrimaryButton
          onClick={() => postUpdatePetDetail()}
          variant="outlined"
          className="w-full"
        >
          บันทึก
        </PrimaryButton>
      </div>
    </div>
  );
}

import React from "react";

// import { useNavigate } from "react-router-dom";

import LogoDog from "../../assets/images/logo-dog-register.png";
import LogoNex from "../../assets/images/logo-nex-register.png";
import DogStarHeart from "../../assets/images/logo-dog-mini-heart.png";
import LogoSpectra from "../../assets/images/logo-spectra-register.png";
import PrimaryButton from "../../components/button/PrimaryButton";
import PopupRegisterSuccess from "../../components/popup/PopupRegisterSuccess";
import { useLocation, useNavigate } from "react-router-dom";
import SecondaryButton from "../../components/button/SecondaryButton";
import { useSelector } from "react-redux";
import TopLogo from "../../components/layout/TopLogo";

export default function RegisterSuccess() {
  const store = useSelector((state) => state.registerPetShop);

  const { state } = useLocation();
  const [openCancelPopup, setOpenCancelPopup] = React.useState(true);
  const navigate = useNavigate();
  return (
    <>
      <PopupRegisterSuccess
        point={state.point}
        actionFunction={() => {
          setOpenCancelPopup(false);
        }}
        open={openCancelPopup}
        onClose={() => {
          setOpenCancelPopup(false);
        }}
      />
      <TopLogo />
      <div
        style={{
          background:
            "linear-gradient(152.04deg, rgb(10, 65, 151) -0.97%, rgb(32, 159, 237) 81.57%)",
          minHeight: "calc(100vh - 60px)",
          padding: "20px",
        }}
      >
        <div className="text-white text-center text-[30px] mt-[50px]">
          สมัครสมาชิกสำเร็จ
        </div>
        <div className="text-white text-center text-[24px]">
          ขอบคุณที่มาเป็นส่วนหนึ่งในครอบครัว
        </div>
        <img
          className="mx-auto my-[20px]"
          src={DogStarHeart}
          alt="logo"
          style={{ width: "50%" }}
        />
        <img
          className="mx-auto"
          src={LogoNex}
          alt="logo"
          style={{ width: "50%" }}
        />
        <div className="text-white text-center text-[24px]">
          สิทธิ์พิเศษมากมายจาก{" "}
          <img
            src={LogoSpectra}
            alt="logo"
            style={{ width: "70px", display: "inline-block" }}
          />{" "}
          รอคุณอยู่
        </div>
        <div className="text-center mt-[50px]">
          {/*btn member*/}
          {!store["isPetShop"] && (
            <div className="my-4">
              <PrimaryButton
                variant="contained"
                className="w-[70%] !bg-[#082e683d]"
                onClick={() => {
                  navigate("/dogbook");
                }}
                sx={{
                  border: "1px solid white",
                }}
              >
                กลับไปที่สมุดพกน้องหมา
              </PrimaryButton>
            </div>
          )}
          {/*btn pet shop*/}
          {store["isPetShop"] && (
            <div className="my-4">
              <PrimaryButton
                variant="contained"
                className="w-[70%] !bg-[#082e683d]"
                onClick={() => {}}
                sx={{
                  border: "1px solid white",
                }}
              >
                ไปหน้าเช็คคะแนน
              </PrimaryButton>
            </div>
          )}
          {store["isPetShop"] && store["isOwner"] === "yes" && (
            <div className="my-4">
              <PrimaryButton
                variant="contained"
                className="w-[70%] !bg-[#082e683d]"
                onClick={() => {
                  navigate("/redeem-gift");
                }}
              >
                แลกของรางวัล
              </PrimaryButton>
            </div>
          )}
          {store["isPetShop"] && (
            <SecondaryButton
              variant="contained"
              className="w-[70%] bg-white"
              onClick={() => {}}
            >
              สร้าง QR Code
            </SecondaryButton>
          )}
        </div>
      </div>
    </>
  );
}

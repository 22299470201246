import dayjs from "dayjs";
import CONFIG from "../config.json";
import CONFIGLIFF from "../configLiffNew.json";

import sign from "jwt-encode";
import jwt_decode from "jwt-decode";
import _ from "lodash";

const helper = {
  GETCONFIG: {
    OPTION: CONFIG.OPTION,
  },
  encodeJWT: (data = {}, secret = "secret") => {
    const encode = sign(data, secret);
    return encode;
  },
  decodeJWT: (token = "") => {
    const decode = jwt_decode(token);
    return decode;
  },
  toActivty: (activitys = []) => {
    const { activityOption } = CONFIG.OPTION;
    const Ans = activityOption.filter((item) => activitys.includes(item.id));
    return Ans;
  },
  getLiffIdByENV: (path, env = "dev") => {
    if (process.env.REACT_APP_ENV) {
      env = process.env.REACT_APP_ENV;
    }

    if (CONFIGLIFF[env][path]) {
      console.log("path >>", path);
      return CONFIGLIFF[env][path];
    } else {
      for (const p in CONFIGLIFF[env]) {
        if (`${path}`.startsWith(p)) {
          return CONFIGLIFF[env][p];
        }
      }
    }

    if (CONFIGLIFF[env]["default"]) {
      return CONFIGLIFF[env]["default"];
    }

    return "1656812094-8P7zvOdq";
  },
  getUTC: (time = "") => {
    return time;
  },
  postUTC: (time = "") => {
    const _time = dayjs(time).format("YYYY-MM-DD");
    return dayjs(_time).add(-1, "days").format("YYYY-MM-DD") + "T17:00:00.000Z";
  },
  numberWithCommas: (num = "") => {
    return `${num}`.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  numberWithZero: (num = "", pad = 4) => {
    var str = "" + num;
    var padStr = "";
    for (let i = 0; i < pad; i++) {
      padStr += "0";
    }
    return padStr.substring(0, padStr.length - str.length) + str;
  },
  isExternalRoute: () => {
    const { pathname } = window.location;
    return `${pathname}`.startsWith("/iframe");
  },
  rDatetoValue: (dateArr = []) => {
    const Rdate = (rDate = {}) => {
      const nz = helper.numberWithZero;
      return `${rDate.year}-${nz(rDate.month, 2)}-${nz(rDate.day, 2)}`;
    };
    const [start, end] = dateArr;
    if (start && end) {
      return { start: Rdate(start), end: Rdate(end) };
    }
    return { start: null, end: null };
  },
  capitalizeFirstLetter: (str = "") => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  },
  mobileCheck: () => {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  },
  async toImageSmall(fileBase64) {
    async function reduce_image_file_size(
      base64Str,
      MAX_WIDTH = 450,
      MAX_HEIGHT = 450
    ) {
      console.log("base64Str >>", base64Str);
      console.log("DDD");
      let resized_base64 = await new Promise((resolve) => {
        let img = new Image();
        img.src = base64Str;
        img.onload = () => {
          let canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          resolve(canvas.toDataURL()); // this will return base64 image results after resize
        };
      });
      return resized_base64;
    }

    async function image_to_base64(file) {
      console.log("CCC");
      let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => resolve(fileReader.result);
        fileReader.onerror = (error) => {
          console.log(error);
          alert("An Error occurred please try again, File might be corrupt");
        };
        fileReader.readAsDataURL(file);
      });
      return result_base64;
    }

    async function process_image(file, min_image_size = 300) {
      console.log("BBB");
      // const res = await image_to_base64(file);
      const res = file;
      if (res) {
        const old_size = calc_image_size(res);
        if (old_size > min_image_size) {
          const resized = await reduce_image_file_size(res);
          const new_size = calc_image_size(resized);
          console.log("new_size=> ", new_size, "KB");
          console.log("old_size=> ", old_size, "KB");
          return resized;
        } else {
          console.log("image already small enough");
          return res;
        }
      } else {
        console.log("return err");
        return null;
      }
    }

    /*- NOTE: USE THIS JUST TO GET PROCESSED RESULTS -*/
    // async function preview_image() {
    //     console.log('00')
    //     const file = document.getElementById('file');
    //     const image = await process_image(file.files[0]);
    //     console.log('image >>', image)
    // }

    function calc_image_size(image) {
      console.log("AAA");
      let y = 1;
      if (image.endsWith("==")) {
        y = 2;
      }
      const x_size = image.length * (3 / 4) - y;
      return Math.round(x_size / 1024);
    }

    return new Promise(async (resolve) => {
      // const file = document.getElementById('file');
      // const image = await process_image(file.files[0]);
      const tmpBase64 = `data:image/png;base64,${fileBase64}`;
      let imageResize = await process_image(tmpBase64);
      imageResize = `${imageResize}`.replace("data:image/png;base64,", "");
      resolve(imageResize);
    });
  },
  genRange: (startNumber, endNumber) => {
    // Initialize an empty array to store the result
    let numberArray = [];
    for (let i = startNumber; i <= endNumber; i++) {
      numberArray.push(i);
    }
    return numberArray;
  },
  secondsToHMS: (totalSeconds) => {
    // Calculate hours
    const hours = Math.floor(totalSeconds / 3600);
    // Calculate remaining seconds after removing hours
    const remainingSecondsAfterHours = totalSeconds % 3600;
    // Calculate minutes
    const minutes = Math.floor(remainingSecondsAfterHours / 60);
    // Calculate remaining seconds after removing minutes
    const seconds = remainingSecondsAfterHours % 60;
    if (hours && minutes && seconds) {
      return `${hours}h ${minutes}m ${seconds}s`;
    } else if (minutes && seconds) {
      return `${minutes}m ${seconds}s`;
    }
    return `${seconds}s`;
  },
  toNumberWithCommas: (x = "") => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return "0";
  },
  FNFORM: {
    handleNumberOnly: (e) => {
      const numericValue = e.target.value.replace(/[^0-9]/g, "");
      e.target.value = numericValue;
    },
    handleCheckBox: (mainId, listId, state) => {
      let _state = _.cloneDeep(state);
      if (_state[mainId]) {
        if (_state[mainId].includes(listId)) {
          _state[mainId] = _state[mainId].filter((item) => item !== listId);
          if (_state[mainId].length === 0) {
            delete _state[mainId];
          }
        } else {
          _state[mainId].push(listId);
        }
      } else {
        _state[mainId] = [listId];
      }
      return _state;
    },
    handleCharOnly: (e) => {
      // const charValue = e.target.value.replace(/[^@_.0-9a-zA-Zก-์]/g, "");
      const charValue = e.target.value.replace(/[^\s@_.a-zA-Zก-์]/g, "");
      e.target.value = charValue;
    },
    handleCharEngOnly: (e) => {
      const charValue = e.target.value.replace(/[^\s@_.a-zA-Z0-9]/g, "");
      e.target.value = charValue;
    },
    handleEngCharOnly: (e) => {
      // const charValue = e.target.value.replace(/[^@_.0-9a-zA-Zก-์]/g, "");
      const charValue = e.target.value.replace(/[a-zA-Z\s]/g, "");
      e.target.value = charValue;
    },
    isUpperCase: (e) => {
      const charEng = e.target.value.replace(/[^\s@_.a-zA-Z0-9]/g, "");
      const charValue = charEng.toUpperCase();
      e.target.value = charValue;
    },

    idCardCheck: (idCardNumber) => {
      let sum = 0;
      for (let i = 0; i < idCardNumber.length - 1; i++) {
        sum += parseInt(idCardNumber[i]) * (13 - i);
      }

      let checkDigit = (11 - (sum % 11)) % 10;

      if (checkDigit == idCardNumber[12]) {
        return true;
      } else {
        return false;
      }
    },
  },
};

export default helper;

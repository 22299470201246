import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  styled,
} from "@mui/material";
import Close from "../../assets/images/icon/close-gray.svg";
import DeliveryUnselected from "../../assets/images/redeem-gift/delivery-unselected.png";
import DeliverySelected from "../../assets/images/redeem-gift/delivery-selected.png";
import SelfUnselected from "../../assets/images/redeem-gift/self-unselected.png";
import SelfSelected from "../../assets/images/redeem-gift/self-selected.png";
import React, { useState, useEffect } from "react";
import PrimaryButton from "../button/PrimaryButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const CustomDialog = styled(Dialog)({
  "& .MuiTypography-root": {
    fontFamily: "inherit",
  },
  "& .MuiDialog-container": {
    marginLeft: 25,
    marginRight: 25,
  },
  "& .MuiDialogTitle-root": {
    fontFamily: "inherit",
    paddingRight: 15,
    paddingLeft: 0,
    paddingBottom: 0,
  },
  "& .MuiPaper-root": {
    fontFamily: "inherit",
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    maxHeight: "100%",
    borderRadius: 5,
  },
  "& .MuiDialogContent-root": {
    fontFamily: "inherit",
    marginLeft: 1,
    marginRight: 1,
    padding: 0,
    "& .MuiTypography-root": {
      color: "black",
      // fontSize: 'calc(100vh * 0.0159)'
    },
  },
  "& .MuiDialogActions-root": {
    display: "block",
    padding: "20px 5%",
  },
});
export default function SelectChannelDialog({
  title,
  open,
  toggle,
  onOk,
  clinicList = [],
  onAction,
}) {
  const [channel, setChannel] = useState(0);
  const [shopId, setShopId] = useState(null);

  const channelConfrim = onAction == 0 ? channel : onAction;

  const BTNDisable =
    channelConfrim == 0 || (channelConfrim == 2 && shopId == null)
      ? true
      : false;

  console.log("onAction >>>", onAction);
  console.log("channel >>>", channel);

  console.log("channelConfrim >>>>>>>>>", channelConfrim);

  useEffect(() => {}, []);

  function onClickChannel(no) {
    setChannel(no);
  }

  const handleClinicSelection = (val) => {
    if (val) {
      setShopId(val);
    } else {
      return;
    }
  };

  return (
    <>
      <CustomDialog
        fullScreen={false}
        open={open}
        onClose={toggle(false)}
        maxWidth="xl"
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={"text-center"}
      >
        <DialogTitle id="scroll-dialog-title">
          <div className="flex flex-row-reverse">
            <img
              src={Close}
              alt="close"
              onClick={toggle(false)}
              className="h-6"
            />
          </div>
          <div className="text-[28px] text-[#209FED] pl-4 mt-[-10px]">
            {title}
          </div>
        </DialogTitle>
        {onAction == 1 ? (
          <DialogContent dividers={false}>
            <div className={"px-[20px]"}>
              <div
                className={
                  "grid grid-cols-1 min-w-[300px] gap-5 text-[20px] font-[600]"
                }
              >
                <img
                  src={onAction === 1 ? DeliverySelected : DeliveryUnselected}
                  alt={"Delivery"}
                  className={"mx-auto pb-2"}
                />
                <div className={channel === 1 ? "text-blue" : "text-gray"}>
                  จัดส่งของรางวัล
                </div>
              </div>
              {onAction === 1 && (
                <div className={"text-red text-[16px] leading-5 pt-5"}>
                  กรุณาตรวจสอบ ที่อยู่จัดส่ง ให้ถูกต้อง
                  <br />
                  ในเมนู สมุดพกน้องหมาและข้อมูลผู้ใช้ ก่อนกดยืนยัน
                </div>
              )}
            </div>
          </DialogContent>
        ) : onAction == 2 ? (
          <DialogContent dividers={false}>
            <div className={"px-[20px]"}>
              <div
                className={
                  "grid grid-cols-1 min-w-[300px] gap-5 text-[20px] font-[600]"
                }
              >
                <img
                  src={onAction === 2 ? SelfSelected : SelfUnselected}
                  alt={"Self"}
                  className={"mx-auto pb-2"}
                />
                <div className={channel === 2 ? "text-blue" : "text-gray"}>
                  รับด้วยตนเอง
                </div>
              </div>

              {onAction === 2 && (
                <>
                  <div className={"text-left text-[20px] text-gray py-5"}>
                    รับได้ที่โรงพยาบาลสัตว์ คลินิก หรือร้านเพ็ทช็อป
                    <br />
                    ที่เคยใช้บริการเท่านั้น :
                  </div>
                  <div>
                    <Select
                      displayEmpty
                      IconComponent={KeyboardArrowDownIcon}
                      fullWidth
                      name="shopId"
                      value={shopId || ""}
                      onChange={(event) =>
                        handleClinicSelection(event.target.value)
                      }
                      variant="standard"
                    >
                      <MenuItem disabled value="">
                        <span className={"text-gray"}>
                          เลือกโรงพยาบาล คลินิก หรือร้านเพ็ทช็อป
                        </span>
                      </MenuItem>
                      {clinicList.map((item, i) => (
                        <MenuItem value={item.id} key={i}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className={"text-red text-[16px] leading-5 pt-5"}>
                    กรุณากดยืนยันเมื่ออยู่ที่หน้าร้านค้าแล้วเท่านั้น
                    <br />
                    หากกด 'ยืนยัน' ไม่สามารถแก้ไขเปลี่ยนแปลงข้อมูลได้
                  </div>
                </>
              )}
            </div>
          </DialogContent>
        ) : (
          <DialogContent dividers={false}>
            <div className={"px-[20px]"}>
              <div className={"grid grid-cols-2 gap-5 text-[20px] font-[600]"}>
                <div onClick={() => onClickChannel(1)}>
                  <img
                    src={channel === 1 ? DeliverySelected : DeliveryUnselected}
                    alt={"Delivery"}
                    className={"mx-auto pb-2"}
                  />
                  <div className={channel === 1 ? "text-blue" : "text-gray"}>
                    จัดส่งของรางวัล
                  </div>
                </div>
                <div onClick={() => onClickChannel(2)}>
                  <img
                    src={channel === 2 ? SelfSelected : SelfUnselected}
                    alt={"Self"}
                    className={"mx-auto pb-2"}
                  />
                  <div className={channel === 2 ? "text-blue" : "text-gray"}>
                    รับด้วยตนเอง
                  </div>
                </div>
              </div>
              {channel === 1 && (
                <div className={"text-red text-[16px] leading-5 pt-5"}>
                  กรุณาตรวจสอบ ที่อยู่จัดส่ง ให้ถูกต้อง
                  <br />
                  ในเมนู สมุดพกน้องหมาและข้อมูลผู้ใช้ ก่อนกดยืนยัน
                </div>
              )}
              {channel === 2 && (
                <>
                  <div className={"text-left text-[20px] text-gray py-5"}>
                    รับได้ที่โรงพยาบาลสัตว์ คลินิก หรือร้านเพ็ทช็อป
                    <br />
                    ที่เคยใช้บริการเท่านั้น :
                  </div>
                  <div>
                    <Select
                      displayEmpty
                      IconComponent={KeyboardArrowDownIcon}
                      fullWidth
                      name="shopId"
                      value={shopId || ""}
                      onChange={(event) =>
                        handleClinicSelection(event.target.value)
                      }
                      variant="standard"
                    >
                      <MenuItem disabled value="">
                        <span className={"text-gray"}>
                          เลือกโรงพยาบาล คลินิก หรือร้านเพ็ทช็อป
                        </span>
                      </MenuItem>
                      {clinicList.map((item, i) => (
                        <MenuItem value={item.id} key={i}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className={"text-red text-[16px] leading-5 pt-5"}>
                    กรุณากดยืนยันเมื่ออยู่ที่หน้าร้านค้าแล้วเท่านั้น
                    <br />
                    หากกด 'ยืนยัน' ไม่สามารถแก้ไขเปลี่ยนแปลงข้อมูลได้
                  </div>
                </>
              )}
            </div>
          </DialogContent>
        )}
        <DialogActions>
          <div className="text-center">
            <PrimaryButton
              variant="contained"
              onClick={() => onOk({ channelConfrim, shopId })}
              disabled={BTNDisable}
            >
              <div className={"px-5"}>ยืนยัน</div>
            </PrimaryButton>
          </div>
        </DialogActions>
      </CustomDialog>
    </>
  );
}

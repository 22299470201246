import React from "react";

export default function DogPaw(props) {
  return (
    <svg
      width={props.width}
      height={props.width}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1051 9.13566C11.9517 7.78586 10.4938 7.04248 8.99995 7.04248C7.50606 7.04248 6.04817 7.78586 4.89486 9.13566C3.85817 10.3489 3.23926 11.878 3.23926 13.2259C3.23926 13.8807 3.44577 14.3794 3.85304 14.7084C4.25502 15.033 4.78574 15.1428 5.37783 15.1428C6.00786 15.1428 6.70742 15.0185 7.39477 14.8962C7.9954 14.7894 8.56271 14.6886 8.99998 14.6886C9.37738 14.6886 9.90951 14.7843 10.4728 14.8857C11.7892 15.1226 13.2812 15.391 14.1432 14.6964C14.5529 14.3662 14.7607 13.8714 14.7607 13.2259C14.7607 11.878 14.1418 10.3489 13.1051 9.13566Z"
        fill={props.color}
      />
      <path
        d="M13.2231 1.80611C12.8011 1.20303 12.1969 0.857178 11.5652 0.857178C10.9336 0.857178 10.3293 1.20307 9.90732 1.80611C9.51967 2.36013 9.30615 3.08766 9.30615 3.85473C9.30615 4.6218 9.51963 5.34934 9.90732 5.90335C10.3293 6.5064 10.9336 6.85229 11.5652 6.85229C12.1969 6.85229 12.8011 6.5064 13.2231 5.90335C13.6108 5.34934 13.8243 4.6218 13.8243 3.85473C13.8243 3.08766 13.6108 2.36009 13.2231 1.80611Z"
        fill={props.color}
      />
      <path
        d="M8.09277 1.80605C7.67077 1.20297 7.06652 0.857117 6.43486 0.857117C5.80319 0.857117 5.19891 1.203 4.77694 1.80605C4.38929 2.36007 4.17578 3.08761 4.17578 3.85467C4.17578 4.62174 4.38926 5.34928 4.77694 5.90329C5.19895 6.50638 5.80319 6.85226 6.43486 6.85226C7.06652 6.85226 7.67077 6.50638 8.09277 5.90329C8.48042 5.34928 8.69393 4.62177 8.69393 3.85467C8.6939 3.08761 8.48042 2.36003 8.09277 1.80605Z"
        fill={props.color}
      />
      <path
        d="M17.4718 6.51145C17.3016 5.95053 16.937 5.54294 16.4451 5.36383C16.0492 5.21971 15.6023 5.24415 15.1867 5.43267C14.614 5.69242 14.1459 6.22881 13.9025 6.90427C13.7036 7.45623 13.6788 8.03965 13.8327 8.54712C14.0028 9.10804 14.3675 9.51563 14.8594 9.69474C15.0297 9.75672 15.2093 9.78752 15.3919 9.78752C15.6338 9.78752 15.8809 9.73336 16.1178 9.6259C16.6905 9.36615 17.1586 8.82976 17.402 8.15434V8.1543C17.6009 7.60237 17.6257 7.01892 17.4718 6.51145Z"
        fill={props.color}
      />
      <path
        d="M4.09785 6.90422C3.8544 6.22877 3.38629 5.69241 2.81362 5.43265C2.39801 5.24413 1.9511 5.21973 1.55524 5.36382C1.06326 5.54296 0.698616 5.95054 0.528495 6.51144C0.37458 7.01891 0.39939 7.60236 0.598304 8.15429V8.15432C0.841783 8.82978 1.30986 9.36613 1.88253 9.62589C2.11945 9.73334 2.36651 9.78751 2.60848 9.78751C2.79102 9.78751 2.97068 9.75668 3.1409 9.69473C3.63295 9.51558 3.99757 9.108 4.16769 8.54711C4.32154 8.03967 4.29673 7.45622 4.09785 6.90422Z"
        fill={props.color}
      />
    </svg>
  );
}

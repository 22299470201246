import { createSlice } from "@reduxjs/toolkit";

const defaultData = {
  memberCode: "",
};

export const StampInviteFriendSlice = createSlice({
  name: "StampInviteFriend",
  initialState: defaultData,
  reducers: {
    setStampInviteFriend: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        console.log(key, value);
        state[key] = value;
      }
    },
    resetStampInviteFriend: () => defaultData,
  },
});

// Action creators are generated for each case reducer function
export const { setStampInviteFriend, resetStampInviteFriend } =
  StampInviteFriendSlice.actions;

export default StampInviteFriendSlice.reducer;

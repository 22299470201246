import React from "react";
import LogoHeader from "../assets/images/logo-header.png";
function Layouts({children}) {
  return (
    <>
      <div className="wrapper">
        <main>{children}</main>
      </div>
    </>
  );
}

export default Layouts;

import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

import TopLogo from "../../components/layout/TopLogo";
import scanGeneral from "../../assets/images/scan-general.png";
import eventQuiz from "../../assets/images/event-quiz.png";
import eventUpload from "../../assets/images/event-upload.png";
import scanQR from "../../assets/images/scanQR.png";
import expireIcon from "../../assets/images/expire-icon.png";
import scanQuiz from "../../assets/images/scan-quiz.png";
import collectPointApprove from "../../assets/images/history/collect_point_approve.png";
import pet_diary from "../../assets/images/history/pet_diary.png";
import game from "../../assets/images/history/game.png";
import invite from "../../assets/images/history/invite.png";
import quiz from "../../assets/images/history/quiz.png";
import mobile from "../../assets/images/mobile.png";
import { useSelector } from "react-redux";
import BackButton from "../../components/button/BackButton";
import helper from "../../functions/helper";
import {
  SearchQRPointHistories,
  SearchEventPointHistories,
} from "../../services/MyPoint";
import { SearchPointExpire } from "../../services/MyPoint";
import dayjs from "dayjs";
import { SearchId } from "../../services/SearchId";
import InfiniteScroll from "react-infinite-scroller";
import { useLiff } from "react-liff";
import { SearchPetOwnerProfile } from "../../services/Dogbook";
import CalendarTab from "../../components/general/CalendarTab";

const initialHistoryData = {
  scan: [
    // {
    //     "id": 1,
    //     "spCode": "SP1210D921234",
    //     "qrCode": "HW1210D921234",
    //     "clinicCode": "213453A564",
    //     "clinicName": "โรงพยาบาลเจ้าพระยา",
    //     "date": "12/12/2023",
    //     "time": "13:21:10 น.",
    //     "nexCoin": 250
    // },
    // {
    //     "id": 2,
    //     "spCode": "SP1210D921234",
    //     "qrCode": "HW1210D921234",
    //     "clinicCode": "213453A564",
    //     "clinicName": "โรงพยาบาลเจ้าพระยา",
    //     "date": "12/12/2023",
    //     "time": "13:21:10 น.",
    //     "nexCoin": 250
    // }
  ],
  event: [
    // {
    //     id: 1,
    //     createdDate: "21-01-2023",
    //     event: [
    //         { eventName: "Welcome Back" , points: 100 },
    //         { eventName: "Welcome Back" , points: 100 },
    //         { eventName: "Welcome Back" , points: 100 },
    //         { eventName: "Welcome Back" , points: 100 },
    //         { eventName: "Welcome Back" , points: 100 },
    //     ]
    // },
    // {
    //     id: 2,
    //     createdDate: "19-01-2023",
    //     event: [
    //         { eventName: "แต้มสำหรับการบันทึกสุขภาพน้องหมา" , points: 50 },
    //         { eventName: "แต้มสำหรับการบันทึกสุขภาพน้องหมา" , points: 50 },
    //     ]
    // }
  ],
};
const initialLoadMore = {
  scan: {
    limit: 3,
    offset: 0,
  },
  event: {
    limit: 3,
    offset: 0,
  },
};

const initialHistoryDataCount = {
  scan: 0,
  event: 0,
};

const initCurrentDate = dayjs().format("YYYY-MM-DD");

export default function MyPointHistory() {
  const store = useSelector((state) => state.myPoint);
  const navigate = useNavigate();
  const [page, setPage] = React.useState("scan");
  const [historyData, setHistoryData] = React.useState(initialHistoryData);
  const [loadMore, setLoadmore] = React.useState(initialLoadMore);
  const [historyDataCount, setHistoryDataCount] = React.useState(
    initialHistoryDataCount
  );

  // คะแนนหมดอายุ
  const [currentDate, setCurrentDate] = React.useState(initCurrentDate);
  const [historyExpire, setHistoryExpire] = React.useState([]);
  const [historyExpireCount, setHistoryExpireCount] = React.useState(0);
  const [expLoadMore, setExpLoadMore] = React.useState({ limit: 3, offset: 0 });

  let [ownerProfile, setOwnerProfile] = useState(null);
  let [currentPoint, setCurrentPoint] = useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isWaiting, setIsWaiting] = React.useState(true);
  const [memberId, setMemberId] = React.useState(null);
  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  useEffect(() => {
    getSearchId();
    //
  }, []);

  useEffect(() => {
    if (memberId) {
      if (page === "scan" && loadMore.scan.limit > 0) {
        // alert('B' + JSON.stringify({memberId, ...loadMore.scan}))
        getScanData(memberId, true);
      } else if (page === "event" && loadMore.event.limit > 0) {
        getEventData(memberId, true);
      }
    }
  }, [loadMore]);

  async function getSearchId() {
    return await SearchId({
      params: { lineId: lineId || null },
    })
      .then(async (response) => {
        if (!response.data.body.memberId) {
          navigate("/register");
        } else {
          setMemberId(response.data.body.memberId);
          getOwnerProfile(response.data.body.memberId);
          getExpireData(response.data.body.memberId, currentDate);
          // alert('A' + JSON.stringify({memberId, ...loadMore.scan}))
          const res1 = await getScanData(response.data.body.memberId);
          const res2 = await getEventData(response.data.body.memberId);
          let tmp = { ...historyData };
          tmp.scan = res1.tmp.scan;
          tmp.event = res2.tmp.event;

          let tmp2 = { ...historyDataCount };
          tmp2.scan = res1.tmp2.scan;
          tmp2.event = res2.tmp2.event;

          setHistoryData(tmp);
          setHistoryDataCount(tmp2);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getOwnerProfile(member_id) {
    return await SearchPetOwnerProfile({
      params: { lineId: lineId || null },
      member_id: member_id,
    })
      .then(async (response) => {
        setOwnerProfile(response.data.body);
        setCurrentPoint(response.data?.body?.currentPoint);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getScanData(_memberId, isRender = false) {
    const { offset, limit } = loadMore.scan;
    return new Promise((resolve, reject) => {
      SearchQRPointHistories({
        params: { memberId: _memberId ?? memberId, offset, limit },
      })
        .then(async (response) => {
          let tmp = null;
          let tmp2 = null;
          if (response.data.resultCode === 20000) {
            console.log(
              "response.data.body getScanData >>",
              response.data.body
            );
            let resData = response.data.body;
            resData = resData.map((item) => {
              item.isActive = false;
              return item;
            });
            tmp = { ...historyData };
            tmp.scan = [...tmp.scan, ...resData];

            tmp2 = { ...historyDataCount };
            tmp2.scan = response.data.rowCount;
          } else {
            tmp = { ...historyData };
            tmp.scan = [];
            tmp2 = { ...historyDataCount };
          }

          if (isRender) {
            setHistoryData(tmp);
            setHistoryDataCount(tmp2);
          } else {
            resolve({ tmp, tmp2 });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async function getEventData(_memberId, isRender = false) {
    const { offset, limit } = loadMore.event;
    return new Promise((resolve, reject) => {
      SearchEventPointHistories({
        params: { memberId: _memberId ?? memberId, offset, limit },
      })
        .then(async (response) => {
          let tmp = null;
          let tmp2 = null;
          if (response.data.resultCode === 20000) {
            console.log(
              "response.data.body getEventData >>",
              response.data.body
            );
            let resData = response.data.body;
            resData = resData.map((item) => {
              item.isActive = false;
              return item;
            });
            tmp = { ...historyData };
            tmp.event = [...tmp.event, ...resData];
            // setHistoryData(tmp)

            tmp2 = { ...historyDataCount };
            tmp2.event = response.data.rowCount;
            // setHistoryDataCount(tmp2)
          } else {
            tmp = { ...historyData };
            tmp.event = [];
            tmp2 = { ...historyDataCount };
            // setHistoryData(tmp)
          }

          if (isRender) {
            setHistoryData(tmp);
            setHistoryDataCount(tmp2);
          } else {
            resolve({ tmp, tmp2 });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async function getExpireData(_memberId, _currentDate) {
    _memberId = _memberId ?? memberId;
    const { limit, offset } = expLoadMore;
    const yearMonth = dayjs(_currentDate).format("YYYY-MM");
    return await SearchPointExpire({
      params: { memberId: _memberId, month: yearMonth },
    })
      .then(async (response) => {
        setHistoryExpire(response.data?.body ?? []);
        setHistoryExpireCount(response.data?.rowCount ?? 0);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function onLoadMore(name) {
    console.log("name >>", name);
    console.log("loadMore A>>", loadMore[name].limit);
    console.log("loadMore B>>", historyData[name].length);
    if (historyData[name].length >= loadMore[name].limit) {
      setIsLoading(true);
      let tmp = { ...loadMore };
      tmp[name].offset = tmp[name].limit;
      tmp[name].limit = tmp[name].limit + 1;

      console.log("loadMore New >>", tmp[name]);
      setLoadmore(tmp);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  }

  const selectDCalendar = (event) => {
    setCurrentDate(event.dateIso);
    getExpireData(memberId, event.dateIso);
  };

  function ItemListScan() {
    const onActive = (i) => {
      let tmp = { ...historyData };
      tmp.scan = [...historyData?.scan];
      tmp.scan[i].isActive = true;
      setHistoryData(tmp);
    };
    return (
      <div
        className="overflow-y-auto"
        style={{ height: "calc(100vh - 370px)" }}
      >
        {Array.isArray(historyData.scan) && historyData.scan.length ? (
          historyData.scan.map((item, i, arr) => (
            <div id="single-card" key={item.id}>
              <div className="">
                <div id="section1" className="py-[6px]">
                  <div className="flex justify-between items-center px-[16px]">
                    {/* <p className="text-lightBlue text-[20px] leading-[120%]">SP1210D921234</p> */}
                    <p className="text-gray text-[16px] leading-[120%]">
                      {dayjs(item.createdDate).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div>
                <hr className="border-lightGray" />
                <div className="px-[16px] pb-[16px]">
                  {item.event
                    .filter((itm2, j) => true || item.isActive || j === 0)
                    .map((item2) => (
                      <>
                        <div id="section2" className="py-[12px]">
                          <Grid container spacing={2}>
                            <Grid item xs={2}>
                              <div className="bg-lightGray rounded-[4px] w-12 h-12 flex justify-center items-center">
                                <img
                                  src={mobile}
                                  alt=""
                                  width="21px"
                                  height="29px"
                                />
                              </div>
                            </Grid>
                            <Grid item xs={10} className="text-left flex-1">
                              <p className="text-black text-[20px] leading-[24px] pl-[8px]">
                                {item2.clinicPetShop?.shopName}
                              </p>
                              <p className="text-gray text-[16px] leading-[17.6px] pl-[8px]">
                                {item2.clinicPetShop?.shopNo}
                              </p>
                            </Grid>
                          </Grid>
                        </div>
                        <hr className="border-lightGray" />
                        <div
                          id="section3"
                          className="pt-[20px] pb-[6px] text-[16px] leading-[0px]"
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={5}>
                              <p className="text-gray">รหัส QR Code</p>
                            </Grid>
                            <Grid item xs={7} className="text-end">
                              <p className="text-black">
                                {item2.qrCode?.qrCode}
                              </p>
                            </Grid>

                            <Grid item xs={5}>
                              <p className="text-gray">NexCoin ที่ได้รับ</p>
                            </Grid>
                            <Grid item xs={7} className="text-end">
                              <p className="text-black">
                                {item2.earnBurnPoint} คะแนน
                              </p>
                            </Grid>
                            <Grid item xs={5}>
                              <p className="text-gray">คะแนนจะหมดอายุ</p>
                            </Grid>
                            <Grid item xs={7} className="text-end">
                              {item2.expireDate && (
                                <p className="text-black">
                                  {dayjs(item2.expireDate).format("DD/MM/YYYY")}
                                </p>
                              )}
                            </Grid>
                            {item2.isEarnQrStamp === 1 && (
                              <>
                                <Grid item xs={5}>
                                  <p className="text-gray">
                                    แสตมป์ที่คุณได้รับ
                                  </p>
                                </Grid>
                                <Grid item xs={7} className="text-end">
                                  <p className="text-black">{1} ดวง</p>
                                </Grid>
                              </>
                            )}
                            {/* { item2.expireDate && <p className="text-gray text-[16px] leading-[17.6px] pl-[8px]">คะแนนจะหมดอายุ: { dayjs(item2.expireDate).format('DD/MM/YYYY') }</p> } */}
                          </Grid>
                        </div>
                      </>
                    ))}
                </div>
              </div>
              {/* {index + 1 !== historyData.scan.length && <hr className="border-[5px] border-lightGray" />} */}
              {item.isActive === false && item.event.length > 1 && (
                <div className="px-[16px] py-[4px] text-center">
                  <hr className="border-b-1px border-lightGray w-full" />
                  <p
                    className="py-[4px] text-blue cursor-pointer"
                    onClick={() => onActive(i)}
                  >
                    แสดงอีก {item.event.length - 1} รายการ
                  </p>
                </div>
              )}
              <div className={`historyList ${arr.length === i + 1}`}></div>
            </div>
          ))
        ) : (
          <div className="flex justify-center items-center min-h-[147px] h-[60%]">
            <div className="flex flex-col justify-center text-center">
              <img
                className="mb-[23px] mx-auto"
                src={scanQR}
                alt="location"
                style={{ display: "inline", width: 52 }}
              />
              <p className="text-darkGray text-[24px] leading-[120%]">
                ยังไม่มีประวัติการแสกน
              </p>
              <p className="text-gray text-[16px] leading-[100%]">
                กรุณากลับมาเช็กข้อมูลอีกครั้งในโอกาสหน้า
              </p>
              <p className="text-gray text-[16px] leading-[100%]">
                หรือติดตามข่าวสารจาก Line @spectrafordog
              </p>
            </div>
          </div>
        )}

        {/* <hr className="border-b-1px border-lightGray w-full" /> */}
        {/* <p className='py-[4px] text-center text-blue cursor-pointer' onClick={() => onLoadMore('scan')}>โหลดเพิ่ม</p> */}
      </div>
    );
  }

  function ItemListEvent() {
    const onActive = (i) => {
      let tmp = { ...historyData };
      tmp.event = [...historyData?.event];
      tmp.event[i].isActive = true;
      setHistoryData(tmp);
    };
    return (
      <div
        className="overflow-y-auto"
        style={{ height: "calc(100vh - 370px)" }}
      >
        {Array.isArray(historyData?.event) &&
          historyData?.event.map((item, i) => (
            <div id="single-card" key={item.id}>
              <div className="">
                <div id="section1" className="py-[6px] bg-lightGray2">
                  <p className="text-gray text-[16px] leading-[19.2px] px-[16px]">
                    {dayjs(item.createdDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                  </p>
                </div>
                <hr className="border-lightGray" />
                {item.event
                  .filter((itm2, j) => item.isActive || j === 0)
                  .map((item2) => {
                    const eventType = item2?.event?.type;
                    const cpsStatus = item2?.collectPointApprove?.status;
                    const CONF_EVENT_TYPE = {
                      QUESTIONNAIRE: quiz,
                      COLLECT_POINT_APPROVE: collectPointApprove,
                      GAME: game,
                      REGISTER: null,
                      PET_DIARY: pet_diary,
                      WELCOME_BACK: null,
                      COLLECT_POINT: null,
                      DEAL: null,
                      INVITE: invite,
                    };
                    const CONF_STATUS_CPS = {
                      1: "Pending",
                      2: "Approve",
                      3: "Reject",
                    };

                    let imgEvent = scanGeneral;
                    let uploadStatus = "";

                    if (CONF_EVENT_TYPE[eventType]) {
                      imgEvent = CONF_EVENT_TYPE[eventType];
                    }

                    if (CONF_STATUS_CPS[cpsStatus]) {
                      uploadStatus = CONF_STATUS_CPS[cpsStatus];
                    }

                    return (
                      <div id="section2" className="py-[12px] px-[16px]">
                        <div className="flex items-center">
                          <div item>
                            <div className="flex justify-center items-center border-[1px] border-lightGray rounded-[4px] p-[2px]">
                              <img
                                className="w-full max-w-[41px]"
                                src={imgEvent}
                                alt=""
                                width="41px"
                                height="41px"
                              />
                            </div>
                          </div>
                          <div
                            item
                            xs={10}
                            className="text-left flex-1 flex justify-between items-center"
                          >
                            <div className="flex-1">
                              <p className="text-black text-[20px] leading-[24px] pl-[8px]">
                                {item2.event.eventName}
                              </p>
                              <p className="text-gray text-[16px] leading-[17.6px] pl-[8px]">
                                NexCoin ที่ได้รับ:{" "}
                                {item2.earnBurnPoint
                                  ? `${helper.numberWithCommas(
                                      item2.earnBurnPoint
                                    )} คะแนน`
                                  : "ไม่ได้คะแนน"}
                              </p>
                              {item2.expireDate && (
                                <p className="text-gray text-[16px] leading-[17.6px] pl-[8px]">
                                  คะแนนจะหมดอายุ:{" "}
                                  {dayjs(item2.expireDate).format("DD/MM/YYYY")}
                                </p>
                              )}
                            </div>
                            <div>
                              {cpsStatus && (
                                <div
                                  className={`tag-status-${uploadStatus} border-[1px] rounded-[28px] py-[4px] px-[18px]`}
                                >
                                  <p className={`text-[16px] leading-[120%]`}>
                                    {uploadStatus}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {item.isActive === false && item.event.length > 1 && (
                  <div className="px-[16px] py-[4px] text-center">
                    <hr className="border-b-1px border-lightGray w-full" />
                    <p
                      className="py-[4px] text-blue cursor-pointer"
                      onClick={() => onActive(i)}
                    >
                      แสดงอีก {item.event.length - 1} รายการ
                    </p>
                  </div>
                )}
              </div>
            </div>
          ))}
        {/* <hr className="border-b-1px border-lightGray w-full" /> */}
        {/* <p className='py-[4px] text-center text-blue cursor-pointer' onClick={() => onLoadMore('event')}>โหลดเพิ่ม</p> */}
      </div>
    );
  }

  function ItemListExpire() {
    const sumPoint = historyExpire.reduce(
      (pre, cur) => (pre = pre + cur.expiredPoints ?? 0),
      0
    );
    return (
      <div
        className="overflow-y-auto"
        style={{ height: "calc(100vh - 370px)" }}
      >
        <CalendarTab value={currentDate} onSelect={selectDCalendar} />
        {Array.isArray(historyExpire) && historyExpire.length ? (
          <>
            <div className="flex justify-between bg-[#F7F7F7] px-[17px] py-[8px]">
              <p className="text-[16px] text-darkGray leading-[120%]">
                คะแนนรวมที่หมดอายุ
              </p>
              <p className="text-[16px] text-darkGray2 font-bold leading-[120%]">
                {sumPoint} คะแนน
              </p>
            </div>
            <div className="pt-[12px] pb-[18px] px-[17px]">
              {historyExpire.map((item, i) => (
                <div
                  className="flex justify-between mb-[4px] text-gray"
                  key={item.id}
                >
                  <p className="text-[16px] leading-[110%] text-gray">
                    {item.expire
                      ? dayjs(item.expire).format("DD/MM/YYYY")
                      : "-"}
                  </p>
                  <p className="text-[16px] leading-[110%] text-gray">
                    {item.expiredPoints} คะแนน
                  </p>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="flex justify-center items-center min-h-[147px] h-[60%]">
            <div className="flex flex-col justify-center text-center">
              <img
                className="mb-[14px] mx-auto"
                src={expireIcon}
                alt="expireIcon"
                style={{ display: "inline", width: 64 }}
              />
              <p className="text-darkGray text-[24px] leading-[120%]">
                ยังไม่มีประวัติคะแนนหมดอายุ
              </p>
              <p className="text-gray text-[16px] leading-[100%]">
                กรุณากลับมาเช็กคะแนนที่หมดอายุของท่านอีกครั้งในโอกาสหน้า
              </p>
              <p className="text-gray text-[16px] leading-[100%]">
                หรือติดตามข่าวสารจาก Line @spectrafordog
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }

  const fetchItems = () => {
    if (memberId && historyDataCount.event) {
      console.log("-----------------------------");
      console.log("fetchItems!");
      onLoadMore("event");
      console.log("-----------------------------");
    }
  };

  // const isLoading = true;

  const loader = (
    <div key="loader" className="loader text-center text-[20px] text-gray">
      Loading ...
    </div>
  );

  return (
    <div id="my-point-history">
      <TopLogo />
      <div
        id="gradient"
        className="gradientBg px-[13px] overflow-hidden"
        style={{ height: "calc(100vh - 60px)" }}
      >
        <div id="viewport" className="">
          <div id="label" className="pt-[36px]">
            <p className="text-white font-light text-[28px] leading-5">
              ประวัติการสะสม NexCoin
            </p>
          </div>

          <hr className="border-white opacity-25 my-[20px]" />

          <div
            id="collected-point"
            className="text-white font-normal text-[20px] leading-5"
          >
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <p>NexCoin สะสมรวม</p>
              </Grid>
              <Grid item xs={7} className="text-end">
                <span>
                  <p className="inline-block font-semibold text-[28px]">
                    {helper.numberWithCommas(currentPoint)}
                  </p>{" "}
                  คะแนน
                </span>
              </Grid>
            </Grid>

            {/* <p className="font-light text-[16px] leading-5">คะแนนของท่านจะหมดอายุในวันที่ 31/12/{dayjs().year()}</p> */}
          </div>

          <div id="item-list" className="bg-white rounded-[4px] mt-[24px]">
            <div id="tab-select">
              <div className="flex items-center">
                <p
                  onClick={() => setPage("scan")}
                  className={
                    `w-full text-[18px] text-gray text-center py-[9px] border-b-[1px] border-lightGray ` +
                    (page === "scan" &&
                      "!text-blue !border-b-[4px] !border-blue")
                  }
                >
                  จากการสแกน
                </p>
                <p
                  onClick={() => setPage("event")}
                  className={
                    `w-full text-[18px] text-gray text-center py-[9px] border-b-[1px] border-lightGray ` +
                    (page === "event" &&
                      "!text-blue !border-b-[4px] !border-blue")
                  }
                >
                  จากกิจกรรม
                </p>
                <p
                  onClick={() => setPage("expire")}
                  className={
                    `w-full text-[18px] text-gray text-center py-[9px] border-b-[1px] border-lightGray ` +
                    (page === "expire" &&
                      "!text-blue !border-b-[4px] !border-blue")
                  }
                >
                  คะแนนหมดอายุ
                </p>
              </div>
            </div>

            <div id="item-card">
              {page === "scan" ? (
                <>{ItemListScan()}</>
              ) : page === "event" ? (
                <InfiniteScroll
                  loadMore={fetchItems}
                  hasMore={isLoading}
                  loader={loader}
                >
                  {ItemListEvent()}
                </InfiniteScroll>
              ) : (
                <>{ItemListExpire()}</>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center bg-white px-[20px] pt-[16px] pb-[24px] fixed bottom-0 left-0 right-0">
        <BackButton onClick={() => navigate(-1)}>
          <p className="text-[24px] text-center">ย้อนกลับ</p>
        </BackButton>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import helper from "../../functions/helper";
import DatePicker from "react-multi-date-picker";
import { Calendar } from "react-multi-date-picker";
import calendar from "../../assets/images/icon/calendar.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

export default function MyPointHistory(props) {
  const { values = [], setValues} = props
  // const [values, setValues] = useState([]);
  const [tmpValues, setTmpValues] = useState(values);
  const [onSelect, setOnSelect] = useState(false);
  console.log("values >>", values);

  function handleClose() {
    setTmpValues([])
    setOnSelect(false)
  }

  function toDateRange(dateArr = []) {
    const [start, end] = dateArr;
    if (start && end) {
      return Rdate(start) + " ถึง " + Rdate(end);
    }
    return '';
  }

  function Rdate(rDate = {}) {
    const nz = helper.numberWithZero
    return `${nz(rDate.day, 2)}/${nz(rDate.month,2)}/${rDate.year}`;
  }

  return (
    <>
      <Dialog
        className="box-1234"
        open={onSelect}
        onClose={handleClose}
      >
        <DialogContent className="!p-[0px]">
          <div className="">
            <Calendar value={tmpValues} onChange={setTmpValues} range rangeHover />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={()=>{
            if (tmpValues.length == 2) {
              setValues(tmpValues)
              handleClose()
            }
          }} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <div className="pet-range-picker">
        <div className="input">
          <TextField
            label={props.label}
            variant="standard"
            value={ toDateRange(values)}
            fullWidth
            required={props.required ?? false}
            error={props.error ?? false}
            helperText={props.helperText}
            InputProps={{ endAdornment: <img src={calendar} alt=""/>, readOnly: true }}
            onClick={() => {
              setOnSelect(true);
            }}
          />
        </div>
      </div>
    </>
  );
}

import React from "react";
import Grid from "@mui/material/Grid";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import checkmark from "../../assets/images/checkmark.png";
import TopLogo from "../../components/layout/TopLogo";
import LogoDogDang from "../../assets/images/logo-dog-dang.png";

export default function MyPointSuccess(props) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { title, subtitle, points, listDetail = [] } = props;
  const { open, toPage } = props;
  console.log("props >>", props);
  function DetailInfo(title, data) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={3}>
          {title}:
        </Grid>
        {data ? (
          <Grid item xs={9} className="text-end">
            {data}
          </Grid>
        ) : (
          <Grid item xs={9} className="text-end">
            -
          </Grid>
        )}
      </Grid>
    );
  }
  const isOpen = open ?? true;
  if (isOpen) {
    return (
      <div className="fixed h-screen w-screen z-10">
        <TopLogo />
        <div
          id="get-point-success"
          className="gradientBg justify-center py-[42px] px-[30px] h-[100vh]"
        >
          <div className="flex justify-center">
            <img src={checkmark} alt="" width="52px" height="52px" />
          </div>

          <p className="text-center text-white text-[32px]">{title}</p>
          <p className="text-center text-white text-[36px] leading-[84%]">
            {subtitle}
          </p>
          <div className="mt-[60px]">
            <img
              className="mx-auto "
              src={LogoDogDang}
              alt="logo"
              style={{ width: "40%" }}
            />
          </div>
          <div className="absolute bottom-[36px] w-[80%] mx-auto left-0 right-0">
            {/* <p className="text-left text-white text-[16px] leading-[84%] mb-[10%]">
                        *คุณสามารถร่วมกิจกรรมสแกนรับพ้อยท์ได้เดือนละครั้งเท่านั้น
                        </p> */}
            <button
              onClick={() => {
                navigate(toPage);
              }}
              className="text-white text-[24px] text-center py-[9px] rounded-[28px] bg-[#082E68]/[0.24] w-full"
            >
              เสร็จสิ้น
            </button>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
}

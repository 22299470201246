import React from "react";
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";

import TopLogo from '../../components/layout/TopLogo';
import SpectraNearYou from '../../assets/images/spectra-near-you.png'
import PrimaryButton from "../../components/button/PrimaryButton";

export default function SpectraNearYouMain() {
    const [latitude, setLat] = React.useState(null);
    const [longitude, setLong] = React.useState(null);
    const navigate = useNavigate();

    const goToClinics = () =>
        navigate('/spectra-near-you-list', {
            state: {
                latitude: latitude,
                longitude: longitude,
                type: 'CLINIC'
            }
        });

    const goToShops = () =>
        navigate('/spectra-near-you-list', {
            state: {
                latitude: latitude,
                longitude: longitude,
                type: 'SHOP'
            }
        });

    React.useEffect(() => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setLat(position.coords.latitude);
            setLong(position.coords.longitude);
        })
    }, []);

    return (
        <React.Fragment>
            <TopLogo />
            <div
                style={{
                    background:
                        "linear-gradient(152.04deg, rgb(10, 65, 151) -0.97%, rgb(32, 159, 237) 81.57%)",
                    minHeight: "calc(100vh - 60px)",
                    padding: "20px",
                }}
            >

                <div className="text-white text-center text-[35px] mt-[50px]">
                    <p>ค้นหาโรงพยาบาลสัตว์ คลินิก<br />และเพ็ทช็อป ใกล้ฉัน</p>
                </div>

                <div className="text-white text-center text-[24px]">
                    กรุณาแชร์ตำแหน่งของคุณก่อนใช้งาน
                </div>
                <img
                    className="mx-auto my-[20px]"
                    src={SpectraNearYou}
                    alt="logo"
                    style={{ width: "90%" }}
                />
                <div className="text-center mt-[50px]">
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <PrimaryButton
                                variant="contained"
                                style={{
                                    minWidth: "150px",
                                    background: "white",
                                    color: "#0A4197",
                                    marginBottom: "10px",
                                    width: "85%",
                                }}
                                onClick={goToClinics}
                            >
                                โรงพยาบาลสัตว์/คลินิกที่ใกล้ฉัน
                            </PrimaryButton>

                        </Grid>
                        <Grid item xs={12}>
                            <PrimaryButton
                                variant="contained"
                                style={{
                                    minWidth: "150px",
                                    background: "white",
                                    color: "#0A4197",
                                    width: "85%"
                                }}
                                onClick={goToShops}
                            >
                                เพ็ทช็อปที่ใกล้ฉัน
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </React.Fragment>
    )
}

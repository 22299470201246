import React, { useEffect } from "react";
import Checkmark from "../../assets/images/checkmark.png";
import LogoDogSit from "../../assets/images/logo-dog-sit.png";
import PrimaryButton from "../../components/button/PrimaryButton";
import PopupQuestionnaireSuccess from "../../components/popup/PopupQuestionnaireSuccess";
import { useLocation, useNavigate } from "react-router-dom";
import SecondaryButton from "../../components/button/SecondaryButton";
import { useSelector } from "react-redux";
import TopLogo from "../../components/layout/TopLogo";
import { SearchId } from "../../services/SearchId";
import errorCircle from "../../assets/images/icon/error-circle.svg";
import { useLiff } from "react-liff";

export default function QuestionnaireNotFound() {
  const store = useSelector((state) => state.questionnaire);
  const [openCancelPopup, setOpenCancelPopup] = React.useState(true);
  const [memberId, setMemberId] = React.useState(true);
  const navigate = useNavigate();

  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  const isMember = memberId ?? false;
  const isShowPoint = store["points"] !== 0;

  useEffect(() => {
    getSearchId();
    //
  }, []);

  async function getSearchId() {
    return await SearchId({
      params: { lineId: lineId || null },
    })
      .then(async (response) => {
        if (!response.data.body.memberId) {
          setMemberId(null);
        } else {
          setMemberId(response.data.body.memberId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <TopLogo />
      {/* {JSON.stringify(store)} */}
      <div
        style={{
          background:
            "linear-gradient(152.04deg, #0A4197 -0.97%, #209FED 81.57%)",
          minHeight: "calc(100vh - 60px)",
          padding: "20px",
        }}
      >
        <img
          className="mx-auto mt-[100px]"
          src={errorCircle}
          alt="logo"
          style={{ maxWidth: "52px" }}
        />
        <div className="text-white text-center text-[36px] mt-[10px] leading-[100%]">
          ขออภัย
        </div>
        <div className="text-white text-center text-[24px] leading-[100%]">
          กิจกรรมนี้สิ้นสุดแล้ว
        </div>

        {false && (
          <div className="absolute w-full bottom-[15px] left-0 text-center mt-[50px]">
            <div className="my-4">
              {isMember ? (
                <PrimaryButton
                  variant="contained"
                  className="w-[70%] !bg-[#082e683d]"
                  onClick={() => {
                    navigate("/my-point");
                  }}
                >
                  กลับไปหน้าแลกของรางวัล
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  variant="contained"
                  className="w-[70%] !bg-[#082e683d]"
                  onClick={() => {
                    navigate("/register");
                  }}
                >
                  ลงทะเบียน NEX Family
                </PrimaryButton>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

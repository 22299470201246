import React, { useEffect } from "react";
import Checkmark from "../../assets/images/checkmark.png";
import QuizManiaSucces from "../../assets/images/quiz-mania-succes.png";
import LogoDogSit from "../../assets/images/logo-dog-sit.png";
import PrimaryButton from "../../components/button/PrimaryButton";
import PopupQuestionnaireSuccess from "../../components/popup/PopupQuestionnaireSuccess";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SecondaryButton from "../../components/button/SecondaryButton";
import { useDispatch, useSelector } from "react-redux";
import TopLogo from "../../components/layout/TopLogo";
import { SearchId } from "../../services/SearchId";
import { useLiff } from "react-liff";
import { setQuestionnaire } from "../../features/QuestionnaireSlice";
import { setQuizMania } from "../../features/QuizManiaSlice";
import helper from "../../functions/helper";

export default function QuestionnaireSuccess() {
  const store = useSelector((state) => state.questionnaire);
  const [openCancelPopup, setOpenCancelPopup] = React.useState(true);
  const [memberId, setMemberId] = React.useState(true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const year = searchParams.get("year");
  const quarter = searchParams.get("quarter");
  const quizId = searchParams.get("quiz");

  const isQuarter = searchParams.get("quarter") ? true : false;

  const [isQuizMania, setIsQuizMania] = React.useState(false);

  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  const dispatch = useDispatch();
  // const qRegisterAns = searchParams.get('registerAns')
  // const registerAns = JSON.parse(qRegisterAns)

  const registerAnsStr = window.localStorage.getItem("p");
  let registerAns = {};
  if (registerAnsStr) {
    // registerAns = JSON.parse(atob(registerAnsStr))
    registerAns = helper.decodeJWT(registerAnsStr);
  }

  const isMember = memberId ?? false;
  const isShowPoint = store["points"] !== 0;

  useEffect(() => {
    getSearchId();
    //
    const { memberId, points, payload } = registerAns;
    if (isQuarter) {
      setIsQuizMania(true);
      fnSetQuizData("memberId", memberId ?? null);
      fnSetQuizData("points", points);
      fnSetQuizData("payload", payload);
    } else {
      fnSetQuestionnaireData("memberId", memberId ?? null);
      fnSetQuestionnaireData("points", points);
      fnSetQuestionnaireData("payload", payload);
    }
  }, []);

  async function getSearchId() {
    return await SearchId({
      params: { lineId: lineId || null },
    })
      .then(async (response) => {
        if (!response.data.body.memberId) {
          setMemberId(null);
        } else {
          setMemberId(response.data.body.memberId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fnSetQuestionnaireData(name, value) {
    const tmpObj = {};
    tmpObj[name] = value;
    dispatch(setQuestionnaire(tmpObj));
  }

  function fnSetQuizData(name, value) {
    const tmpObj = {};
    tmpObj[name] = value;
    dispatch(setQuizMania(tmpObj));
  }

  return (
    <>
      <PopupQuestionnaireSuccess
        point={store["points"]}
        actionFunction={() => {
          setOpenCancelPopup(false);
        }}
        open={isShowPoint && isMember && openCancelPopup}
        onClose={() => {
          setOpenCancelPopup(false);
        }}
      />
      <TopLogo />
      {/* {JSON.stringify(store)} */}
      <div
        style={{
          background:
            "linear-gradient(152.04deg, #0A4197 -0.97%, #209FED 81.57%)",
          minHeight: "calc(100vh - 60px)",
          padding: "20px",
        }}
      >
        <img
          className="mx-auto mt-[18px]"
          src={Checkmark}
          alt="logo"
          style={{ maxWidth: "52px" }}
        />

        <div className="text-white text-center text-[36px] mt-[10px] leading-[100%]">
          ตอบคำถามเรียบร้อย
        </div>
        <div className="text-white text-center text-[24px] leading-[100%]">
          ขอบคุณที่ร่วมสนุก
        </div>
        {isQuizMania ? (
          <>
            <img
              className="mx-auto mt-[24px] w-full max-w-[236px]"
              src={QuizManiaSucces}
              alt="logo"
            />
            <p className="text-white text-center text-[20px] leading-[120%] mt-[24px]">ติดตามเฉลยคำตอบของ Quiz mania ได้ที่<br/>โพสต์ Line Voom ของ Spectra For Dog ในช่วงต้นเดือน</p>
          </>
        ) : (
          <img
            className="mx-auto"
            src={LogoDogSit}
            alt="logo"
            style={{ width: "50%" }}
          />
        )}
        {isQuizMania ? (
          <div className="bQuizManiaSucccessMain">
            <div className="bQuizManiaSucccess">
              {isMember ? (
                <>
                  <button
                    className="btn _outline"
                    onClick={() =>
                      navigate(
                        `/quiz-mania-rank?quiz=${quizId}&year=${year}&quarter=${quarter}`
                      )
                    }
                  >
                    เช็คอันดับคะแนนของคุณ
                  </button>
                  <button
                    className="btn _outline transparent"
                    onClick={() => navigate("/redeem-gift-member")}
                  >
                    ไปยัง Privilege
                  </button>
                </>
              ) : (
                <button
                  className="btn _outline"
                  onClick={() => {
                    window.localStorage.removeItem("p");
                    navigate("/register");
                  }}
                >
                  ลงทะเบียน NEX Family
                </button>
              )}
            </div>
          </div>
        ) : (
          <div className="text-center mt-[50px]">
            <div className="my-4">
              {isMember ? (
                <PrimaryButton
                  variant="contained"
                  className="w-[70%] !bg-[#082e683d]"
                  onClick={() => {
                    window.localStorage.removeItem("p");
                    navigate("/dogbook");
                  }}
                >
                  กลับไปที่สมุดพกน้องหมา
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  variant="contained"
                  className="w-[70%] !bg-[#082e683d]"
                  onClick={() => {
                    window.localStorage.removeItem("p");
                    navigate("/register");
                  }}
                >
                  ลงทะเบียน NEX Family
                </PrimaryButton>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

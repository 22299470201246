import React from "react";
import { useLiff } from "react-liff";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import CookieSettingPopup from "../../components/popup/CookieSettingPopup";
import CustomPopupShopMember from "../../components/popup/CustomPopupShopMember";
import ShopCard from "../../components/card/ShopCard";
import ShopBanner from "../../assets/images/membercardowner/banner.png";

import add from "../../assets/images/icon/add.svg";
import edit from "../../assets/images/icon/edit.svg";
import { SearchId } from "../../services/SearchId";
import {
  SearchShopProfile,
  SearchEmployeeDetail,
  PostDeleteEmployee,
} from "../../services/MemberCardOwner";
import TopLogo from "../../components/layout/TopLogo";
import TopBanner from "../../components/layout/TopBanner";

export default function MemberCardOwnerMain() {
  const [clinicPetShop, setClinicPetShop] = React.useState(null);
  const [employee, setEmployee] = React.useState(null);
  const [employees, setEmployees] = React.useState(null);

  const [employeeIdForDelete, setEmployeeIdForDelete] = React.useState(null);

  const [openCookiePopup, setOpenCookiePopup] = React.useState(false);
  const [openCancelPopup, setOpenCancelPopup] = React.useState(false);
  const [openEditedPopup, setOpenEditedPopup] = React.useState(false);

  const navigate = useNavigate();

  const [page, setPage] = React.useState("info");

  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  React.useEffect(() => {
    getSearchId();
  }, []);

  async function getSearchId() {
    return await SearchId({
      params: { lineId: lineId || null },
    })
      .then(async (response) => {
        if (!response.data.body.employeeId) {
          navigate("/register");
        } else {
          getShopProfile(
            response.data.body.employeeId || "194",
            response.data.body.clinicPetShopId || "3216"
          );
          getEmployeeDetail(response.data.body.clinicPetShopId || "3216");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getShopProfile(employeeId, clinicPetShopId) {
    return await SearchShopProfile({
      params: { lineId: lineId || null },
      employeeId: employeeId,
      clinicPetShopId: clinicPetShopId,
    })
      .then(async (response) => {
        setClinicPetShop(response.data.body.clinicPetShop);
        setEmployee(response.data.body.employee);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getEmployeeDetail(clinicPetShopId) {
    return await SearchEmployeeDetail({
      params: { lineId: lineId || null },
      clinicPetShopId: clinicPetShopId,
    })
      .then(async (response) => {
        if (response.data.resultCode === 20000) {
          setEmployees(response.data.body);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const goToEdit = () => {
    // employee.isOwner = false;
    if (employee.isOwner) {
      navigate("/member-card-owner-edit", {
        state: {
          clinicPetShop: clinicPetShop,
          employee: employee,
        },
      });
    } else {
      const data = {
        email: employee.email,
        firstName: employee.firstName,
        lastName: employee.lastName,
        mobileNumber: employee.mobileNumber,
        isOwner: employee.isOwner,
        employeeId: parseInt(employee.employeeId),
      };
      console.log("data bb", data);
      navigate("/member-card-owner-edit-employee", {
        state: {
          payload: data,
        },
      });
    }
  };

  const goToEditEmployee = (data) =>
    navigate("/member-card-owner-edit-employee", {
      state: {
        payload: data,
      },
    });

  const deleteEmployeePopup = async (data) => {
    // console.log('item data', data);
    setOpenCancelPopup(true);
    setEmployeeIdForDelete(data);
  };

  const deleteEmployee = async () => {
    const myPayload = {
      employeeId: employeeIdForDelete,
    };

    try {
      const { data } = await PostDeleteEmployee({ data: myPayload });
      console.log("resData.data >>", data);
      if (data.resultCode === 20000) {
        // console.log("delete success");
        setOpenCancelPopup(false);
        window.open("/member-card-owner", "_self");
      }
    } catch (error) {
      console.log("PostDeleteEmployee error", error);
    }
  };

  const checkIsOwner = (isOwner) => {
    if (isOwner) {
      return "เจ้าของร้าน/ผู้จัดการฝ่ายจัดซื้อ/ผู้จัดการสาขา";
    } else {
      return "พนักงาน";
    }
  };

  function UserInfo(title, data) {
    return (
      <div className="flex justify-between items-start">
        <p className="text-gray text-[20px] whitespace-pre-line leading-[24px]">
          {title}:
        </p>
        {data ? (
          <div className="max-w-[200px] text-right text-darkGray text-[20px] whitespace-pre-line leading-[24px]">
            {data}
          </div>
        ) : (
          <p className="text-darkGray text-[20px]">-</p>
        )}
      </div>
    );
  }

  function EmployeeCard() {
    return (
      <div className="flex flex-col gap-[8px] mb-[16px]">
        <CustomPopupShopMember
          title={"ยืนยันการลบข้อมูลพนักงาน"}
          desc={
            "คุณต้องการลบข้อมูลพนักงานคนนี้หรือไม่? หากมั่นใจแล้ว กรุณากด ยืนยัน เพื่อดำเนินการต่อ"
          }
          cancelFunction={() => {
            setOpenCancelPopup(false);
          }}
          actionFunction={() => {
            deleteEmployee();
          }}
          open={openCancelPopup}
          onClose={() => {
            setOpenCancelPopup(false);
          }}
        />
        {employees &&
          employee &&
          employees
            .filter((item) => item.employeeId != employee.employeeId)
            .map((item) => (
              <div
                key={item.employeeId}
                className="bg-white rounded-[4px] mt-[10px] px-[15px] pt-[10px] pb-[15px]"
              >
                <div>
                  <p className="text-lightBlue text-[23px] text-left">
                    ข้อมูลพนักงาน
                  </p>
                </div>
                {UserInfo(
                  "ชื่อ - นามสกุล",
                  `${item.firstName} ${item.lastName}`
                )}
                {UserInfo("ตำแหน่ง", checkIsOwner(item.isOwner))}
                {UserInfo("อีเมล", item.email)}
                {UserInfo("เบอร์โทรศัพท์", item.mobileNumber)}

                {employee.isOwner && (
                  <div className="flex items-center gap-[12px]">
                    <button
                      onClick={() => {
                        deleteEmployeePopup(item.employeeId);
                      }}
                      className="mt-[20px] w-fit flex gap-[5px] items-center justify-center py-[1px] px-[17px] rounded-[28px] border-[1px] border-red"
                    >
                      <FontAwesomeIcon
                        icon={faXmark}
                        className="w-[11px] text-red"
                      />
                      <p className="text-red text-[15px]">ลบ</p>
                    </button>

                    <button
                      onClick={() => {
                        goToEditEmployee(item);
                      }}
                      className="mt-[20px] w-fit flex gap-[5px] items-center justify-center py-[1px] px-[17px] rounded-[28px] border-[1px] border-blue"
                    >
                      <img className="w-[10px]" src={edit} alt="" />
                      <p className="text-blue text-[15px] whitespace-nowrap">
                        แก้ไขข้อมูล
                      </p>
                    </button>
                  </div>
                )}
              </div>
            ))}
      </div>
    );
  }

  return (
    <div id="shop-page">
      <TopLogo />
      <TopBanner type="memberCardOwner" />
      <CookieSettingPopup
        open={openCookiePopup}
        onClose={() => {
          setOpenCookiePopup(false);
        }}
      />
      {/*<img src={ShopBanner} alt="shopbanner" className="w-full" />*/}
      <div id="shop" className="gradientBg px-[20px]">
        <div className="relative">
          <ShopCard data={clinicPetShop} />
          <div className="bg-white rounded-[4px] mt-[24px]">
            <div className="flex items-center">
              <p
                onClick={() => setPage("info")}
                className={
                  `w-full text-[20px] text-gray text-center py-[9px] border-b-[1px] border-lightGray ` +
                  (page === "info" && "!text-blue !border-b-[4px] !border-blue")
                }
              >
                ข้อมูลของฉัน
              </p>
              <p
                onClick={() => setPage("address")}
                className={
                  `w-full text-[20px] text-gray text-center py-[9px] border-b-[1px] border-lightGray ` +
                  (page === "address" &&
                    "!text-blue !border-b-[4px] !border-blue")
                }
              >
                ข้อมูลร้านค้า
              </p>
            </div>

            {page === "info" && employee ? (
              <div className="p-[16px]">
                <p className="text-[24px] text-lightBlue">ข้อมูลของฉัน</p>
                <div className="flex flex-col gap-[8px] mt-[10px]">
                  {UserInfo(
                    "ชื่อ - นามสกุล",
                    `${employee.firstName} ${employee.lastName}`
                  )}
                  {UserInfo(
                    "ตำแหน่ง",
                    employee.isOwner ? (
                      <React.Fragment>
                        <p>เจ้าของร้าน/</p>
                        <p>ผู้จัดการฝ่ายจัดซื้อ/</p>
                        <p>ผู้จัดการสาขา</p>
                      </React.Fragment>
                    ) : (
                      "พนักงาน"
                    )
                  )}
                  {UserInfo("อีเมล", employee.email)}
                  {UserInfo("เบอร์โทรศัพท์", employee.mobileNumber)}
                </div>

                <button
                  onClick={goToEdit}
                  className="mt-[20px] w-fit flex gap-[5px] items-center justify-center py-[1px] px-[17px] rounded-[28px] border-[1px] border-blue"
                >
                  <img className="w-[10px]" src={edit} alt="" />
                  <p className="text-blue text-[15px]">แก้ไขข้อมูล</p>
                </button>
              </div>
            ) : (
              <div className="p-[16px]">
                <p className="text-[24px] text-lightBlue">ข้อมูลร้านค้า</p>

                {clinicPetShop && clinicPetShop.address ? (
                  <div>
                    <div className="flex flex-col gap-[12px]">
                      {UserInfo("รหัสร้านค้า", `${clinicPetShop.shopNo}`)}
                      {UserInfo("ชื่อร้านค้า", `${clinicPetShop.shopName}`)}
                      {UserInfo(
                        "ที่อยู่",
                        `${clinicPetShop.addressDetail} ${clinicPetShop.address.addressName}`
                      )}
                      {UserInfo(
                        "ที่อยู่จัดส่ง",
                        `${clinicPetShop.deAddressDetail} ${clinicPetShop.deAddress.addressName}`
                      )}
                    </div>

                    <button
                      onClick={goToEdit}
                      className="mt-[20px] w-fit flex gap-[5px] items-center justify-center py-[1px] px-[17px] rounded-[28px] border-[1px] border-blue"
                    >
                      <img className="w-[10px]" src={edit} alt="" />
                      <p className="text-blue text-[15px]">แก้ไขข้อมูล</p>
                    </button>
                  </div>
                ) : (
                  <div>
                    <p className="text-[20px]">ไม่ม่ข้อมูล</p>

                    {/* <button
                      onClick={() => window.open("/dogbook-edit-info", "_self")}
                      className="mt-[20px] w-fit flex gap-[5px] items-center justify-center py-[2px] px-[24px] rounded-[28px] border-[1px] border-blue"
                    >
                      <img className="w-[10px]" src={add} alt="" />
                      <p className="text-blue text-[20px]">
                        เพิ่มข้อมูลที่อยู่
                      </p>
                    </button> */}
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="flex justify-center">
            <button
              className="my-[24px] text-white text-[24px] text-center py-[9px] px-[24px] bg-blue/[0.24] rounded-[28px]"
              onClick={() => setOpenCookiePopup(true)}
            >
              ตั้งค่านโยบายความเป็นส่วนตัวและคุกกี้
            </button>
          </div>
        </div>
      </div>

      <div className="mt-[10px] px-[10px]">
        <p className="text-lightBlue text-[28px] text-left">ข้อมูลพนักงาน</p>

        {/* <div className="mt-[10px] flex justify-start items-start gap-[10px]">
          <p className="text-[20px] text-left leading-[20px]">
            กรุณาเพิ่มข้อมูลน้องหมาของคุณ เพื่อรับสิทธิ์พิเศษต่างๆ ของน้องหมา
            โดยกดที่ปุ่ม ‘เพ่ิมข้อมูลน้องหมา’ ด้านล่าง
          </p>
        </div> */}
        {employees && employee && <EmployeeCard />}
      </div>
    </div>
  );
}

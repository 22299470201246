import React, {useEffect} from "react";
import {useLiff} from 'react-liff';

export default function Home() {
  const [decodedIDToken, setDecodedIDToken] = React.useState({})
  const {isLoggedIn, liff} = useLiff();

  useEffect(() => {
    if (!isLoggedIn) return;

    const idToken = liff.getDecodedIDToken();
    setDecodedIDToken(idToken)
  }, [liff, isLoggedIn]);
  return (
    <>
      <div>isLoggedIn {isLoggedIn.toString()}</div>
      <div>sub {decodedIDToken.sub}</div>
    </>
  );
}

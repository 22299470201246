import React, { useEffect, useState, useRef } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import TopLogo from "../../components/layout/TopLogo";
import { useLiff } from "react-liff";
import helper from "../../functions/helper";
import moment from "moment/moment";
import { SearchId } from "../../services/SearchId";
import {
  SearchQuizLeaderboard,
  SearchMyQuizLeaderboard,
} from "../../services/QuizMania";
import { esES } from "@mui/x-date-pickers";

export default function QuizManiaRank() {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const quarter = searchParams.get("quarter");
  const year = searchParams.get("year");

  const [isMember, setIsMember] = useState(true);
  const [memberId, setMemberId] = useState(true);

  const [allRankLeaderboard, setAllRankLeaderboard] = React.useState([]);
  const [myRankLeaderboard, setMyRankLeaderboard] = React.useState({});
  const [topRankLeaderboard, setTopRankLeaderboard] = React.useState([]);
  const [underRankLeaderboard, setUnderRankLeaderboard] = React.useState([]);

  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken ? liff.getDecodedIDToken().sub : null;

  const quizId = searchParams.get("quiz");
  // console.log("LineId >>", lineId);

  console.log("myRankLeaderboard >>>", myRankLeaderboard);
  console.log("topRankLeaderboard >>>", topRankLeaderboard);
  console.log("underRankLeaderboard >>>", underRankLeaderboard);
  console.log("isMember >>>", isMember);

  // const startTime = moment(data.startTime);
  // const endTime = moment(data.toTime);
  // const totalTiem = endTime.diff(startTime, "second");

  // เช็ค Line ID ในระบบ
  useEffect(() => {
    getSearchId();
    fetchQuizLeaderboard();
    fetchMyQuizLeaderboard();
  }, []);

  async function getSearchId() {
    return await SearchId({
      params: { lineId: lineId || null },
    })
      .then(async (response) => {
        if (!response.data.body.memberId) {
          setMemberId(null);
        } else {
          setMemberId(response.data.body.memberId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const checkYou = (NEX_ID = "", MY_NEX_ID = "") => {
    if (NEX_ID == MY_NEX_ID) {
      return <span>(You)</span>;
    }

    return <></>;
  };

  const myRankNumber = (ALL_LEADER = [], MY_LEADER = {}) => {
    ALL_LEADER = ALL_LEADER.map((item, i) => {
      item.no = `#${i + 1}`;
      return item;
    });

    const myRankNo =
      ALL_LEADER.find((item) => item.memberCode == MY_LEADER.memberCode)?.no ??
      "";
    return myRankNo;
  };

  const fetchQuizLeaderboard = async () => {
    try {
      const payload = {
        params: { year: year, lineId: lineId, quarter: quarter },
      };
      const resData = await getQuizLeaderboard(payload);
      setAllRankLeaderboard(resData.result);
      setTopRankLeaderboard(mappingTopRank(resData.result));
      setUnderRankLeaderboard(mappingUnderRank(resData.result));
    } catch (err) {
      console.log(err);
    }
  };

  const getQuizLeaderboard = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await SearchQuizLeaderboard(payload);
      if (data.resultCode === 20000) {
        const resData = data.resultData;

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  const fetchMyQuizLeaderboard = async () => {
    try {
      const payload = {
        params: { year: year, lineId: lineId, quarter: quarter },
      };
      const resData = await getMyQuizLeaderboard(payload);
      if (resData.status) {
        setMyRankLeaderboard(mappingMyRank(resData.result));
      } else {
        setIsMember(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getMyQuizLeaderboard = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await SearchMyQuizLeaderboard(payload);
      if (data.resultCode === 20000) {
        const resData = data.resultData;
        _return.status = true;
        _return.result = resData;
      } else if (data.resultCode === 40300) {
        setIsMember(false);
        _return.status = false;
      } else {
        _return.status = false;
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  const mappingTopRank = (array) => {
    let arrayTopRank = [];
    if (array.length > 3) {
      for (let i = 0; i <= 2; i++) {
        let topRank = {
          memberCode: array[i].memberCode,
          sumGetPoint: array[i].sumGetPoint,
          sumTotalTime: parseInt(array[i].sumTotalTime),
          tierRank:
            array[i].tier === "BRONZE"
              ? "bronze"
              : array[i].tier === "SILVER"
              ? "silver"
              : array[i].tier === "GOLD"
              ? "gold"
              : "",
        };
        arrayTopRank.push({ ...topRank });
      }
    } else {
      for (let i = 0; i < array.length; i++) {
        let topRank = {
          memberCode: array[i].memberCode,
          sumGetPoint: array[i].sumGetPoint,
          sumTotalTime: parseInt(array[i].sumTotalTime),
          tierRank:
            array[i].tier === "BRONZE"
              ? "bronze"
              : array[i].tier === "SILVER"
              ? "silver"
              : array[i].tier === "GOLD"
              ? "gold"
              : "",
        };
        arrayTopRank.push({ ...topRank });
      }
    }
    return arrayTopRank;
  };

  const mappingUnderRank = (array) => {
    let arrayunderRank = [];
    for (let i = 3; i < array.length; i++) {
      let underRank = {
        memberCode: array[i].memberCode,
        sumGetPoint: array[i].sumGetPoint,
        sumTotalTime: parseInt(array[i].sumTotalTime),
        tierRank:
          array[i].tier === "BRONZE"
            ? "bronze"
            : array[i].tier === "SILVER"
            ? "silver"
            : array[i].tier === "GOLD"
            ? "gold"
            : "",
      };
      arrayunderRank.push({ ...underRank });
    }
    return arrayunderRank;
  };

  const mappingMyRank = (data) => {
    let myRank = {
      memberCode: data.memberCode,
      sumGetPoint: data.sumGetPoint,
      sumTotalTime: parseInt(data.sumTotalTime),
      tierRank:
        data.tier === "BRONZE"
          ? "bronze"
          : data.tier === "SILVER"
          ? "silver"
          : data.tier === "GOLD"
          ? "gold"
          : "",
    };

    return myRank;
  };

  return (
    <div id="questionnaireRankPage">
      <TopLogo />
      <div className="content gradientBg gradientBgParty ">
        <div className="pRankMain">
          <div className="bWarp">
            <div className="bRankHead">
              <div className="bTitleRank">
                <i></i>
              </div>
              <div className="bWinner123">
                {topRankLeaderboard[1] ? (
                  <div className="bWinnerItem">
                    <div className="bNo">
                      <i className="n2"></i>
                    </div>
                    <div className="bWdetail">
                      <p className="tName">
                        {topRankLeaderboard[1].memberCode}
                        {checkYou(
                          topRankLeaderboard[1].memberCode,
                          myRankLeaderboard.memberCode
                        )}
                      </p>
                      <div className="bRank">
                        <i className={topRankLeaderboard[1].tierRank}></i>
                      </div>
                      <p className="tPoint">
                        {helper.toNumberWithCommas(
                          topRankLeaderboard[1].sumGetPoint
                        )}{" "}
                        Pts.
                      </p>
                      <p className="tTime">
                        {helper.secondsToHMS(
                          topRankLeaderboard[1].sumTotalTime
                        )}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="bWinnerItem">
                    <div className="bNo">
                      <i className="n2"></i>
                    </div>
                    <div className="bWdetail">
                      <p className="tName">{"No Rank"}</p>
                      <div className="bRank">
                        <i className="bronze"></i>
                      </div>
                      <p className="tPoint">{0} Pts.</p>
                      <p className="tTime">{helper.secondsToHMS(0)}</p>
                    </div>
                  </div>
                )}

                {topRankLeaderboard[0] ? (
                  <div className="bWinnerItem">
                    <div className="bNo">
                      <i className="n1"></i>
                    </div>
                    <div className="bWdetail">
                      <p className="tName n1">
                        {topRankLeaderboard[0].memberCode}
                        {checkYou(
                          topRankLeaderboard[0].memberCode,
                          myRankLeaderboard.memberCode
                        )}
                      </p>
                      <div className="bRank">
                        <i className={topRankLeaderboard[0].tierRank}></i>
                      </div>
                      <p className="tPoint">
                        {helper.toNumberWithCommas(
                          topRankLeaderboard[0].sumGetPoint
                        )}{" "}
                        Pts.
                      </p>
                      <p className="tTime">
                        {helper.secondsToHMS(
                          topRankLeaderboard[0].sumTotalTime
                        )}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="bWinnerItem">
                    <div className="bNo">
                      <i className="n1"></i>
                    </div>
                    <div className="bWdetail">
                      <p className="tName n1">{"No Rank"}</p>
                      <div className="bRank">
                        <i className="bronze"></i>
                      </div>
                      <p className="tPoint">{0} Pts.</p>
                      <p className="tTime">{helper.secondsToHMS(0)}</p>
                    </div>
                  </div>
                )}

                {topRankLeaderboard[2] ? (
                  <div className="bWinnerItem">
                    <div className="bNo">
                      <i className="n3"></i>
                    </div>
                    <div className="bWdetail">
                      <p className="tName">
                        {topRankLeaderboard[2].memberCode}
                        {checkYou(
                          topRankLeaderboard[2].memberCode,
                          myRankLeaderboard.memberCode
                        )}
                      </p>
                      <div className="bRank">
                        <i className={topRankLeaderboard[2].tierRank}></i>
                      </div>
                      <p className="tPoint">
                        {helper.toNumberWithCommas(
                          topRankLeaderboard[2].sumGetPoint
                        )}{" "}
                        Pts.
                      </p>
                      <p className="tTime">
                        {helper.secondsToHMS(
                          topRankLeaderboard[2].sumTotalTime
                        )}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="bWinnerItem">
                    <div className="bNo">
                      <i className="n3"></i>
                    </div>
                    <div className="bWdetail">
                      <p className="tName n3">{"No Rank"}</p>
                      <div className="bRank">
                        <i className="bronze"></i>
                      </div>
                      <p className="tPoint">{0} Pts.</p>
                      <p className="tTime">{helper.secondsToHMS(0)}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="bRankContent">
              <div className="bRank4up">
                {underRankLeaderboard.map((item, i) => {
                  return (
                    <div className="bItem" key={i}>
                      <div className="bLeft">
                        <p className="tRunning">#{i + 4}</p>
                        <div className="bNameRank">
                          <p>
                            {item.memberCode}
                            {checkYou(
                              item.memberCode,
                              myRankLeaderboard.memberCode
                            )}
                          </p>

                          {/* <i className="gold"></i> */}
                          <i className={item.tierRank}></i>
                          {/* <i className="bronze"></i> */}
                        </div>
                      </div>
                      <div className="bCenter">
                        <p>{helper.secondsToHMS(item.sumTotalTime)}</p>
                      </div>
                      <div className="bRight">
                        <p>
                          {helper.toNumberWithCommas(item.sumGetPoint)} Pts.
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
              {isMember ? (
                <div className="bRank4up you">
                  <div className="bItem">
                    <div className="bLeft">
                      <p className="tRunning">
                        {myRankNumber(allRankLeaderboard, myRankLeaderboard)}
                      </p>
                      <div className="bNameRank">
                        <p>
                          {myRankLeaderboard.memberCode}{" "}
                          {checkYou(
                            myRankLeaderboard.memberCode,
                            myRankLeaderboard.memberCode
                          )}
                        </p>
                        {/* <i className="gold"></i> */}
                        {/* <i className="sliver"></i> */}
                        <i className="bronze"></i>
                      </div>
                    </div>
                    <div className="bCenter">
                      <p>
                        {helper.secondsToHMS(myRankLeaderboard.sumTotalTime)}
                      </p>
                    </div>
                    <div className="bRight">
                      <p> {myRankLeaderboard.sumGetPoint} Pts.</p>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          {isMember ? (
            <div className="bFooterBar">
              <div className="bContent">
                <button
                  className="btn _outline"
                  onClick={() => navigate("/redeem-gift-member")}
                >
                  ไปยัง Privilege
                </button>
                <button
                  className="btn"
                  onClick={() => navigate(`/quiz-mania?quiz=${quizId}`)}
                >
                  เสร็จสิ้น
                </button>
              </div>
            </div>
          ) : (
            <div className="bFooterBar">
              <div className="bContent">
                <button
                  className="btn"
                  onClick={() => navigate(`/quiz-mania?quiz=${quizId}`)}
                >
                  เสร็จสิ้น
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import liff from "@line/liff"
import helper from "../../functions/helper";
import { CreateArticlePoint } from "../../services/Article";

export default function LiffBasic() {
  const [searchParams] = useSearchParams();
  const [ lineId, setLineId] = useState(null)
  const qWeb = searchParams.get('web')

  const getLineLiff = async () => {
    const path = window.location.pathname
    const liffId = helper.getLiffIdByENV(path, 'dev')
    await liff.init({ liffId: liffId })
    if (!liff.isLoggedIn()){
      const destinationUrl = window.location.href
      // alert('destinationUrl >>' + destinationUrl)
      liff.login({ redirectUri: destinationUrl })
    }
    const _lineId = liff.getDecodedIDToken().sub
    window.localStorage.removeItem('sub')
    window.localStorage.setItem('sub', _lineId)
    setLineId(_lineId)
  }

  useEffect(() => {
    getLineLiff()
  }, [])

  useEffect(() => {
    const reUrl = qWeb
    if (lineId) {
      createArticlePoint(lineId, reUrl)
    }
  }, [lineId])

  async function createArticlePoint(lineId, url) {
    try {
      const { data } = await CreateArticlePoint({ data: { url, lineId } });
      console.log("resData.data >>", data);
      if (liff?.openWindow) {
        if (liff.getOS() == 'web') {
          window.location.replace(url)
        } else {
          liff.openWindow({ url: url, external: false });
        }
      }
    } catch (error) {
      alert('มีบางอย่างผิดพลาด')
      console.log("create pet error", error);
    }
  }
  return (
    <div>Loading...</div>
  )
  
}
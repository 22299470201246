import React, { useEffect, useState, useCallback } from "react";
import Grid from '@mui/material/Grid';
import dayjs from "dayjs";
import "swiper/css";
import "swiper/css/pagination";
import gift from "../../assets/images/icon/gift.svg";
import qrCode from "../../assets/images/icon/qr-code.svg";
import photo from "../../assets/images/icon/photo.svg";
import excel from "../../assets/images/my-point-shop/excel.png";
import TopBanner from "../../components/layout/TopBanner";
import { SearchId } from "../../services/SearchId";
import { SearchPoint } from "../../services/MyPoint";
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { useLiff } from "react-liff";
import SecondaryButton from '../../components/button/SecondaryButton';
import MemberCard from '../../components/card/MemberCard';
import { useDispatch, useSelector } from 'react-redux';
import { setMyPoint } from '../../features/MyPointSlice';
import PrimaryButton from '../../components/button/PrimaryButton';
import { useNavigate, useLocation } from 'react-router-dom';
import TopLogo from '../../components/layout/TopLogo';
import checkmark from "../../assets/images/checkmark.png";
import { SearchAdminConfig } from "../../services/Dropdown";
import { SearchQRPointShopHistories } from "../../services/MyPoint";
import helper from '../../functions/helper';
import expireIcon from "../../assets/images/expire-icon.png";
import mobile from '../../assets/images/mobile.png';
import scanQR from "../../assets/images/scanQR.png";
import ShopCard from "../../components/card/ShopCard";
import {
  SearchShopProfile,
  SearchEmployeeDetail,
  PostDeleteEmployee
} from "../../services/MemberCardOwner";
import CalendarTab from "../../components/general/CalendarTab";
import { SearchPointExpire } from "../../services/MyPoint";
import InfiniteScroll from 'react-infinite-scroller';

const initCurrentDate = dayjs().format('YYYY-MM-DD');

export default function MyPointMain() {
  const store = useSelector((state) => state.myPoint)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [member, setMember] = useState({})
  const [clinicPetShopId, setClinicPetShopId] = React.useState({});
  const [clinicPetShop, setClinicPetShop] = React.useState({});
  const [shopPoint, setShopPoint] = React.useState(0);
  const [employee, setEmployee] = React.useState(null);
  const [employees, setEmployees] = React.useState(null);
  const [isPhoto, setIsPhoto] = useState(true)
  const [shopName, setShopName] = useState('-')
  const [page, setPage] = React.useState("scan");



  // ประวัติสแกน
  const [historyScan, setHistoryScan] = React.useState([]);
  const lazyLoadItem = 4;
  const [nextPageScan, setNextPageScan] = useState(null);
  const [itemsScan, setItemsScan] = useState([]);
  const [fetchingScan, setFetchingScan] = useState(false);
  // คะแนนหมดอายุ
  const [currentDate, setCurrentDate] = React.useState(initCurrentDate);
  const [historyExpire, setHistoryExpire] = React.useState([]);
  const [nextPageExpire, setNextPageExpire] = useState(null);
  const [itemsExpire, setItemsExpire] = useState([]);
  const [fetchingExpire, setFetchingExpire] = useState(false);



  const [historyExpireCount, setHistoryExpireCount] = React.useState(0);
  const [expLoadMore, setExpLoadMore] = React.useState({ limit: 3, offset: 0 });

  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;


  React.useEffect(() => {
    getSearchId()
  }, []);

  async function getSearchId() {
    return await SearchId({
      params: { lineId: lineId || null },
    })
      .then(async (response) => {
        if (!response.data.body.employeeId) {
          window.location.replace("/register");
        } else {
          getShopProfile(response.data.body.employeeId || "194", response.data.body.clinicPetShopId || "3216");
          getEmployeeDetail(response.data.body.clinicPetShopId || "3216");
          getScanData(response.data.body.clinicPetShopId)
          getExpireData(response.data.body.clinicPetShopId, currentDate)
          const { clinicPetShop } = await getSearchPoint({
            clinicPetShopId: response.data.body.clinicPetShopId,
            employeeId: response.data.body.employeeId,
            limit: 10,
            offset: 0,
          });
          // console.log('res >>', res)
          setShopPoint(clinicPetShop.currentPoint)
          setClinicPetShopId(response.data.body.clinicPetShopId)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getSearchPoint(payload = {}) {
    const { data } = await SearchPoint({ params: payload })
    if (+data['resultCode'] === 20000) {
      return data.body || {};
    } else {
      return {};
    }
  }

  async function getShopProfile(employeeId, clinicPetShopId) {
    return await SearchShopProfile({
      params: { lineId: lineId || null },
      employeeId: employeeId,
      clinicPetShopId: clinicPetShopId
    })
      .then(async (response) => {
        setClinicPetShop(response.data.body.clinicPetShop);
        setEmployee(response.data.body.employee);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getEmployeeDetail(clinicPetShopId) {
    return await SearchEmployeeDetail({
      params: { lineId: lineId || null },
      clinicPetShopId: clinicPetShopId,
    })
      .then(async (response) => {
        if (response.data.resultCode === 20000) {
          setEmployees(response.data.body);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getScanData(_clinicPetShopId) {
    return new Promise((resolve, reject) => {
        SearchQRPointShopHistories({
            params: { clinicPetShopId: _clinicPetShopId, offset: 0, limit: 5 },
        })
        .then(async (res) => {
            if (res.data.resultCode === 20000) {
              console.log('res.data.body getScanData >>', res.data.body)
              setHistoryScan(res.data.body)
              setNextPageScan('0');
            } else {
              setHistoryScan([])
              setNextPageScan('0');
            }
        })
        .catch((error) => {
            setHistoryScan([])
            setNextPageScan('0');
            console.log(error);
        });
    })
  }

  async function getExpireData(_clinicPetShop, _currentDate) {
    _clinicPetShop = _clinicPetShop ?? clinicPetShop
    const { limit, offset } = expLoadMore
    const yearMonth = dayjs(_currentDate).format('YYYY-MM')
    return await SearchPointExpire({ params: { clinicPetShopId: _clinicPetShop, month: yearMonth } })
    .then(async (response) => {
        setHistoryExpire(response.data?.body ?? []);
        setHistoryExpireCount(response.data?.rowCount ?? 0)
        setNextPageExpire('0')
      })
      .catch((error) => {
        setNextPageExpire('0')
        console.log(error);
    });
  }

  function ItemScan(item, i, arr) {
    return (
      <div id="single-card" key={item.id}  className="w-full">
         {item.event.map(item2 => (
          <>
            <div>
                <div id="section1" className="py-[6px]">
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <p className="text-gray text-[16px] leading-[19.2px] px-[16px]">{dayjs(item.createdDate,'YYYY-MM-DD').format('DD/MM/YYYY')}</p>
                        </Grid>
                    </Grid>
                </div>
                <hr className="border-lightGray"/>
                <div className="px-[16px] pb-[16px]">
                  {/* {item.event.map(item2 => ( */}
                      <>
                          <div id="section2" className="py-[12px]">
                              <Grid container spacing={2}>
                                  <Grid item xs={2}>
                                      <div className="bg-lightGray rounded-[4px] w-12 h-12 flex justify-center items-center">
                                          <img src={mobile} alt="" width="21px" height="29px" />
                                      </div>
                                  </Grid>
                                  <Grid item xs={10} className="text-left flex-1">
                                      <p className="text-black text-[20px] leading-[24px] pl-[8px]"><span className="text-gray text-[16px]">From : </span>{item2.member?.memberCode}</p>
                                      {/* <p className="text-gray text-[16px] leading-[17.6px] pl-[8px]">{item2.clinicPetShop?.shopNo}</p> */}
                                  </Grid>
                              </Grid>
                          </div>
                          <hr className="border-lightGray" />
                          <div id="section3" className="pt-[20px] pb-[6px] text-[16px] leading-[0px]">
                              <Grid container spacing={2}>
                                  <Grid item xs={5}>
                                      <p className="text-gray">รหัส QR Code</p>
                                  </Grid>
                                  <Grid item xs={7} className="text-end">
                                      <p className="text-black">{item2.qrCode?.qrCode}</p>
                                  </Grid>

                                  <Grid item xs={5}>
                                      <p className="text-gray">NexCoin ที่ได้รับ</p>
                                  </Grid>
                                  <Grid item xs={7} className="text-end">
                                      <p className="text-black">{item2.earnBurnPoint} คะแนน</p>
                                  </Grid>

                                  <Grid item xs={5}>
                                      <p className="text-gray">คะแนนจะหมดอายุ</p>
                                  </Grid>
                                  <Grid item xs={7} className="text-end">
                                      {item2.expireDate &&<p className="text-black">{dayjs(item2.expireDate).format('DD/MM/YYYY')}</p>}
                                  </Grid>
                              </Grid>
                          </div>
                      </>
                  {/* ))} */}
                </div>
            </div>
            <div className={`historyList ${arr.length === i + 1}`}></div>
          </>
          ))}
      </div>
    )
  }

  function ItemExpire(item, i, arr) {
    return (
      <div  className="flex justify-between mb-[4px] text-gray" key={item.id} >
        <p className="text-[16px] leading-[110%] text-gray">{item.expire ? dayjs(item.expire).format('DD/MM/YYYY') : '-'}</p>
        <p className="text-[16px] leading-[110%] text-gray">{item.expiredPoints} คะแนน</p>
      </div>
    )
  }

  const selectDCalendar = (event) => {
    setCurrentDate(event.dateIso)
    getExpireData(clinicPetShopId, event.dateIso)
  }

  const fetchItemsScan = useCallback(() => {
    console.log('fetchItemsScan Start!')
      if (itemsScan.length < historyScan.length) {
        console.log('fetchingScan', fetchingScan);
        if (fetchingScan) {
          return;
        }
        setFetchingScan(true);
        setTimeout(() => {
          const sliceItems = historyScan.slice(+nextPageScan, +nextPageScan + lazyLoadItem);
          setItemsScan([...itemsScan, ...sliceItems]);
        }, 1000)
      } else {
        setNextPageScan(null);
      }
    },
    [fetchingScan, historyScan, itemsScan, nextPageScan]
  );

  const fetchItemsExpire = useCallback(() => {
    console.log('fetchItemsExpire Start!')
      if (itemsExpire.length < historyExpire.length) {
        console.log('fetchingExpire', fetchingExpire);
        if (fetchingExpire) {
          return;
        }
        setFetchingExpire(true);
        setTimeout(() => {
          const sliceItems = historyExpire.slice(+nextPageExpire, +nextPageExpire + lazyLoadItem);
          setItemsExpire([...itemsExpire, ...sliceItems]);
        }, 1000)
      } else {
        setNextPageExpire(null);
      }
    },
    [fetchingExpire, historyExpire, itemsExpire, nextPageExpire]
  );

  const hasMoreItemsScan = !!nextPageScan;
  const hasMoreItemsExpire = !!nextPageExpire;

  const loaderFirstLoad = (
    <div className="absolute w-full h-full flex justify-center items-center">
      <div key="loader" className="loader text-center text-[20px] text-gray">
        Loading ...
      </div>
    </div>
  );

  const loaderFirstLoad2 = (
    <div className="absolute w-full h-full flex justify-center items-center top-[0px] left-[0px]">
      <div key="loader" className="loader text-center text-[20px] text-gray">
        Loading ...
      </div>
    </div>
  );

  const loader = (
    <div key="loader" className="loader text-center text-[20px] text-gray">
      Loading ...
    </div>
  );
  const sumPointExpire = historyExpire.reduce((pre, cur)=> pre = pre + cur.expiredPoints ?? 0, 0)
  return (<React.Fragment>
    <div id="my-point-page">
      <TopLogo />
      <TopBanner type="myPointShop" />
      <div id="my-point" className="gradientBg mt-[-20px] px-[20px]">
        {/* <MemberCard data={member} /> */}
        <ShopCard data={clinicPetShop} />
        <div className="py-[16px] text-white">
          <div className="flex justify-between text-[20px]">
            <div className="leading-[120%]">NexCoin สะสมรวม</div>
            <div>
              <span className="text-[24px] font-[600] leading-[120%]">{helper.numberWithCommas(shopPoint)}</span>
              &nbsp;คะแนน
            </div>
          </div>
          {/* <div className="flex justify-between text-[16px] mt-[-5px] mb-[12px]">
            <div className="">
              คะแนนของท่านจะหมดอายุในวันที่ 31/12/{dayjs().year()}
            </div>
            <div>
              <span className="text-[16px] font-[600]">{shopPoint}</span>
              &nbsp;คะแนน
            </div>
          </div> */}
          <div className="py-3">
            <hr className="opacity-50" />
          </div>
          <div className="flex flex-col gap-[10px] mt-[0px] mb-[0px]">
            {/* <SecondaryButton variant="outlined" className="w-full !border-[0] !bg-[#E1E8F2]" onClick={() => navigate('/my-point-export')}>
              <img src={excel} alt="icon" className="mt-[-2px]" />
              ดาวน์โหลดประวัติการสะสม NexCoin 
            </SecondaryButton> */}
            <PrimaryButton variant="outlined" className="w-full !border-[0] !bg-[#082E68]/[0.24]" onClick={() => navigate('/redeem-gift')}>
              <img src={gift} alt="icon" className="mt-[-2px]" />
              ใช้ NexCoin แลกของรางวัลที่นี่
            </PrimaryButton>
            <p className="text-[24px]">ประวัติการได้รับคะแนนย้อนหลัง</p>
          </div>
        </div>
      </div>
      <div className="bg-white rounded-t-[10px] mx-4 mt-[-15px] px-[0px]">
        <div id="tab-select">
          <div className="flex items-center">
              <p
                  onClick={() => setPage("scan")}
                  className={
                      `w-full text-[18px] text-gray text-center py-[9px] border-b-[1px] border-lightGray ` +
                      (page === "scan" && "!text-blue !border-b-[4px] !border-blue")
                  }
              >
                  จากการสแกน
              </p>
              <p
                  onClick={() => setPage("expire")}
                  className={
                      `w-full text-[18px] text-gray text-center py-[9px] border-b-[1px] border-lightGray ` +
                      (page === "expire" &&
                          "!text-blue !border-b-[4px] !border-blue")
                  }
              >
                  คะแนนหมดอายุ
              </p>
          </div>
        </div>
        <div id="item-card">
          {page === "scan" ? (
            <>
              {
                historyScan.length > 0 ?
                <div className="relative" style={{ minHeight: "350px"}}>
                  <InfiniteScroll
                    loadMore={fetchItemsScan}
                    hasMore={hasMoreItemsScan}
                    loader={itemsScan.length == 0 ? loaderFirstLoad : loader}
                  >
                    <div className="">
                      {itemsScan.map((item, i, arr) => (
                        <div>
                          {ItemScan(item, i, arr)}
                        </div>
                      ))}
                    </div>
                  </InfiniteScroll>
                </div>
                :
                <div className="relative flex justify-center items-center" style={{ minHeight: "350px"}}>
                  <div className="flex justify-center items-center min-h-[147px] h-[60%]">
                      <div className="flex flex-col justify-center text-center">
                          <img className="mb-[23px] mx-auto" src={scanQR} alt="location" style={{ display: 'inline', width: 52 }} />
                          <p className="text-darkGray text-[24px] leading-[120%]">ยังไม่มีประวัติการถูกแสกน</p>
                          <p className="text-gray text-[16px] leading-[100%]">กรุณากลับมาเช็กข้อมูลอีกครั้งในโอกาสหน้า</p>
                          {/* <p className="text-gray text-[16px] leading-[100%]">หรือติดตามข่าวสารจาก Line @spectrafordog</p> */}
                      </div>
                  </div>
                </div>
              }
            </>
          ) : (
            <>
            <CalendarTab value={currentDate} onSelect={selectDCalendar}/>
            {
              historyExpire.length > 0 ?
              <div className="relative" style={{ minHeight: "350px"}}>
                <InfiniteScroll
                  loadMore={fetchItemsExpire}
                  hasMore={hasMoreItemsExpire}
                  loader={itemsExpire.length == 0 ? loaderFirstLoad2 : loader}
                >
                  <div className="">
                    <div className="flex justify-between bg-[#F7F7F7] px-[17px] py-[8px]">
                        <p className="text-[16px] text-darkGray leading-[120%]">คะแนนรวมที่หมดอายุ</p>
                        <p className="text-[16px] text-darkGray2 font-bold leading-[120%]">{sumPointExpire} คะแนน</p>
                    </div>
                    <div className="pt-[12px] pb-[18px] px-[17px]">
                      {
                        itemsExpire.map((item, i, arr) => {
                          return (
                            <div>
                              {ItemExpire(item, i, arr)}
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </InfiniteScroll>
              </div>
              :
              <div className="relative flex justify-center items-center" style={{ minHeight: "350px"}}>
                <div className="flex justify-center items-center min-h-[147px] h-[60%]">
                    <div className="flex flex-col justify-center text-center">
                        <img className="mb-[23px] mx-auto" src={scanQR} alt="location" style={{ display: 'inline', width: 52 }} />
                        <p className="text-darkGray text-[24px] leading-[120%]">ยังไม่มีประวัติคะแนนหมดอายุ</p>
                        <p className="text-gray text-[16px] leading-[100%]">กรุณากลับมาเช็กคะแนนที่หมดอายุของท่านอีกครั้งในโอกาสหน้า</p>
                        {/* <img className="mb-[23px] mx-auto" src={scanQR} alt="location" style={{ display: 'inline', width: 52 }} />
                        <p className="text-darkGray text-[24px] leading-[120%]">ยังไม่มีประวัติการถูกแสกน</p>
                        <p className="text-gray text-[16px] leading-[100%]">กรุณากลับมาเช็กข้อมูลอีกครั้งในโอกาสหน้า</p> */}
                        {/* <p className="text-gray text-[16px] leading-[100%]">หรือติดตามข่าวสารจาก Line @spectrafordog</p> */}
                    </div>
                </div>
              </div>
            }
            </>
          )
          }
        </div>
      </div>
    </div>
  </React.Fragment>
  );
}

import { get, post } from "./Api";

export function SearchPetDaily({ params, keyword }) {
  return get({ url: `/searchPetDiary`, params });
}

export function SearchPetDiaryById({ params, keyword }) {
  return get({ url: `/searchPetDiaryById`, params });
}

export function CreatePetDiary({ data }) {
  return post({ url: `/createPetDiary`, data });
}

export function DeletePetDiary({ data }) {
  return post({ url: `/deletePetDiary`, data });
}
import React, { useEffect } from "react";
import Checkmark from "../../assets/images/checkmark.png";
import PrimaryButton from "../../components/button/PrimaryButton";
import PopupQuestionnaireSuccess from "../../components/popup/PopupQuestionnaireSuccess";
import TopLogo from "../../components/layout/TopLogo";
import { useLocation, useNavigate } from "react-router-dom";

export default function QuestionnaireSuccess(props) {
  const navigate = useNavigate();
  return (
    <>
      <TopLogo />
      {/* {JSON.stringify(store)} */}
      <div
        className="flex flex-col "
        style={{
          background:
            "linear-gradient(152.04deg, #0A4197 -0.97%, #209FED 81.57%)",
          minHeight: "calc(100vh - 60px)",
          padding: "20px",
          paddingBottom: "36px",
        }}
      >
        <div className="flex-1">
          <img
            className="mx-auto mt-[18px]"
            src={Checkmark}
            alt="logo"
            style={{ maxWidth: "52px" }}
          />
          <div className="text-white text-center text-[36px] mt-[10px] leading-[100%]">
            ส่งอีเมลสำเร็จแล้ว
          </div>
          <div className="text-white text-center text-[24px] leading-[100%]">
            ไฟล์ excel ประวัติการสะสม NexCoin ถูกส่งไปยัง
          </div>
          <div className="text-white text-center text-[24px] leading-[100%]">
            {props.email}
          </div>
        </div>
        <div className="text-center">
          <div className="text-white text-center text-[20px] leading-[100%]">
            หากไม่พบอีเมล์ กรุณาตรวจสอบ
          </div>
          <div className="text-white text-center text-[20px] leading-[100%]">
            ในกล่อง Spam หรือ Junk mail ของคุณ
          </div>
          <div className="mt-[20px]">
            <PrimaryButton
              variant="contained"
              className="w-[70%] !bg-[#082e683d]"
              onClick={() => {
                navigate("/my-point-shop");
              }}
            >
              กลับไปหน้าเช็กคะแนน
            </PrimaryButton>
          </div>
        </div>
      </div>
    </>
  );
}

// fonts
import "./assets/fonts/DB-Helvethaica-X-Bd-v3.2.ttf";
import "./assets/fonts/DB-Helvethaica-X-Blk-v3.2.ttf";
import "./assets/fonts/DB-Helvethaica-X-It-v3.2.ttf";
import "./assets/fonts/DB-Helvethaica-X-Li-v3.2.ttf";
import "./assets/fonts/DB-Helvethaica-X-Med-v3.2.ttf";
import "./assets/fonts/DB-Helvethaica-X-Thin-v3.2.ttf";
import "./assets/fonts/DB-Helvethaica-X-UlLi-v3.2.ttf";
import "./assets/fonts/DB-Helvethaica-X-v3.2.ttf";
import "./assets/fonts/Kanit-BoldItalic.ttf";
import "./assets/fonts/Kanit-Medium.ttf";

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import AppNotLine from "./AppNotLine";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./services/Store";
import { LiffProvider } from "react-liff";
import helper from "./functions/helper";
import { Logger } from "./services/Logger";
// const liffId = '1656812094-8P7zvOdq'; // dev
// const liffId = '1656777670-qeX53wLV'; // stg
// const liffId = '1656777670-qeX53wLV'; // prod

//  EX. /external?quiz=cXVpemRvZz0x
if (`${window.location.pathname}`.startsWith("/external")) {
  let search = window.location.search.substring(1);
  let query = JSON.parse(
    '{"' +
      decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
  if (query.quiz) {
    let liffId = null;
    if (query.type == "quizMania") {
      liffId = helper.getLiffIdByENV("/quiz-mania", "dev");
    } else {
      liffId = helper.getLiffIdByENV("/questionnaire", "dev");
    }

    const quizNO = query.quiz;

    if (helper.mobileCheck()) {
      // old liff url ( OPEN WITH APP LINE )
      // window.location = `line://app/${liffId}?quiz=${quizNO}`;
      const redirect = `line://app/${liffId}?quiz=${quizNO}`;
      // alert('Mobile Redirect aaa' + redirect)
      Logger.normal(`Mobile Redirect ${redirect}`);
      window.location.replace(redirect);
    } else {
      // new liff url
      // window.location = `https://liff.line.me/${liffId}?quiz=${quizNO}`;
      const redirect = `https://liff.line.me/${liffId}?quiz=${quizNO}`;
      // alert('Web Redirect bbb' + redirect)
      Logger.normal(`Web Redirect ${redirect}`);
      window.location.replace(redirect);
    }
  }
}

function fnHandleLiff(liff) {
  console.log("error", liff.error);
}
// alert(window.location.pathname)
const path = window.location.pathname;
const liffId = helper.getLiffIdByENV(path, "dev");
// alert(liffId)

let isLoginBrowser = true;
const RouteNotAuth = [
  "/otp",
  "/liff-basic",
  "/questionnaire",
  "/asset",
  "/article-point",
];
const root = ReactDOM.createRoot(document.getElementById("root"));

if (RouteNotAuth.includes(window.location.pathname)) {
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <AppNotLine />
      </BrowserRouter>
    </Provider>
  );
} else {
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <LiffProvider
          liffId={liffId}
          withLoginOnExternalBrowser={isLoginBrowser}
          callback={fnHandleLiff}
        >
          <App />
        </LiffProvider>
      </BrowserRouter>
    </Provider>
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

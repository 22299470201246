import React, { useEffect, useState } from "react";
import TopBanner from "../../components/layout/TopBanner";
import { SearchId } from "../../services/SearchId";
import { useLiff } from "react-liff";
import TopLogo from "../../components/layout/TopLogo";
import { useNavigate } from "react-router-dom";
import CustomPopup from "../../components/popup/CustomPopup";
import { SearchCountRecommendStamp } from "../../services/Dogbook";

const maxStamp = 10;

export default function InviteFriend() {
  const navigate = useNavigate();

  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  // const [myStamp, setMyStamp] = useState([...Array(12)]);
  const [myStamp, setMyStamp] = useState(0);
  const [myMemberCode, seyMyMemberCode] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    getSearchByLineId();
    //
  }, []);

  const getSearchByLineId = async () => {
    const memberData = await getSearchId();
    if (!memberData.result.memberId) {
      navigate("/register");
    } else {
      const recommendStampData = await getCountRecommendStamp(
        memberData.result.memberId
      );
      if (recommendStampData.status) {
        setMyStamp(recommendStampData.result.counts);
        seyMyMemberCode(recommendStampData.result.memberCode);
      }
    }
  };

  const getSearchId = async () => {
    let _return = {
      status: false,
      result: null,
    };
    try {
      const { data } = await SearchId({ params: { lineId: lineId || null } });
      if (data.resultCode === 20000) {
        const resData = data.body;
        console.log("resData >>", resData);
        _return.status = true;
        _return.result = resData;
      }
    } catch (error) {
      console.log(error);
    }
    return _return;
  };

  const getCountRecommendStamp = async (id) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await SearchCountRecommendStamp({ member_id: id });
      if (data.resultCode === 20000) {
        const resData = data.resultData;
        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  const onShareLink = async () => {
    try {
      const result = await liff.shareTargetPicker(
        [
          {
            type: "text",
            text: "line://ti/p/@spectrafordog",
          },
        ],
        {
          isMultiple: true, // ส่งที่ได้หลายคน
        }
      );

      if (result) {
        // แชร์สำเร็จ
        console.log(`[${result.status}] Message sent!`);
        setOpenPopup(true);
      } else {
        const [majorVer, minorVer, patchVer] = (
          liff.getLineVersion() || ""
        ).split(".");

        if (minorVer === undefined) {
          alert("ShareTargetPicker was canceled in external browser");
          return;
        }

        if (
          parseInt(majorVer) >= 10 &&
          parseInt(minorVer) >= 10 &&
          parseInt(patchVer) > 0
        ) {
          alert("ShareTargetPicker was canceled in LINE app");
        }
      }
    } catch (error) {
      console.error("Error sharing via LINE:", error.message);
    }
  };

  const onConfirm = async () => {
    navigate(`/dogbook-invite-friend-confrim`);
  };

  const onCopy = () => {
    var textToCopy = document.getElementById("tMemberCode").innerText;

    var tempInput = document.createElement("input");
    document.body.appendChild(tempInput);
    tempInput.value = textToCopy;
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    const inviteFriendPage = document.getElementById("inviteFriendPage");
    inviteFriendPage.classList.add("copy");
    setTimeout(function () {
      inviteFriendPage.classList.remove("copy");
    }, 1000);
  };

  const contBTN0 = !(myStamp >= maxStamp); // กรณี มีค่า mystamp == 10
  const disabledBTN = contBTN0;

  return (
    <>
      <CustomPopup
        title={"ส่งคำเชิญให้เพื่อนเรียบร้อย!"}
        // desc={"รอเพื่อนของคุณตอบรับคำเชิญครบ 10 คน เพื่อแลกรับ NexCoin ได้เลย "}
        descHtml={
          "รอเพื่อนของคุณตอบรับคำเชิญครบ 10 คน<br/>เพื่อแลกรับ NexCoin ได้เลย "
        }
        cancelText={"ปิด"}
        cancelFunction={() => {
          setOpenPopup(false);
        }}
        open={openPopup}
        onClose={() => {
          setOpenPopup(false);
        }}
      />
      <div id="inviteFriendPage">
        <TopLogo />
        <div className="content gradientBg ">
          <TopBanner type="inviteFriend" />
          <div className="pInviteMain">
            <div className="bInviteBar">
              <div className="tCopyLink">
                <p>
                  รหัสเชิญ:{" "}
                  <span id="tMemberCode">{myMemberCode ?? myMemberCode}</span>
                </p>
                <i onClick={onCopy}></i>
              </div>
              <button onClick={onShareLink}>
                <i></i>
                <p>แชร์ลิ้งก์เชิญ</p>
              </button>
            </div>
            <div className="bStampCard">
              <h3>
                จำนวนเพื่อนที่เชิญแล้ว: <span>{myStamp}/10</span>
              </h3>
              <div className="bPackCard">
                {[...Array(10)].map((item, i) => {
                  return (
                    <div className="bItemCard" key={i}>
                      {myStamp > i ? (
                        <i className="bPic used"></i>
                      ) : (
                        <i className="bPic free"></i>
                      )}
                      <p>คนที่ {i + 1}</p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="bBTN">
              <button
                className="btn _outline transparent"
                onClick={onConfirm}
                disabled={disabledBTN}
              >
                แลกรับ NexCoin
              </button>
              {/* <button className="btn ">ยืนยันแลกรับ NexCoin</button> */}
              {/* <button className="btn _outline">ยกเลิก</button> */}
            </div>
          </div>
          <div className="pInviteDesc">
            <h2>กติกาการเล่น</h2>
            <div className="bDesc">
              <p>1. กดปุ่มแชร์ลิงค์เชิญ พร้อมส่งรหัสเชิญสมาชิกผ่านไลน์</p>
              <p>
                2. เมื่อเพื่อนสมัครสำเร็จโดยใส่รหัสเชิญเราก็ได้รับไปเลย 1 Dog
                Friend 20 coin
              </p>
              <p>
                3. เมื่อทำการสะสมครบ 10 Dog Friend เรียบร้อย
                สามารถเชิญเพื่อนใหม่ได้ไม่จำกัด​ ​
              </p>
              {/* <p>
                4. หลังจากทำการเชิญเพื่อนครบและได้รับ Double Nexcoin เรียบร้อย
                สามารถเริ่มเชิญเพื่อนใหม่ได้ ไม่จำกัด
              </p> */}

              <br />
              <p>
                *หมายเหตุ 1 Dog Friend
                จะได้รับเมื่อเพื่อนทำการสมัครสมาชิกเรียบร้อยแล้วเท่านั้น
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";

export default function Cookies() {
  return (
    <>
      <ol className="list-decimal leading-[100%] space-li">
        <li>
          บททั่วไป LINE @Spectrafordog ดำเนินการโดยบริษัท เบอริงเกอร์
          <br />
          อินเกลไฮม์ แอนิมอล เฮลท์ (ประเทศไทย) จำกัด ตั้งอยู่ เลขที่
          <br />
          2922/193-195 อาคารชาญอิสสระทาวเวอร์ 2 ชั้น 9 ถนนเพชรบุรี
          <br />
          ตัดใหม่ แขวงบางกะปิ เขตห้วยขวาง กรุงเทพมหานคร 10310
          <br />
          ซึ่งต่อไปในนโยบายการใช้คุกกี้ฉบับนี้ ต่อจากนี้เรียกว่า "ทางเรา"
          <br />
          หรือ "เรา" เรามุ่งมั่นที่จะปกป้องและเคารพความเป็นส่วนตัวของท่าน
          <br />
          กรุณาอ่านนโยบายการใช้คุกกี้ฉบับนี้พร้อมกับนโยบายความเป็น
          <br />
          ส่วนตัวและเงื่อนไขการใช้งานของเรา ซึ่งมีการอธิบายว่าคุกกี้คืออะไร
          <br />
          ทำไมเราถึงใช้คุกกี้และใช้อย่างไร เมื่อท่านเข้าเยี่ยมชมเว็บไซต์ และท่าน
          <br />
          สามารถบล็อก และ/หรือลบคุกกี้ได้อย่างไร
        </li>
        <li>
          ความยินยอม เว็บไซต์ของเราใช้คุกกี้เพื่อจำแนกท่านออกจากผู้ใช้งาน
          <br />
          รายอื่นของเว็บไซต์ ซึ่งช่วยให้เราสามารถมอบประสบการณ์ที่ดี
          <br />
          ให้แก่ท่านในขณะท่องเว็บไซต์ และยังช่วยให้เราสามารถปรับปรุงเว็บ
          <br />
          ไซต์ให้ดียิ่งขึ้นได้ด้วย เมื่อท่านยังท่องเว็บไซต์ต่อไปแสดงว่าท่าน
          <br />
          ยอมรับการใช้คุกกี้ของเรา
        </li>
        <li>
          คุกกี้คืออะไร คุกกี้เป็นไฟล์ข้อความที่ประกอบด้วยข้อมูลจำนวน
          <br />
          ไม่มาก ซึ่งโดยปกติจะเป็นสตริงอักขระและตัวเลขต่างๆ และถูกนำไปใช้
          <br />
          ทั่วอินเทอร์เน็ตและจัดเก็บไว้ในและเข้าถึงได้จากอุปกรณ์ของท่านใน
          <br />
          ขณะเข้าเยี่ยมชมเว็บไซต์ (รวมถึงเว็บไซต์นี้)
        </li>
        <li>
          เว็บไซต์นี้ใช้คุกกี้อย่างไร
          คุกกี้ดังกล่าวนี้ช่วยปรับปรุงเว็บไซต์นี้ทาง
          <br />
          อ้อม โดยการติดตามการใช้งานของท่าน คุกกี้ชุดนี้รวบรวมข้อมูล
          <br />
          และอาจรายงานสถิติการใช้งานเว็บไซต์โดยไม่ได้ระบุตัวตนของผู้เข้า
          <br />
          ชมเว็บไซต์แต่ละราย
        </li>
        <li>
          เราใช้คุกกี้ประเภทใดบ้าง?
          <ul className="list-disc pl-5">
            <li>
              คุกกี้การนำทางหรือคุกกี้แบบช่วงเวลา – เป็นคุกกี้ชั่วคราว
              <br />
              ที่ยังคงใช้งานได้จนกว่าท่านจะออกจากระบบเว็บไซต์
              <br />
              หรือคุกกี้หมดอายุเนื่องจากไม่มีการใช้งานเป็นระยะเวลาหนึ่ง
              <br />
              คุกกี้แบบช่วงเวลาจำเป็นต่อเว็บไซต์เพื่อให้ทำงานได้อย่างถูก
              <br />
              ต้อง เช่น การเข้าถึงบริเวณสำหรับเข้าสู่ระบบของเว็บไซต์
              <br />
              ซึ่งจำเป็นสำหรับการท่องและใช้เว็บไซต์ทั่วไป เราอาจใช้คุกกี้
              <br />
              ที่มีระยะเวลาเท่ากับช่วงเวลาการท่องเว็บหนึ่งครั้ง ซึ่งจะหมด
              <br />
              อายุเมื่อปิดเบราว์เซอร์ หรือเราสามารถใช้คุกกี้แบบถาวร
              <br />
              ซึ่งใช้งานได้นานกว่านั้นแม้ว่าจะปิดเบราว์เซอร์ไปแล้วก็ตาม
              <br />
              แต่มักจะเป็นช่วงเวลาที่มีการกำหนดไว้โดยเฉพาะเสมอ
            </li>
            <li>
              คุกกี้เพื่อการวิเคราะห์/วัดผลการทำงานของเว็บไซต์ - ใช้เพื่อ
              <br />
              รวบรวมข้อมูลโดยรวมเกี่ยวกับจำนวนผู้ใช้งานและวิธีการที่ผู้ใช้
              <br />
              งานเข้าเยี่ยมชมเว็บไซต์ ซึ่งทำให้เรารับทราบ และนับจำนวน
              <br />
              ผู้เข้าชมเว็บไซต์และดูว่าผู้เข้าชมเว็บไซต์ท่องเว็บไซต์ของเรา
              <br />
              อย่างไรบ้างเมื่อพวกเขาใช้งาน ส่วนนี้ช่วยให้เราสามารถ
              <br />
              ปรับปรุงวิธีการทำงานของเว็บไซต์ให้ดียิ่งขึ้นได้ ตัวอย่างเช่น
              <br />
              รับรองว่าผู้ใช้งานสามารถค้นหาสิ่งที่ต้องการได้อย่างง่ายดาย
            </li>
            <li>
              คุกกี้เพื่อการทำงานของเว็บไซต์ - คุกกี้ประเภทนี้ทำให้ผู้ใช้งาน
              <br />
              สามารถท่องเว็บไซต์ได้ตามเกณฑ์ที่เลือก (เช่น ภาษา
              <br />
              ผลิตภัณฑ์ที่เลือกซื้อ) เพื่อสร้างประสบการณ์ในการใช้งาน
              <br />
              เว็บไซต์ของท่านให้ดียิ่งขึ้น คุกกี้นี้ถูกนำมาใช้เพื่อจดจำ
              เมื่อท่าน
              <br />
              กลับมาเยี่ยมชมเว็บไซต์ของเราอีกครั้ง เราสามารถปรับเปลี่ยน
              <br />
              เนื้อหาของเราให้เหมาะสมกับท่านและจดจำการตั้งค่าของท่านได้
              <br />
              (ตัวอย่างเช่น การเลือกภาษาหรือภูมิภาคของท่าน)
            </li>
            <li>
              คุกกี้เพื่อปรับเนื้อหาเข้ากับกลุ่มเป้าหมาย - คุกกี้นี้จะบันทึก
              <br />
              การเข้าเยี่ยมชมเว็บไซต์ของเรา หน้าเว็บที่ท่านเข้าเยี่ยมชม
              <br />
              และลิงก์ที่ท่านติดตาม เราจะใช้ข้อมูลนี้เพื่อทำให้เว็บไซต์ของเรา
              <br />
              และโฆษณาที่ปรากฏบนเว็บไซต์มีความเกี่ยวข้องกับความสนใจ
              <br />
              ของท่านมากขึ้น เราอาจแบ่งปันข้อมูลนี้กับบุคคลภายนอกเพื่อ
              <br />
              วัตถุประสงค์นี้ด้วย
            </li>
            <li>
              คุกกี้แบบถาวร คุกกี้นี้จะยังคงอยู่ในอุปกรณ์ของท่านเป็นระยะ
              <br />
              เวลาที่นานขึ้น (ระยะเวลาขึ้นอยู่กับอายุของคุกกี้โดยเฉพาะ)
              <br />
              เว็บไซต์อาจใช้คุกกี้แบบถาวรสองตัวซึ่ง
              <ol className="list-lower-roman pl-5">
                <li>
                  อาจถูกเก็บไว้ในอุปกรณ์ของท่านหลังจากที่ท่านเข้าสู่
                  <br />
                  เว็บไซต์ครั้งแรก คุกกี้นี้ระบุลักษณะเฉพาะของบริษัทที่ไม่
                  <br />
                  ซ้ำกัน และนำมาใช้เพื่อจัดทำการสร้างตัวตนของเว็บไซต์
                  <br />
                  และการปรับแต่งอื่น ๆ
                </li>
                <li>
                  อาจถูกเก็บไว้ในอุปกรณ์ของท่าน หากท่านเลือกให้
                  <br />
                  เว็บไซต์จดจำชื่อผู้ใช้ของท่าน โดยสามารถเลือกตัวเลือก
                  <br />
                  นี้ได้โดยเลือกทำเครื่องหมายที่ช่องในหน้าลงชื่อเข้าใช้
                  <br />
                  ของสมาชิก เมื่อเลือกทำเครื่องหมายที่ช่องแล้วแสดงว่า
                  <br />
                  ท่านยินยอมให้เราจัดเก็บและอ่านคุกกี้นี้
                </li>
              </ol>
            </li>
            <li>
              เว็บบีคอน - เป็นภาพกราฟิกขนาดเล็กที่มีความโปร่งใส
              <br />
              ข้อมูลนี้ถูกนำไปใช้ในการจัดทำรายงานเพื่อช่วยให้เราสามารถ
              <br />
              ปรับปรุงการบริการของเราให้ดียิ่งขึ้นให้แก่ท่านได้
            </li>
          </ul>
        </li>
        <li>
          บุคคลภายนอก บริษัท เบอริงเกอร์ อินเกลไฮม์ แอนิมอล เฮลท์
          <br />
          (ประเทศไทย) จำกัด อาจฝังวิดีโอหรือเนื้อหาอื่น ๆ ลงในเว็บไซต์
          <br />
          และเมื่อท่านเข้าเยี่ยมชมหน้าเว็บที่มีเนื้อหาฝังอยู่
          ท่านอาจได้รับคุกกี้
          <br />
          จากเนื้อหานั้น บริษัท เบอริงเกอร์ อินเกลไฮม์ แอนิมอล เฮลท์
          <br />
          (ประเทศไทย) จำกัด ไม่ได้ควบคุมคุกกี้ดังกล่าวนี้ และเราขอแนะนำ
          <br />
          ให้ท่านตรวจสอบเว็บไซต์ของบุคคลภายนอกสำหรับข้อมูลเกี่ยวกับ
          <br />
          พวกเขา
          <div className="py-1"></div>
          วิดีโอที่ฝังอยู่ในเว็บไซต์จะไม่ได้เล่นเองโดยอัตโนมัติ เมื่อคลิกที่ปุ่ม
          <br />
          เล่นสำหรับวิดีโอที่ฝังอยู่ใด ๆ ก็ตาม แสดงว่าท่านยินยอมให้จัดเก็บ
          <br />
          คุกกี้แฟลช (ถ้ามี) ไว้ในอุปกรณ์ของท่านได้
        </li>
        <li>
          ฉันจะบล็อกและ/หรือลบคุกกี้ได้อย่างไร? ท่านสามารถบล็อกการ
          <br />
          ใช้คุกกี้หรือลบคุกกี้ที่ติดตั้งไว้แล้วในเบราว์เซอร์ของท่านได้
          <div className="py-1"></div>
          เราขอแจ้งให้ท่านทราบข้อเท็จจริงว่าการปิดใช้งานคุกกี้อาจทำให้ท่าน
          <br />
          ไม่สามารถเข้าถึงคุณสมบัติบางอย่างบนเว็บไซต์ของเรา และเว็บไซต์
          <br />
          อื่นๆ ได้
          <div className="py-1"></div>
          ท่านสามารถตั้งค่าเบราว์เซอร์ให้ยอมรับหรือปฏิเสธคุกกี้ทั้งหมด
          <br />
          ให้แจ้งเตือนเมื่อมีการใช้คุกกี้ ให้ตรวจสอบความถูกต้อง ระยะเวลา
          <br />
          เนื้อหา และให้ลบคุกกี้ตามระยะเวลาที่กำหนดได้ นอกจากนี้ท่านยัง
          <br />
          สามารถพิมพ์คำว่า “คุกกี้” ลงในส่วนช่วยเหลือของเบราว์เซอร์เพื่อดู
          <br />
          คำแนะนำเกี่ยวกับการตั้งค่าได้ สำหรับข้อมูลเกี่ยวกับวิธีจัดการคุกกี้
          <br />
          ในเบราว์เซอร์ของอุปกรณ์มือถือของท่าน โดยจะต้องศึกษาคู่มือของ
          <br />
          อุปกรณ์ดังกล่าว
        </li>
        <li>
          การติดต่อเรา บริษัท เบอริงเกอร์ อินเกลไฮม์ แอนิมอล เฮลท์
          <br />
          (ประเทศไทย) จำกัด ขอสงวนสิทธิ์ในการเปลี่ยนแปลงนโยบายการใช้
          <br />
          คุกกี้ฉบับนี้เมื่อใดก็ได้ โดยแจ้งให้ทราบผ่านการโพสต์เกี่ยวกับการ
          <br />
          เปลี่ยนแปลงในเว็บไซต์นี้ กรุณาตรวจสอบหน้าเว็บนี้เป็นประจำเพื่อ
          <br />
          ดูการเปลี่ยนแปลงใด ๆ นโยบายฉบับนี้ได้รับการปรับปรุงล่าสุด
          <br />
          เมื่อวันที่ 25 มิถุนายน พ.ศ. 2563
          <div className="py-1"></div>
          หากมีข้อสงสัยเกี่ยวกับแนวปฏิบัติการใช้คุกกี้ของเรา ท่านสามารถ
          <br />
          ติดต่อเราได้ทุกเมื่อ หรือสามารถติดต่อเจ้าหน้าที่คุ้มครองข้อมูลส่วน
          <br />
          บุคคลของเราได้โดยตรง:
          <div className="py-1"></div>
          <div className="font-[600]">เบอริงเกอร์ อินเกลไฮม์ ประเทศไทย</div>
          <div className="py-1"></div>
          <div className="text-[90%]">เจ้าหน้าที่คุ้มครองข้อมูล</div>
          <div>
            หมายเลขโทรศัพท์ติดต่อ: &nbsp;
            <a className="text-blue" href="tel:+66 2 308-8522">
              +66 2 308-8522
            </a>
          </div>
          <div>
            อีเมล: &nbsp;
            <a
              className="text-blue"
              href="mailto:zzLEGCompDPOThailand@boehringer-ingelheim.com"
            >
              zzLEGCompDPOThailand@boehringer-ingelheim.com
            </a>
          </div>
        </li>
      </ol>
      <div className="py-1"></div>
      <div className="ml-[-3%]">
        <div className="text-[120%] text-[#209FED]">
          คำชี้แจงสิทธิ์การเข้าถึง
        </div>
        <div className="leading-[100%]">
          เบอริงเกอร์ อินเกลไฮม์
          มุ่งมั่นที่จะให้บริการเว็บไซต์ที่สามารถเข้าถึงผู้ชม
          <br />
          ได้มากที่สุด โดยไม่คำนึงถึงเทคโนโลยีหรือศักยภาพ เรามุ่งมั่นทำงานอย่าง
          <br />
          แข็งขันเพื่อเพิ่มสิทธิ์การเข้าถึงและความสามารถในการใช้งานเว็บไซต์ของ
          <br />
          เรา และเพื่อให้เป็นไปตามมาตรฐานและแนวทางที่มีอยู่หลากหลาย ซึ่งแนว
          <br />
          ทางดังกล่าวนี้อธิบายถึงวิธีการที่ทำให้คนพิการสามารถเข้าถึงเนื้อหา
          <br />
          เว็บไซต์ได้ง่ายขึ้น
          การปฏิบัติตามแนวทางดังกล่าวนี้จะช่วยให้ผู้ใช้งานทุกคน
          <br />
          สามารถใช้งานเว็บไซต์ได้ง่ายขึ้น เราพยายามหาวิธีแก้ไขปัญหาอย่าง
          <br />
          ต่อเนื่อง ซึ่งจะช่วยทำให้สามารถเข้าถึงทุกส่วนของเว็บไซต์โดยรวมได้ใน
          <br />
          ระดับเดียวกัน
        </div>
        <div className="py-1"></div>
        <div className="text-[120%] text-[#209FED]">
          องค์ประกอบมัลติมีเดีย: รูปภาพ ไฟล์วิดีโอ และไฟล์เสียง
        </div>
        <div className="leading-[100%]">
          ข้อความในไฟล์ ภาพ และข้อความอธิบายรูปภาพสั้น ๆ มีอยู่ในเนื้อหา
          <br />
          มัลติมีเดีย และเนื้อหาที่ไม่ใช่ข้อความทั้งหมด นอกจากนี้ยังมีเว็บไซต์
          <br />
          รูปแบบย่อที่แสดงหน้าเว็บ พร้อมข้อความอธิบายแทนรูปภาพ และวิดีโอ
          <br />
          ทั้งหมด มีการระบุขนาดของวิดีโอ และมัลติมีเดียทั้งหมดเพื่อให้สามารถ
          <br />
          ประมาณการเวลาดาวน์โหลดได้ หากจำเป็นต้องใช้ซอฟต์แวร์เพื่อดูวิดีโอ
          <br />
          จะมีการโพสต์ลิงค์ดาวน์โหลด เสริมเอาไว้ วิดีโอทั้งหมดจะถูกนำเสนอด้วย
          <br />
          ซอฟต์แวร์เครื่องเล่น ซึ่งช่วย ให้ผู้ใช้งานสามารถเล่นวิดีโอ
          หยุดชั่วคราว
          <br />
          หยุดเล่น เล่นเดินหน้า และเล่น ย้อนกลับได้
        </div>
        <div className="py-1"></div>
        <div className="text-[120%] text-[#209FED]">
          การนำเสนอเนื้อหาและเค้าโครง
        </div>
        <div className="leading-[100%]">
          มีการแยกเนื้อหาและเค้าโครงอย่างเคร่งครัดผ่านการใช้ HTML และ CSS
          <br />
          (Cascading Style Sheet) อย่างเหมาะสม หลีกเลี่ยงการใช้เค้าโครงแบบ
          <br />
          ตารางหากเป็นไปได้
          <div className="py-1"></div>
          เราพยายามนำเสนอโครงสร้างการนำทางเว็บไซต์ ที่ชัดเจน ซึ่งช่วยให้
          <br />
          สามารถค้นหาเนื้อหาที่ท่านกำลังมองหาด้วยวิธีที่ง่ายและรวดเร็ว
          <br />
          ลิงค์ประกอบด้วยคำอธิบายหัวเรื่อง ซึ่งอธิบายเนื้อหา
          และทำให้เข้าใจได้ง่าย
          <br />
          จากบริบท การใช้ประโยคและย่อหน้าสั้นๆ และจุดเริ่มต้นหลายจุดไปยังแหล่ง
          <br />
          ข้อมูลเพิ่มเติม เช่น
          ผ่านองค์ประกอบทีเซอร์และบริเวณเนื้อหาที่เกี่ยวข้อง
          <br />
          จะช่วยให้เข้าใจเนื้อหาได้ง่ายขึ้นในขณะเดียวกัน หากท่านประสบปัญหา
          <br />
          ในการเข้าถึงเว็บไซต์นี้ กรุณาติดต่อเราทันที
          <div className="py-1"></div>
          บริษัท เบอริงเกอร์ อินเกลไฮม์ แอนิมอล เฮลท์ (ประเทศไทย) จำกัด
          <br />
          ไม่รับรองหรือรับประกันเกี่ยวกับความถูกต้องหรือความครบถ้วนของ
          <br />
          ข้อมูลใด ๆ ที่อยู่ในเว็บไซต์นี้
          ภายใต้เงื่อนไขที่ทำให้ท่านสามารถเข้าถึงข้อมูล
          <br />
          ในเว็บไซต์นี้ได้โดยไม่เสียค่าใช้จ่าย ซึ่งบริษัท เบอริงเกอร์ อินเกลไฮม์
          <br />
          แอนิมอล เฮลท์ (ประเทศไทย) จำกัด ไม่และจะไม่รับผิดชอบต่อความสูญเสีย
          <br />
          การบาดเจ็บ หรือความเสียหายใด ๆ รวมถึงแต่ไม่จำกัดเพียงความสูญเสีย
          <br />
          หรือความเสียหายโดยตรง โดยอ้อม พิเศษ เชิงลงโทษ โดยบังเอิญหรือสืบ
          <br />
          เนื่อง กำไรที่สูญเสีย หรือความเสียหายที่เกิดขึ้นจากข้อมูลที่สูญหาย
          <br />
          หรือการหยุดชะงักทางธุรกิจอันเป็นผลมาจากการเข้าใช้งานเว็บไซต์นี้หรือ
          <br />
          การไม่สามารถเข้าใช้งานเว็บไซต์นี้ของผู้ใช้งาน
          หรือเพื่อการพึ่งพาข้อมูลใด
          <br />ๆ ที่มีอยู่ในเว็บไซต์นี้ของผู้ใช้งาน
          <div className="py-1"></div>
          บริษัท เบอริงเกอร์ อินเกลไฮม์ แอนิมอล เฮลท์ (ประเทศไทย) จำกัด
          <br />
          ไม่ได้ยกเว้นความรับผิดต่อการเสียชีวิตหรือการบาดเจ็บของบุคคลที่เกิด
          <br />
          ขึ้นโดยตรงจากความประมาทเลินเล่อหรือการประพฤติมิชอบโดยเจตนา
          <br />
          บริษัท เบอริงเกอร์ อินเกลไฮม์ แอนิมอล เฮลท์ (ประเทศไทย) จำกัด
          <br />
          ขอสงวนสิทธิ์ในการเพิ่ม ลบ หรือแก้ไขข้อมูลในเว็บไซต์นี้เมื่อใดก็ได้
          และไม่
          <br />
          ต้องแจ้งให้ทราบล่วงหน้า ข้อยกเว้นและข้อจำกัดข้างต้นนำไปใช้กับขอบเขต
          <br />
          ตามที่กฎหมายอนุญาตเท่านั้น สิทธิ์ตามกฎหมายของท่านในฐานะผู้บริโภค
          <br />
          ต้องไม่ได้รับผลกระทบใด ๆ
          เว็บไซต์นี้มีข้อมูลเกี่ยวกับผลิตภัณฑ์ที่ได้รับ
          <br />
          การอนุมัติให้ใช้ภายในประเทศไทยได้ในปัจจุบัน และอาจแนะนำให้ใช้โดยหรือ
          <br />
          ตามคำแนะนำของสัตวแพทย์ที่ได้รับอนุญาตเท่านั้น นอกจากนี้ เว็บไซต์นี้
          <br />
          อาจมีข้อมูลเกี่ยวกับผลิตภัณฑ์ใหม่ที่ยังไม่ได้รับการอนุมัติในประเทศไทย
          <br />
          ในปัจจุบันหรือผลิตภัณฑ์ที่มีอยู่ในประเทศอื่น
          ซึ่งไม่ควรตีความว่าเป็นการ
          <br />
          อนุญาตให้ใช้ผลิตภัณฑ์ดังกล่าวได้
          <div className="py-1"></div>
          เว็บไซต์นี้เชื่อมโยงไปยังเว็บไซต์อื่น ๆ ที่จัดทำโดยหน่วยงานต่าง ๆ
          ซึ่งบาง
          <br />
          แห่งอาจตั้งอยู่นอกประเทศไทย บริษัท เบอริงเกอร์ อินเกลไฮม์ แอนิมอล
          <br />
          เฮลท์ (ประเทศไทย) จำกัด จัดทำลิงค์เชื่อมโยงดังกล่าวนี้ เพื่ออำนวยความ
          <br />
          สะดวกเท่านั้นและไม่รับรองความถูกต้องหรือความสมบูรณ์ของข้อมูลที่อยู่
          <br />
          ในนั้น เว็บไซต์ดังกล่าวอาจมีข้อมูลที่เหมาะสมเฉพาะกับประเทศหรือภูมิภาค
          <br />
          ต้นทางที่เป็นที่ตั้งของเว็บไซต์เท่านั้น
          <div className="py-1"></div>
          ท่านไม่ควรตีความสิ่งใดบนเว็บไซต์นี้ว่าเป็นการส่งเสริมหรือการชักชวนให้
          <br />
          ใช้ผลิตภัณฑ์ใด ๆ หรือเพื่อการใช้ผลิตภัณฑ์ใด ๆ ที่ไม่ได้รับอนุญาตตาม
          <br />
          กฎหมายและข้อบังคับของประเทศที่ท่านอาศัยอยู่
          <div className="py-1"></div>
          ชื่อผลิตภัณฑ์ทั้งหมดที่ระบุไว้ในเว็บไซต์นี้ที่เป็นตัวอักษรตัวใหญ่และ/
          <br />
          หรือตามด้วยสัญลักษณ์ ™ หรือ ® ถือเป็นเครื่องหมายการค้าของบริษัท
          <br />
          เบอริงเกอร์ อินเกลไฮม์ แอนิมอล เฮลท์ (ประเทศไทย) จำกัด
          เว้นแต่จะระบุไว้
          <br />
          เป็นอื่น
          ไม่มีสิ่งใดในเว็บไซต์นี้ที่ถือว่าเป็นการให้อนุญาตหรือให้สิทธิ์ภายใต้
          <br />
          ลิขสิทธิ์ สิทธิ์บัตร เครื่องหมายการค้า หรือสิทธิ์ในทรัพย์สินทางปัญญา
          <br />
          อื่น ๆ ของ บริษัท เบอริงเกอร์ อินเกลไฮม์ แอนิมอล เฮลท์ (ประเทศไทย)
          <br />
          จำกัด หรือบุคคลภายนอก
        </div>
      </div>
    </>
  );
}

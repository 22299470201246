import { useEffect, useState } from "react";
import dayjs from "dayjs";
import locale from "dayjs/locale/th";
import weekdayPlugin from "dayjs/plugin/weekday";
import objectPlugin from "dayjs/plugin/toObject";
import isTodayPlugin from "dayjs/plugin/isToday";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Pagination, FreeMode, Navigation } from "swiper";
import { useRef } from "react";
import CalendarDiary from "../../assets/images/icon/calendar-diary.svg";
import "../../daliy-calendar.css";

const Calendar = (props) => {
  const inputEl = useRef(null);
  const now = dayjs(props.value).locale({
    ...locale,
  });

  dayjs.extend(weekdayPlugin);
  dayjs.extend(objectPlugin);
  dayjs.extend(isTodayPlugin);

  const [currentMonth, setCurrentMonth] = useState(now);
  const [firstLoad, setFirstLoad] = useState(false);
  const [arrayOfDays, setArrayOfDays] = useState([]);

  useEffect(() => {
    getAllDays();
    console.log('currentMonth >>', currentMonth )
  }, [currentMonth]);

  const nextMonth = () => {
    const plus = currentMonth.add(1, "month");

    setCurrentMonth(plus);
  };

  const prevMonth = () => {
    const minus = currentMonth.subtract(1, "month");

    setCurrentMonth(minus);
  };

  const renderHeader = () => {
    const dateFormat = "MMMM YYYY";

    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <div className="icon" onClick={() => prevMonth()}>
            chevron_left
          </div>
        </div>
        <div className="col col-center flex" style={{ flex: 5 }}>
          <img className="w-[18px]" src={CalendarDiary} alt="" />
          <span className="text text-center  text-[28px] text-left px-[10px] text-lightBlue">
            {currentMonth.format(dateFormat)}
          </span>
        </div>
        <div className="col col-end" onClick={() => nextMonth()}>
          <div className="icon">chevron_right</div>
        </div>
      </div>
    );
  };

  const onSelectDate = (dateSelect)=> {
    console.log('onSelectDate >>', dateSelect)
    console.log('inputEl >>', inputEl.current?.swiper)
    pageSlideTo(dateSelect.index)
    props.onSelect(dateSelect);
  }

  function pageSlideTo(index) {
    if(inputEl.current?.swiper) {
      inputEl.current.swiper.slideTo(index)
    }
  }

  const getAllDays = () => {
    let currentDate = currentMonth.startOf("month");
    const nextMonth = currentMonth.add(1, "month").month();
    let allDates = [];
    let pIndex = 0;
    while (currentDate.month() !== nextMonth) {
      const formated = formateDateObject(currentDate, pIndex);
      allDates.push(formated);
      currentDate = currentDate.add(1, "day");
      pIndex++;
    }
    setArrayOfDays(allDates);
    if(firstLoad) {
      onSelectDate(allDates[0])
    } else {
      setFirstLoad(true)
      const find = allDates.find(item => item.dateIso == currentMonth.format('YYYY-MM-DD'))
      setTimeout(()=>{
        pageSlideTo(find.index)
      },200)
    }
  };



  const dateBox = (cDate, dayName = "", dayNum = 0) => {
    // props.value
    return (
      <div
        onClick={() => {
          // console.log("select Date ", cDate.date);
          onSelectDate(cDate)
        }}
      >
        {cDate.dateIso === props.value ? (
          <div className="itemDay active">
            <p className="text-[20px] font-bold text-blue text-center  mb-[7px]">
              {dayName}
            </p>
            <button className="bg-blue rounded-full px-2">
              <p className="text-white text-[20px]">
                {`${dayNum}`.padStart(2, "0")}
              </p>
            </button>
          </div>
        ) : (
          <div className="itemDay">
            <p className="text-[20px] font-bold text-blue text-center  mb-[7px]">
              {dayName}
            </p>
            <button className="bg-blue rounded-full px-2">
              <p className="text-white text-[20px]">
                {`${dayNum}`.padStart(2, "0")}
              </p>
            </button>
          </div>
        )}
      </div>
    );
  };
  
  const renderCellsDog = () => {
    // console.log("arrayOfDays >>", arrayOfDays);
    return (
      <div className="px-[17px]">
        <Swiper
          ref={inputEl}
          slidesPerView={4}
          spaceBetween={10}
          freeMode={true}
          modules={[FreeMode]}
          className="mySwiper"
        >
          {arrayOfDays.map((cDate) => (
            <SwiperSlide key={cDate.day}>
              {dateBox(cDate, cDate.dayName, cDate.day)}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  };

  const formateDateObject = (date, pIndex) => {
    const clonedObject = { ...date.toObject() };
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const dayindex = date.format("d");
    const dateIso = date.format("YYYY-MM-DD");
    const formatedObject = {
      index: pIndex,
      dayName: days[dayindex],
      day: clonedObject.date,
      month: clonedObject.months,
      year: clonedObject.years,
      isCurrentMonth: clonedObject.months === currentMonth.month(),
      isCurrentDay: date.isToday(),
      date: date,
      dateIso: dateIso,
    };

    return formatedObject;
  };

  return (
    <div className="calendar rounded-t-[4px]">
      {renderHeader()}
    </div>
  );
};

export default Calendar;

import { configureStore } from "@reduxjs/toolkit";
import RegisterMainReducer from "../features/register/RegisterMainSlice";
import RegisterPetShopReducer from "../features/register/RegisterPetShopSlice";
import MyPointReducer from "../features/MyPointSlice";
import QuestionnaireReducer from "../features/QuestionnaireSlice";
import LoadingReducer from "../features/LoadingSlice";
import StampInviteFriendReducer from "../features/invite/StampInviteFriendSlice";
import QuizManiaReducer from "../features/QuizManiaSlice";

export default configureStore({
  reducer: {
    registerMain: RegisterMainReducer,
    registerPetShop: RegisterPetShopReducer,
    myPoint: MyPointReducer,
    questionnaire: QuestionnaireReducer,
    loading: LoadingReducer,
    stampInviteFriend: StampInviteFriendReducer,
    quizMania: QuizManiaReducer,
  },
});

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const maxStamp = 6;
const defBookStamp = {
  clinicPetShop: "0",
  clinicPetShopName: "...",
  bookNo: 0,
  totalStamp: 0,
};

export default function StampCardMain({
  stampCountList = [],
  currentIndex = 0,
  onNext = () => console.log("onNext!"),
  onBack = () => console.log("onBack!"),
}) {
  const navigate = useNavigate();

  const tmpStampCountList = JSON.parse(JSON.stringify(stampCountList));

  let _stampCountList = [];
  const arrTotalStampAll = tmpStampCountList.map((item) => item.totalStamp);
  const totalStampAll = arrTotalStampAll.reduce(
    (acc, cur) => Number(acc) + Number(cur),
    0
  ); // sum

  if (totalStampAll % 6 === 0 || tmpStampCountList.length === 0) {
    _stampCountList = tmpStampCountList;
    const bookNoNew = tmpStampCountList
      .map((item) => item.bookNo)
      .sort(function (a, b) {
        return b - a;
      });
    console.log("bookNoNew >>", bookNoNew);
    _stampCountList.unshift({ ...defBookStamp, bookNo: bookNoNew[0] + 1 });
  } else {
    _stampCountList = tmpStampCountList;
  }

  _stampCountList = tmpStampCountList;

  return (
    <div className="pStampMain">
      <div className="bStampCard">
        <div className="bStampHead">
          {/* {totalStampAll} */}
          <div className="bLeft">
            <h3>สมุดสะสม NexStamp ของฉัน</h3>
            <p>
              แสตมป์ที่ได้รับแล้ว: {_stampCountList[currentIndex].totalStamp}/
              {maxStamp}
            </p>
            <p>
              <span>
                {_stampCountList[currentIndex].bookNo
                  ? "เล่มที่ " + _stampCountList[currentIndex].bookNo
                  : ""}
                {" สาขา" + _stampCountList[currentIndex].clinicPetShopName}
              </span>
            </p>
          </div>
          <div className="bRight">
            <button
              className="btn"
              // onClick={onExchangeReward}
              onClick={() => navigate("/redeem-gift-member")}
              // disabled={disabledBTN}
            >
              แลกรางวัล
            </button>
          </div>
        </div>

        <div className="bPackCard">
          {[...Array(6)].map((item, i) => {
            console.log(
              "_stampCountList[currentIndex].totalStamp > i  >>",
              _stampCountList[currentIndex].totalStamp > i
            );
            return (
              <div
                className={`bItemCard ${
                  _stampCountList[currentIndex].totalStamp > i ? " done " : ""
                }`}
              >
                {_stampCountList[currentIndex].totalStamp > i ? (
                  <>
                    <i>{i + 1}</i>
                    <img
                      className="bPic done"
                      src={`assets/img/ci/my-point-stamp/used/stamp-${
                        i + (1 + (currentIndex % 2 === 0 ? 0 : 6))
                      }.png`}
                    ></img>
                  </>
                ) : (
                  <>
                    <i className="free">{i + 1}</i>
                    <img
                      className="bPic free"
                      src={`assets/img/ci/my-point-stamp/free/stamp.png`}
                    ></img>
                  </>
                )}
              </div>
            );
          })}
        </div>

        <div className="bControlStamp">
          <button
            className="btnControl back"
            disabled={currentIndex === 0}
            onClick={() => {
              const tmpValue = currentIndex - 1;
              // setcurrentIndex(tmpValue)
              onBack(tmpValue);
            }}
          ></button>
          {/* {_stampCountList.length} */}
          <button
            className="btnControl next"
            disabled={currentIndex === _stampCountList.length - 1}
            onClick={() => {
              const tmpValue = currentIndex + 1;
              // setcurrentIndex(tmpValue)
              onNext(tmpValue);
            }}
          ></button>
        </div>
      </div>
    </div>
  );
}

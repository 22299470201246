import { createSlice } from "@reduxjs/toolkit";

const defaultData = {
  memberId: null,
  points: 0,
  payload: {},
};

export const QuizManiaSlice = createSlice({
  name: "QuizMania",
  initialState: defaultData,
  reducers: {
    setQuizMania: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        console.log(`${key}: ${value}`);
        state[key] = value;
      }
    },
    resetQuizMania: () => defaultData,
  },
});

// Action creators are generated for each case reducer function
export const { setQuizMania, resetQuizMania } = QuizManiaSlice.actions;

export default QuizManiaSlice.reducer;

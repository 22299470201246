import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";

import dogFace from "../../assets/images/icon/dogFace.svg";
import { SearchId } from "../../services/SearchId";
// import { tempLineId1 } from "../../services/Api";
import { SearchPetDetail } from "../../services/Dogbook";
import getTime from "../../functions/getTime";
import { useLiff } from "react-liff";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SearchPetDiaryById, DeletePetDiary } from "../../services/PetDaily";
import dayjs from "dayjs";
import helper from "../../functions/helper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import BottomNavigation from "@mui/material/BottomNavigation";
import TopLogo from "../../components/layout/TopLogo";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useRef } from "react";
import { styled } from "@mui/material/styles";
import CustomPopup from "../../components/popup/CustomPopup";
import Exit from "../../assets/images/icon/Exit";

export default function PetDiaryView() {
  //ช้อมูลผู้ใช้ - info/ สมุดที่อยู่ - address
  const [searchParams] = useSearchParams();
  let [dailyInfo, setDailyInfo] = useState([]);
  const navigate = useNavigate();

  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;
  const inputEl = useRef(null);

  // popup handle
  const [activeIndex, setSetActiveIndex] = React.useState(0);
  const [petId, setPetId] = React.useState(null);
  const [selectDiaryId, setSelectDiaryId] = React.useState(null);
  const [openCancelPopup, setOpenCancelPopup] = React.useState(false);

  const qMemberId = searchParams.get("memberId");
  const qDiaryDate = searchParams.get("diaryDate");
  const qPetDiaryId = searchParams.get("petDiaryId");

  useEffect(() => {
    getPetDiaryById();
    setSelectDiaryId(qPetDiaryId);
  }, []);

  async function getPetDiaryById() {
    return await SearchPetDiaryById({
      params: { memberId: qMemberId, diaryDate: qDiaryDate },
    })
      .then(async (response) => {
        const resData = response.data;
        if (resData.resultCode == 20000) {
          console.log("resData.body >>", resData.body);
          setDailyInfo(resData.body);
          if (Array.isArray(resData.body)) {
            const index = resData.body.findIndex(
              (item) => item.petDiaryId == qPetDiaryId
            );
            console.log("index >>", resData.body);
            console.log("index >>", index);
            if (resData.body[index]?.pet?.petId) {
              setPetId(resData.body[index]?.pet?.petId);
            }
            setTimeout(() => {
              pageSlideTo(index);
            }, 100);
          }
        } else {
          navigate(-1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function DailyInfo(title, data, subtitle) {
    return (
      <div className="flex flex-col justify-between items-start">
        <p
          className={`text-gray text-[20px] whitespace-pre-line leading-[24px]`}
        >
          {title}
        </p>
        {subtitle ? (
          <div className="text-gray text-[12px] mb-[4px]">
            เพื่อการป้องกัน เห็บ หมัด ไรขี้เรื้อนเปียก ไรขี้เรื้อนแห้ง ไรหู
            พยาธิหนอนหัวใจ พยาธิปากขอ และอื่นๆ
          </div>
        ) : (
          <></>
        )}
        {data ? (
          <p className="ml-4 text-left text-darkGray text-[20px] whitespace-pre-line leading-[24px]">
            {data}
          </p>
        ) : (
          <p className="ml-4 text-darkGray ml- text-[20px]">-</p>
        )}
      </div>
    );
  }

  function DailyActivty(title, data = []) {
    data = data.map((item) => item.activity);
    let actMap = helper.toActivty(data);
    console.log("actMap >>", actMap);
    return (
      <div className="flex flex-col justify-between items-start">
        <p className="text-gray text-[20px] whitespace-pre-line leading-[24px]">
          {title}
        </p>
        <div className="grid grid-cols-3 gap-x-[12px] gap-y-[12px] mt-[4px] ml-[16px] mb-[4px] ">
          {Array.isArray(actMap) &&
            actMap.map((item) => {
              return (
                <div className="w-full flex gap-[5px] items-center justify-center py-[2px] px-[24px] rounded-[28px] border-[1px] border-blue">
                  <p className="text-blue text-[16px] whitespace-nowrap">
                    {item.name}
                  </p>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
  function DailySkin(title, data = "") {
    console.log("data >>", data);
    try {
      data = data.split(",");
    } catch (e) {
      data = [data];
    }
    let skinList = data;
    console.log("skinList >>", skinList);
    if (Array.isArray(skinList)) {
      skinList = skinList.filter((t) => t);
    }

    return (
      <div className="flex flex-col justify-between items-start">
        <p className="text-gray text-[20px] whitespace-pre-line leading-[24px]">
          {title}
        </p>
        <div className="grid grid-cols-1 gap-x-[12px] gap-y-[12px] mt-[4px] ml-[16px] mb-[4px] ">
          {Array.isArray(skinList) && (
            <>
              {skinList.length ? (
                skinList.map((name) => {
                  return (
                    <div className="w-full flex gap-[5px] items-center justify-center py-[2px] px-[24px] rounded-[28px] border-[1px] border-blue w-fit">
                      <p className="text-blue text-[16px] whitespace-nowrap text-ellipsis">
                        {name}
                      </p>
                    </div>
                  );
                })
              ) : (
                <div>-</div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }

  function pageSlideTo(index) {
    if (inputEl.current?.swiper) {
      inputEl.current.swiper.slideTo(index);
      setSetActiveIndex(index);
    }
  }

  async function deletePetDiaryById() {
    const payload = { petDiaryId: selectDiaryId };
    console.log("payload >>", payload);
    try {
      const { data } = await DeletePetDiary({ data: payload });
      console.log("resData.data >>", data);
      if (data.resultCode === 20000) {
        setOpenCancelPopup(false);
        getPetDiaryById();
      }
    } catch (error) {
      console.log("create pet error", error);
    }
  }

  const DailyCarousal = () => {
    return (
      <Swiper
        ref={inputEl}
        spaceBetween={13}
        // slidesPerView={1.08}
        pagination={true}
        modules={[Pagination]}
        scrollbar={{ draggable: true }}
        onInit={(swiper) => {
          swiper.slideTo(activeIndex, 0);
        }}
        onSlideChange={(swiper) => {
          if (swiper.activeIndex !== activeIndex) {
            setSetActiveIndex(swiper.activeIndex);
            if (dailyInfo[swiper.activeIndex]?.pet?.petId) {
              setPetId(dailyInfo[swiper.activeIndex]?.pet?.petId);
            }
          }
        }}
      >
        {dailyInfo &&
          dailyInfo.map((item, i) => (
            <SwiperSlide key={item.id} virtualIndex={i}>
              <div className="bg-white rounded-[4px] relative pt-[68px] mt-[75px]">
                {item.pet?.urlPicture ? (
                  <img
                    className="absolute top-[-50px] left-0 right-0 mx-auto rounded-full w-[118px] h-[118px] object-cover"
                    src={
                      item.pet?.urlPicture.startsWith("data:image/png;base64")
                        ? item.pet?.urlPicture
                        : `data:image/png;base64,${item.pet?.urlPicture}`
                    }
                    alt=""
                  />
                ) : (
                  <div className="absolute top-[-50px] left-0 right-0 mx-auto rounded-full h-[118px] w-[118px] bg-blueGray flex justify-center items-center">
                    <img className="w-[60px]" src={dogFace} alt="" />
                  </div>
                )}
                <p className="text text-center text-[24px] text-left text-lightBlue">
                  {item.pet?.petName}
                </p>

                <div className="p-[16px] pb-[36px]">
                  <p className="text-[24px] text-center text-lightBlue">
                    {item.petName}
                  </p>
                  <div className="flex flex-col gap-[8px] mt-[10px]">
                    {DailyActivty("กิจกรรม", item.petDiaryActivity)}
                    {DailyInfo(
                      "สถานที่",
                      item.clinicPetShop?.shopName +
                        (item.clinicPetShopOther
                          ? `: ${item.clinicPetShopOther}`
                          : "")
                    )}
                    {DailyInfo(
                      "น้ำหนัก",
                      item.weight ? Number(item.weight) + " กิโลกรัม" : ""
                    )}
                    {/* {DailyInfo("เลือด", item.blood)} */}
                    {/* {DailyInfo("สภาพผิว", item.skin)} */}
                    {DailySkin("สภาพผิว", item.skin)}
                    {DailyInfo("ข้อสังเกต", item.remark)}
                    {DailyInfo(
                      "วันที่พาน้องหมาไปพบสัตวแพทย์",
                      item.vaccineDate
                        ? dayjs(item.vaccineDate).format("DD/MM/YYYY")
                        : null,
                      "เพื่อการป้องกัน เห็บ หมัด ไรขี้เรื้อนเปียก ไรขี้เรื้อนแห้ง ไรหู พยาธิหนอนหัวใจ พยาธิปากขอ และอื่นๆ"
                    )}

                    {DailyInfo("วัคซีนที่ฉีด", item.vaccineName)}
                    <hr className="border-b-1px border-lightGray w-full my-[3px]" />
                    {DailyInfo("บันทึกประจำวัน", item.dairyRecord)}
                    <div
                      className="w-fit flex gap-[5px] items-center justify-center py-[2px] px-[15px] rounded-[28px] border-[1px] border-lightRed mt-[36px]"
                      onClick={() => {
                        setOpenCancelPopup(true);
                        setSelectDiaryId(item.petDiaryId);
                      }}
                    >
                      {/* <p className="text-red text-[20px] whitespace-nowrap pt-[2px]" style={{ color: '#FF2B1E'}} >✖</p> */}
                      <div className="pt-[2px]">
                        <Exit />
                      </div>
                      <p
                        className="text-red text-[20px] whitespace-nowrap"
                        style={{ color: "#FF2B1E" }}
                      >
                        ลบบันทึก
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    );
  };

  return (
    <>
      <CustomPopup
        title={"ยืนยันการลบบันทึกสุขภาพ"}
        desc={
          "คุณต้องการลบข้อมูลบันทึกสุขภาพของน้องหมาหรือไม่?\nหากมั่นใจแล้ว กรุณากด ยืนยัน เพื่อดำเนินการต่อ"
        }
        cancelFunction={() => {
          setOpenCancelPopup(false);
        }}
        actionFunction={() => {
          // navigate(-1);
          deletePetDiaryById();
          setOpenCancelPopup(false);
        }}
        open={openCancelPopup}
        onClose={() => {
          setOpenCancelPopup(false);
        }}
      />
      <div id="dogbook-page" className="">
        <TopLogo />
        {/* <TopBanner type="dogbook" /> */}
        <div id="dogbook" className="gradientBg mt-[0px] pt-[24px]">
          <div className="">
            <p className="text-[36px] text-white text-center leading-[36px]">
              บันทึกสุขภาพ
            </p>
            <p className="text-[20px] text-white text-center leading-[20px]">
              วันที่{" "}
              {dailyInfo.length > 0 &&
                dayjs(dailyInfo[0].dairyDate).format("DD/MM/YYYY")}
            </p>
          </div>

          <div className="px-[20px] pb-[36px]">
            {dailyInfo && <DailyCarousal />}
          </div>
        </div>
        <div style={{ marginTop: 80 }}>
          <Box>
            <Paper
              sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1,
              }}
              elevation={1}
            >
              <BottomNavigation
                className="px-[17px]"
                style={{ gap: "10px", alignItems: "center", height: "90px" }}
              >
                <button
                  onClick={() => navigate("/pet-diary")}
                  className="flex justify-center items-center border-[1px] border-blue rounded-[28px] w-full gap-[10px] py-[8px]"
                >
                  {/* <DogPaw width={20} color="#FFFFFF" /> */}
                  <p className="text-[24px] text-blue text-center">ย้อนกลับ</p>
                </button>
                <button
                  onClick={() => {
                    if (petId) {
                      return navigate(
                        `/pet-diary-form?diaryDate=${qDiaryDate}&petId=${petId}`
                      );
                    }
                    return navigate(`/pet-diary-form?diaryDate=${qDiaryDate}`);
                  }}
                  className="flex justify-center items-center bg-blue rounded-[28px] w-full gap-[10px] py-[8px]"
                >
                  {/* <DogPaw width={20} color="#FFFFFF" /> */}
                  <p className="text-[24px] text-white text-center">
                    เขียนเพิ่ม
                  </p>
                </button>
              </BottomNavigation>
            </Paper>
          </Box>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { SearchId } from "../../services/SearchId";
import { useLiff } from "react-liff";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import helper from "../../functions/helper";
import TopLogo from "../../components/layout/TopLogo";
import QRCodeItem from "../../components/general/QRCodeItem";
import {
  SearchShopProfile,
  SearchEmployeeDetail,
} from "../../services/MemberCardOwner";

export default function GenerateQRCodeMain() {
  // api state
  const [clinicPetShop, setClinicPetShop] = React.useState(null);
  const [employee, setEmployee] = React.useState(null);
  const [employees, setEmployees] = React.useState(null);

  const [searchParams] = useSearchParams();
  let [QRCodeValue, setQRCodeValue] = useState("");
  const navigate = useNavigate();

  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  useEffect(() => {
    getSearchId();
  }, []);

  React.useEffect(() => {
    getSearchId();
  }, []);

  async function getSearchId() {
    return await SearchId({
      params: { lineId: lineId || null },
    })
      .then(async (response) => {
        if (!response.data.body.employeeId) {
          window.location.replace("/register");
        } else {
          getShopProfile(
            response.data.body.employeeId,
            response.data.body.clinicPetShopId
          );
          getEmployeeDetail(response.data.body.clinicPetShopId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getShopProfile(employeeId, clinicPetShopId) {
    return await SearchShopProfile({
      params: { lineId: lineId || null },
      employeeId: employeeId,
      clinicPetShopId: clinicPetShopId,
    })
      .then(async (response) => {
        setClinicPetShop(response.data.body.clinicPetShop);
        setEmployee(response.data.body.employee);
        genQRCodeValue(response.data.body.clinicPetShop?.clinicPetShopId);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getEmployeeDetail(clinicPetShopId) {
    return await SearchEmployeeDetail({
      params: { lineId: lineId || null },
      clinicPetShopId: clinicPetShopId,
    })
      .then(async (response) => {
        if (response.data.resultCode === 20000) {
          setEmployees(response.data.body);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const genQRCodeValue = (shopId) => {
    const value = `NGS${helper.numberWithZero(shopId, 5)}D${dayjs().format(
      "DDMMYYHHmmss"
    )}`;
    setQRCodeValue(value);
  };

  const exitLineApp = () => {
    liff.closeWindow();
  };

  return (
    <div id="generate-qr-code-page" className="page-f-screen">
      <TopLogo />
      <div
        id="generate-qr-code"
        className="gradientBg mt-[0px] pt-[24px] pb-[36px] px-[13px] flex-1"
      >
        <div className="bg-white relative round-[4px] h-full py-[24px] flex flex-col justify-center">
          <div className="flex-1">
            <div className="mb-[24px]">
              <p className="text-[36px] text-lightBlue text-center leading-[120%] mb-[4px]">
                QR Code สะสม NexCoin
              </p>
              <p className="text-[20px] text-darkGray text-center leading-[100%]">
                นำ QR Code ด้านล่างนี้ให้ลูกค้าสแกน
              </p>
              <p className="text-[20px] text-darkGray text-center leading-[100%]">
                วิธีสแกน ให้ลูกค้ากดที่เมนู สะสมNexCoin&แลกของรางวัล
              </p>
            </div>
            {/* {QRCodeValue} */}
            <div className="QR CODE flex justify-center">
              <div className="p-[12px]">
                <QRCodeItem value={QRCodeValue} style={{ width: "90%" }} />
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <button
              onClick={exitLineApp}
              className="flex justify-center items-center border-[1px] border-blue rounded-[28px] w-full gap-[10px] py-[8px] w-1/2"
            >
              <p className="text-[24px] text-blue text-center">ปิด</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

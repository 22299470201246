import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

import dogFace from "../../assets/images/icon/dogFace.svg";
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import calendar from "../../assets/images/icon/calendar.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DogPaw from "../../assets/images/icon/DogPaw";
import SecondaryButton from "../../components/button/SecondaryButton";
import PrimaryButton from "../../components/button/PrimaryButton";
import TopBanner from "../../components/layout/TopBanner";
import { useNavigate } from "react-router-dom";
import dogHeart from "../../assets/images/dogbook/dog-heart.png";
import CustomPopup from "../../components/popup/CustomPopup";
import {
  postCreatePet,
  SearchDogBreed,
  SearchFavoriteProduct,
  SearchPetDetail,
} from "../../services/Dogbook";
// import { tempLineId1 } from "../../services/Api";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { SearchId } from "../../services/SearchId";
import { useLiff } from "react-liff";
import TopLogo from "../../components/layout/TopLogo";
import helper from "../../functions/helper";
import dayjs from "dayjs";

const initialInputValues = {
  urlPicture: null,
  memberId: "",
  petName: "",
  dogBreedId: "",
  birthday: null,
  weight: "",
  favoriteProductId: "",
  isNoti: false,
  lastDateProduct: null,
  openSlide: true,
};

const initialErrorInput = {
  urlPicture: false,
  memberId: false,
  petName: false,
  dogBreedId: false,
  birthday: false,
  weight: false,
  favoriteProductId: false,
  lastDateProduct: false,
};

export default function AddDog() {
  const [isAdded, setIsAdded] = useState(false);
  const navigate = useNavigate();

  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  const [openCancelPopup, setOpenCancelPopup] = React.useState(false);
  const [memberId, setMemberId] = React.useState(null);

  //api data
  const [dogBreedData, setDogBreedData] = useState([]);
  const [favProductData, setFavProductData] = useState([]);

  const [userInputs, setUserInputs] = useState([{ ...initialInputValues }]);
  const [errorInputs, setErrorInputs] = useState([{ ...initialErrorInput }]);
  let [petDetail, setPetDetail] = useState([]);

  const addDogForm = () => {
    setUserInputs([...userInputs, { ...initialInputValues }]);
    setErrorInputs([...errorInputs, { ...initialErrorInput }]);
  };

  const removeDogForm = (index) => {
    const userInputList = [...userInputs];
    const errorInputList = [...errorInputs];

    userInputList.splice(index, 1);
    errorInputList.splice(index, 1);

    setUserInputs(userInputList);
    setErrorInputs(errorInputList);
  };

  const handleUserInputChange = (
    event,
    index,
    fieldName = null,
    newValue = null
  ) => {
    let { name, value } = event?.target ?? { name: fieldName, value: newValue };
    const updatedUserInputs = [...userInputs];
    updatedUserInputs[index][name] = value;

    setUserInputs(updatedUserInputs);
  };

  const toggleOpenSlide = (value, index) => {
    const updatedUserInputs = [...userInputs];
    updatedUserInputs[index]["openSlide"] = value;

    setUserInputs(updatedUserInputs);
  };

  function validateInputs() {
    let valid = true;
    let updatedErrors = [];
    for (var index = 0; index < userInputs.length; index++) {
      let updatedError = {
        // urlPicture: userInputs[index].urlPicture !== null ? false : true,
        petName: userInputs[index].petName !== "" ? false : true,
        dogBreedId: userInputs[index].dogBreedId !== "" ? false : true,
        birthday: userInputs[index].birthday !== null ? false : true,
        weight: userInputs[index].weight !== "" ? false : true,
        favoriteProductId:
          userInputs[index].favoriteProductId !== "" ? false : true,
        lastDateProduct:
          userInputs[index].lastDateProduct !== null ? false : true,
      };
      updatedErrors.push(updatedError);
      valid = !Object.values(updatedError).some((field) => field);
    }

    setErrorInputs(updatedErrors);

    return valid;
  }

  useEffect(() => {
    getSearchId();
    getDogBreed();
    getFavProduct();
    //
  }, []);

  async function getSearchId() {
    return await SearchId({
      params: { lineId: lineId || null },
    })
      .then(async (response) => {
        if (!response.data.body.memberId) {
          window.location.replace("/register");
        } else {
          getPetDetail(response.data.body.memberId);
          setMemberId(response.data.body.memberId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getPetDetail(member_id) {
    return await SearchPetDetail({
      params: { lineId: lineId || null },
      member_id: member_id,
    })
      .then(async (response) => {
        if (response.data.resultCode === 20000) {
          setPetDetail(response.data.body);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getDogBreed(keyword) {
    return await SearchDogBreed({
      params: { lineId: lineId || null },
      keyword: keyword,
    })
      .then(async (response) => {
        setDogBreedData(response.data.body);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getFavProduct() {
    return await SearchFavoriteProduct({
      // params: { lineId: tempLineId1 },
    })
      .then(async (response) => {
        setFavProductData(response.data.body);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function createPet() {
    if (validateInputs()) {
      console.log("pass");
    } else {
      console.log("failed");
      return;
    }

    userInputs.forEach(async (userInput) => {
      helper.toImageSmall();
      const payload = {
        ...userInput,
        urlPicture: userInput.urlPicture
          ? await helper.toImageSmall(userInput.urlPicture)
          : null,
        memberId: memberId,
        isNoti: userInput.lastDateProduct ? true : false,
        birthday: helper.postUTC(userInput.birthday),
        notifyTime: (() => {
          if (userInput.lastDateProduct) {
            const _lastDateProduct = dayjs(userInput.lastDateProduct).add(
              0,
              "month"
            );
            return helper.postUTC(_lastDateProduct);
          }
          return null;
        })(),
      };
      console.log("payload >>", payload);
      delete payload.openSlide;

      try {
        const { data } = await postCreatePet({ data: payload });
        console.log("resData.data >>", data);
        if (data.resultCode === 20000) {
          setIsAdded(true);
          console.log("resData.data >>", data.body);
        }
      } catch (error) {
        console.log("create pet error", error);
      }
    });
  }

  function fnRenderOption(props, option, { inputValue }) {
    const breed1 = `${option.name}`;

    const matches1 = match(breed1, inputValue, { insideWords: true });
    const parts1 = parse(breed1, matches1);

    return (
      <li {...props} className="text-left pl-1 border-address-list">
        <div>
          {parts1.map((part, index) => (
            <span
              key={index}
              style={{
                fontWeight: part.highlight ? 700 : 400,
                color: part.highlight ? "#0A4197" : "#8F8F8F",
              }}
            >
              {part.text}
            </span>
          ))}
        </div>
      </li>
    );
  }

  const handleImage = (event, index) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const updatedUserInputs = [...userInputs];
      updatedUserInputs[index]["urlPicture"] = reader.result.substring(
        reader.result.indexOf(",") + 1
      );

      setUserInputs(updatedUserInputs);
    };
    reader.readAsDataURL(file);
  };

  const yesterday = new Date();

  return (
    <div id="adddog-page">
      <CustomPopup
        title={"ยกเลิกการเพิ่มน้องหมา"}
        desc={
          "คุณกำลังยกเลิกการเพิ่มข้อมูลของน้องหมาตัวใหม่ หากกด ยืนยัน แล้วข้อมูลของน้องหมาจะไม่ถูกบันทึก"
        }
        cancelFunction={() => {
          setOpenCancelPopup(false);
        }}
        actionFunction={() => {
          navigate(-1);
          setOpenCancelPopup(false);
        }}
        open={openCancelPopup}
        onClose={() => {
          setOpenCancelPopup(false);
        }}
      />
      {isAdded ? (
        <div
          onClick={() => window.open("/dogbook", "_self")}
          className="gradientBg flex flex-col items-center pt-[62px] min-h-[calc(100vh-60px)] px-[40px]"
        >
          <p className="text-center text-white text-[36px]">
            เพิ่มน้องหมาในสมุดพกแล้ว
          </p>
          <img
            className="w-[157px] mt-[37px] mb-[70px]"
            src={dogHeart}
            alt=""
          />
          <button className="text-white text-[24px] text-center py-[9px] w-full rounded-[28px] bg-[#082E68]/[0.24]">
            กลับไปที่สมุดพกน้องหมา
          </button>
        </div>
      ) : (
        <div>
          <TopLogo />
          <TopBanner type="dogbook" />
          <div id="add-dog" className="relative mt-[-20px] px-[20px]">
            <div className="bg-white pt-[24px] rounded-[4px] mb-[36px]">
              <p className="text-lightBlue text-[28px] text-left px-[17px]">
                เพิ่มน้องหมา
              </p>
              <div>
                {userInputs.map((userInput, index) => (
                  <div key={index}>
                    <div
                      onClick={() => {
                        toggleOpenSlide(!userInput.openSlide, index);
                      }}
                      className="mt-[12px] bg-lightGray2 py-[6px] px-[17px] flex items-center justify-between"
                    >
                      <p className="text-blue text-[20px] text-left">
                        เพิ่มน้องหมาตัวที่ {petDetail.length + (index + 1)}
                      </p>
                      <FontAwesomeIcon
                        icon={userInput.openSlide ? faChevronUp : faChevronDown}
                        className="w-[13px] text-blue"
                      />
                    </div>

                    <div
                      className={`transition-all duration-200 slide${
                        userInput.openSlide ? " down" : " up"
                      }`}
                    >
                      <div className="relative mt-[24px] flex justify-center">
                        {userInputs.length > 1 && (
                          <div
                            onClick={() => {
                              removeDogForm(index);
                            }}
                            className="cursor-pointer flex gap-[5px] items-center right-[20px] top-[0px] absolute rounded-full border-[1px] border-red py-[1px] px-[12px]"
                          >
                            <FontAwesomeIcon
                              icon={faXmark}
                              className="w-[12px] text-red"
                            />
                            <p className="text-red text-[18px]">ลบ</p>
                          </div>
                        )}
                        {userInput.urlPicture !== null ? (
                          <div className="relative flex flex-col justify-center items-center h-[118px] w-[118px]">
                            <img
                              className="rounded-full object-cover h-[118px] w-[118px]"
                              src={`data:image/png;base64,${userInput.urlPicture}`}
                              alt=""
                            />
                            <p className="absolute bottom-[15px] text-white text-[16px]">
                              เปลี่ยนรูปภาพ
                            </p>
                            <input
                              name="urlPicture"
                              className="opacity-0 absolute left-0 top-0 bottom-0 right-0"
                              type="file"
                              onChange={(event) => handleImage(event, index)}
                            />
                          </div>
                        ) : (
                          <div className="relative flex flex-col justify-center items-center gap-[5px] rounded-full h-[118px] w-[118px] bg-blueGray">
                            <img className="w-[60px]" src={dogFace} alt="" />
                            <p className="text-blue text-[16px]">
                              อัพโหลดรูปภาพ
                            </p>
                            <input
                              className="opacity-0 absolute left-0 top-0 bottom-0 right-0"
                              name="urlPicture"
                              type="file"
                              onChange={(event) => handleImage(event, index)}
                            />
                          </div>
                        )}
                      </div>

                      <div className="pb-[24px] px-[20px]">
                        <div className="flex flex-col gap-[30px]">
                          <TextField
                            name="petName"
                            className="w-full text-[20px]"
                            label="ชื่อน้องหมา"
                            error={errorInputs[index].petName}
                            helperText="ชื่อน้องหมาไม่ถูกต้อง"
                            value={userInput.petName}
                            onChange={(event) =>
                              handleUserInputChange(event, index)
                            }
                            required
                            variant="standard"
                          />

                          {dogBreedData && (
                            <Autocomplete
                              disablePortal
                              freeSolo
                              name="dogBreedId"
                              value={
                                dogBreedData.filter(
                                  (data) => data.id === userInput.dogBreedId
                                )[0] || ""
                              }
                              onChange={(event, value) => {
                                handleUserInputChange(
                                  null,
                                  index,
                                  "dogBreedId",
                                  value?.id ?? ""
                                );
                              }}
                              options={dogBreedData}
                              getOptionLabel={(item) => item.name || ""}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onChange={(e) => {
                                    getDogBreed(e.target.value);
                                  }}
                                  className="w-full text-[20px]"
                                  label="สายพันธุ์"
                                  name="dogBreedId"
                                  required
                                  variant="standard"
                                  error={errorInputs[index].dogBreedId}
                                  helperText="สายพันธุ์ไม่ถูกต้อง"
                                />
                              )}
                              renderOption={fnRenderOption}
                            />
                          )}

                          <div className="relative">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <MobileDatePicker
                                className="w-full"
                                label="วัน เดือน ปี เกิดน้องหมา"
                                variant="standard"
                                inputFormat="MM/DD/YYYY"
                                maxDate={yesterday.setDate(
                                  yesterday.getDate() - 1
                                )}
                                name="birthday"
                                value={userInput.birthday}
                                onChange={(value) => {
                                  handleUserInputChange(
                                    null,
                                    index,
                                    "birthday",
                                    value.toISOString()
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    variant="standard"
                                    name="birthday"
                                    {...params}
                                    error={errorInputs[index].birthday}
                                    helperText="วันเกิดไม่ถูกต้อง"
                                  />
                                )}
                              />
                              <img
                                className={
                                  `absolute right-[10px] bottom-[15px] w-[15px] pointer-events-none ` +
                                  (errorInputs[index].birthday
                                    ? "bottom-[35px]"
                                    : null)
                                }
                                src={calendar}
                                alt=""
                              />
                            </LocalizationProvider>
                          </div>

                          <TextField
                            className="w-full text-[20px]"
                            label="น้ำหนัก"
                            type="number"
                            value={userInput.weight}
                            onChange={(event) =>
                              handleUserInputChange(event, index)
                            }
                            variant="standard"
                            error={errorInputs[index].weight}
                            helperText="น้ำหนักไม่ถูกต้อง"
                            name="weight"
                          />

                          <div>
                            <FormControl
                              fullWidth
                              error={errorInputs[index].favoriteProductId}
                            >
                              <InputLabel style={{ marginLeft: "-14px" }}>
                                ชื่อผลิตภัณฑ์ป้องกันปรสิตที่ใช้ประจำ
                              </InputLabel>
                              <Select
                                displayEmpty
                                IconComponent={KeyboardArrowDownIcon}
                                fullWidth
                                name="favoriteProductId"
                                value={
                                  parseInt(userInput.favoriteProductId) || ""
                                }
                                // value={
                                //   favProductData.filter(item => item.id === userInput.favoriteProductId)[0]['name']
                                // }
                                onChange={(event) =>
                                  handleUserInputChange(event, index)
                                }
                                variant="standard"
                              >
                                {favProductData &&
                                  favProductData.map((item, i) => (
                                    <MenuItem value={item.id} key={i}>
                                      {item.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                              <FormHelperText>
                                ชื่อผลิตภัณฑ์ไม่ถูกต้อง
                              </FormHelperText>
                            </FormControl>
                          </div>
                        </div>

                        <div className="flex flex-col gap-[0px] mt-[24px]">
                          <p className="text-[24px] text-left">
                            ให้เราช่วยเตือนนัดพบคุณหมอ
                          </p>
                          <div className="relative">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <MobileDatePicker
                                className="w-full"
                                label="วันที่นัดพบคุณหมอ"
                                variant="standard"
                                inputFormat="MM/DD/YYYY"
                                name="lastDateProduct"
                                value={userInput.lastDateProduct}
                                onChange={(value) => {
                                  handleUserInputChange(
                                    null,
                                    index,
                                    "lastDateProduct",
                                    value.toISOString()
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    variant="standard"
                                    {...params}
                                    error={errorInputs[index].lastDateProduct}
                                    helperText="วันที่นัดพบคุณหมอไม่ถูกต้อง"
                                  />
                                )}
                              />
                              <img
                                className={
                                  `absolute right-[10px] bottom-[15px] w-[15px] pointer-events-none ` +
                                  (errorInputs[index].lastDateProduct
                                    ? "bottom-[35px]"
                                    : null)
                                }
                                src={calendar}
                                alt=""
                              />
                            </LocalizationProvider>
                          </div>
                        </div>

                        {index + 1 === userInputs.length && (
                          <div className="mt-[35px]">
                            <p className="text-[24px] text-left">
                              ที่บ้านมีน้องหมาอีกหรือเปล่า?
                            </p>
                            <button
                              onClick={addDogForm}
                              className="mt-[12px] flex justify-center items-center bg-lightBlue2 rounded-[28px] w-full gap-[10px] py-[8px]"
                            >
                              <DogPaw width={20} color="#0A4197" />
                              <p className="text-[24px] text-blue text-center">
                                เพิ่มน้องหมา
                              </p>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex itesm-center gap-[13px] justify-center bg-white px-[20px] pt-[16px] pb-[24px]">
            <SecondaryButton
              variant="outlined"
              className="w-full"
              onClick={() => setOpenCancelPopup(true)}
            >
              ยกเลิก
            </SecondaryButton>
            <PrimaryButton
              onClick={() => createPet()}
              variant="outlined"
              className="w-full"
            >
              บันทึก
            </PrimaryButton>
          </div>
        </div>
      )}
    </div>
  );
}

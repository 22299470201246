import React, { useEffect, useState, useRef } from "react";

export default function QuizManiaEmpty() {
  return (
    <div id="quizManiaEmpty">
      <div className="bPicEmpty">
        <i></i>
      </div>
      <div className="bTitle">
        <p className="tTitle">ยังไม่มีอันดับคะแนน</p>
        <p>กรุณากลับมาเช็กข้อมูลอีกครั้งในโอกาสหน้า</p>
      </div>
    </div>
  );
}

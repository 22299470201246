import { Dialog, Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import PrimaryButton from "../button/PrimaryButton";
import SecondaryButton from "../button/SecondaryButton";
import CircleExit from "../../assets/images/icon/CircleExit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import BottomNavigation from "@mui/material/BottomNavigation";
import CustomSwitch from "../../components/button/CustomSwitch";
import LogoNexMix from "../../assets/images/logo-nex-text-mix.png";
import { SearchId, UpdateMemberWelcomeBack } from "../../services/SearchId";
import { SearchPetOwnerProfile } from "../../services/Dogbook";
import { SearchDdlEvent } from "../../services/Dropdown";
import { useNavigate, useLocation } from "react-router-dom";
import globalStyle from "../../assets/styles/constants";
import Paper from "@mui/material/Paper";
import { useLiff } from "react-liff";

import PolicyPopup from "./PolicyPopup";
import helper from "../../functions/helper";

export default function WelcomeBackPopup(props) {
  const navigate = useNavigate();
  const [eventPoint, setEventPoint] = useState(0);
  const [curPoint, setCurPoint] = useState(0);
  const [cookie, setCookie] = useState(false);
  const [isTerm, setIsTerm] = useState(false);
  let [memberId, setMemberId] = useState(null);
  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  const [policy, setPolicy] = React.useState("cookie");
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  useEffect(() => {
    setTimeout(() => {
      getSearchId();
    }, 500);
    //
    return () => {
      console.log("cleaned up!");
    };
  }, []);

  async function getSearchId() {
    return await SearchId({
      params: { lineId: lineId || null },
    })
      .then(async (response) => {
        if (response.data.body.memberId) {
          getOwnerProfile(response.data.body.memberId);
          setMemberId(response.data.body.memberId);
          getSearchDdlEvent("WELCOME_BACK");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getOwnerProfile(member_id) {
    return await SearchPetOwnerProfile({
      params: { lineId: lineId || null },
      member_id: member_id,
    })
      .then(async (response) => {
        const resBody = response.data?.body;
        console.log("resBody >>", resBody);
        setCurPoint(resBody.currentPoint);
        setCookie(!!resBody.cookie);
        setIsTerm(!!resBody.isTerm);
        if (resBody.isTerm === null || resBody.isTerm === undefined) {
          setOpen(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getSearchDdlEvent(type) {
    try {
      const { data } = await SearchDdlEvent({ params: { type } });
      if (+data["resultCode"] === 20000) {
        const body = data.body || [];
        // console.log("body", body);
        if (Object.keys(body).length === 0) {
          setEventPoint(0);
        } else {
          setEventPoint(body[0].point);
        }
      } else {
        setEventPoint(0);
      }
    } catch (error) {
      console.log("getSearchDdlEvent error", error);
    }
  }

  async function postUpdateMemberWelcomeBack() {
    const payload = {
      memberId: memberId,
      isTerm: isTerm,
    };

    try {
      const { data } = await UpdateMemberWelcomeBack({ data: payload });
      console.log("resData.data >>", data);
      if (data.resultCode === 20000) {
        getOwnerProfile(memberId);
      }
      handleClose();
    } catch (error) {
      console.log("UpdateMemberWelcomeBack error", error);
      handleClose();
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const policyHandleYes = (value) => {
    if (value === "cookie") {
      setCookie(true);
    } else {
      setIsTerm(true);
    }
  };

  const policyHandleNo = (value) => {
    if (value === "cookie") {
      setCookie(false);
    } else {
      setIsTerm(false);
    }
  };

  const listCookie = (txt1 = "", txt2 = "", value, setValue, viewpage) => {
    return (
      <div className="flex justify-between">
        <Checkbox
          checked={value}
          onChange={(event) => setValue(!value)}
          inputProps={{ "aria-label": "controlled" }}
          sx={globalStyle.checkboxStyle}
        />
        <div className="w-full text-left pl-[8px]">
          <p className="text-dark text-[20px] font-medium leading-[100%]">
            {txt1}
          </p>
          <p
            className="text-blue text-[18px] leading-[120%]"
            onClick={() => {
              if (viewpage === "cookie") {
                setPolicy("cookie");
              } else {
                setPolicy("term");
              }
              setOpen2(true);
            }}
          >
            {txt2}
          </p>
        </div>
      </div>
    );
  };
  return (
    <Dialog onClose={() => {}} open={open} fullWidth={true} maxWidth="xl">
      <div className="pb-[20px] pt-[24px] px-[17px] rounded-[4px] flex justify-between">
        <div className="w-full">
          <p className="text-lightBlue text-[28px] text-center leading-[100%]">
            ยินดีต้อนรับกลับสู่
          </p>
          <div className="flex justify-center gap-[10px]">
            <p className="text-lightBlue text-[32px] text-center leading-[100%]">
              ครอบครัว
            </p>
            <p className="text-blue text-[32px] text-center leading-[100%]">
              Nex Family
            </p>
            {/* <img className="" src={LogoNexMix} alt="logo" style={{width: "50%"}}/> */}
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="">
          <div className="gradientBlue w-full rounded-[8px] py-[12px]">
            <p className="text-white text-[20px] text-center">
              คุณได้รับ NexCoin
            </p>
            <p className="text-white text-[52px] text-center leading-[100%]">
              {helper.numberWithCommas(eventPoint)} คะแนน
            </p>
          </div>
          <div className="flex justify-between gap-[52px] mt-[8px] mb-[15px]">
            <p className="text-[20px] text-darkGray text-center">
              NexCoin เดิมที่คุณมี
            </p>
            <p className="text-[28px] text-lightBlue text-center leading-[100%]">
              {helper.numberWithCommas(curPoint)} คะแนน
            </p>
          </div>
          <div className="">
            {listCookie(
              "ยอมรับเงื่อนไขกลับสู่ครอบครัว Nex Family",
              "อ่านรายละเอียดนโยบายความเป็นส่วนตัว",
              isTerm,
              setIsTerm,
              "term"
            )}
          </div>
          <PolicyPopup
            open={open2}
            value={policy}
            setOpen={(e) => setOpen2(e)}
            onYes={policyHandleYes}
            onNo={policyHandleNo}
          />
        </div>
      </div>

      <div className="flex itesm-center gap-[13px] justify-center mt-[18px] mb-[24px]">
        <SecondaryButton
          variant="outlined"
          className="w-1/2"
          disabled={!isTerm}
          onClick={postUpdateMemberWelcomeBack}
        >
          ตกลง
        </SecondaryButton>
      </div>
    </Dialog>
  );
}

import React, { useEffect, useState } from "react";
import { SearchId } from "../../services/SearchId";
import { useLiff } from "react-liff";
import TopLogo from "../../components/layout/TopLogo";
import { useNavigate } from "react-router-dom";
import imgMyPointStamp from "../../assets/images/topbanner/my-point-stamp-new.png";
import { SearchBookQrStamp } from "../../services/MyPoint";
import StampCardMain from "./StampCardMain";
import { setMyPoint } from "../../features/MyPointSlice";

// const maxStamp = 6;

export default function MyPointStamp() {
  const navigate = useNavigate();
  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  const [myStampCountList, setMyStampCountList] = useState([]); //set myStampCountList
  const [currentBookStamp, setCurrentBookStamp] = useState(0);

  const clinicLink = "https://bit.ly/NEXStamp";

  // const onExchangeReward = async () => {
  //   const id0 = freebiesId;
  //   const id1 = memberId;
  //   navigate(`/redeem-gift-member-detail/${id0}/${id1}`);
  // };

  const scrollBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    getSearchByLineId();
    //
  }, []);

  const getSearchByLineId = async () => {
    const memberData = await getSearchId();
    if (!memberData.result.memberId) {
      navigate("/register");
    } else {
      const bookQrStamp = await getCountBookQrStamp(memberData.result.memberId);
      if (bookQrStamp.status) {
        setMyStampCountList(bookQrStamp.result.bookStamp);
      }
    }
  };

  const getSearchId = async () => {
    let _return = {
      status: false,
      result: null,
    };
    try {
      const { data } = await SearchId({ params: { lineId: lineId || null } });
      if (data.resultCode === 20000) {
        const resData = data.body;
        console.log("resData >>", resData);
        _return.status = true;
        _return.result = resData;
      }
    } catch (error) {
      console.log(error);
    }
    return _return;
  };

  const getCountBookQrStamp = async (id) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await SearchBookQrStamp({
        params: { memberId: id ?? 0 },
      });
      if (data.resultCode === 20000) {
        const resData = data.resultData;
        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  // const contBTN0 = !(myStampCount >= maxStamp); // กรณี มีค่า mystamp == 10
  // const disabledBTN = contBTN0;

  return (
    <>
      <div id="myPointStampPage">
        <TopLogo />
        <div className="content gradientBg pb-[64px]">
          {/* <TopBanner type="myPointStamp" /> */}
          <div className="bTopBanner">
            <img src={imgMyPointStamp} alt="logo" className="w-full" />
            <button className="btn _outline transparent" onClick={scrollBottom}>
              รายละเอียดกิจกรรม
            </button>
          </div>

          <StampCardMain
            stampCountList={myStampCountList}
            currentIndex={currentBookStamp}
            onBack={(index) => setCurrentBookStamp(index)}
            onNext={(index) => setCurrentBookStamp(index)}
          />

          <div className="pStampDesc">
            <div className="bContentDesc1">
              <h2>รายละเอียดการสะสม NEX Stamp</h2>
              <div className="bDesc">
                <p>
                  1. สแกนเพื่อเช็คอิน เพื่อรับ NexCoin และ NexStamp (สแกน 1
                  ครั้ง รับ NexStamp 1 ดวง)​
                </p>
                <p>
                  2. เมื่อสะสม NexStamp ครม 6 ดวง
                  สามารถใช้สิทธิ์แลกรับของรางวัลได้​
                </p>
                <p>
                  3. หลังใช้สิทธิ์แลกของรางวัลเรียบร้อยแล้ว ท่านสามารถเริ่มสะสม
                  NexStamp ได้ใหม่อีกครั้ง
                </p>
                <p>
                  ​*ระยะเวลาการแลกรับของรางวัล ตั้งแต่วันนี้ถึง 31 มกราคม 2568
                </p>
              </div>
            </div>
            <div className="bContentDesc2">
              <h3>ร้านค้าที่ร่วมรายการ</h3>
              <h4>คลิกเพื่อดูชื่อร้านค้า</h4>
              <a
                style={{ textDecoration: "underline" }}
                href={clinicLink}
                target="_blank"
                rel="noreferrer"
              >
                {clinicLink}
              </a>
            </div>
          </div>

          {/* <div className="pStampDesc">
            <div className="bContentDesc1">
              <div className="bDesc">
                <p>ร้านค้าที่ร่วมรายการ</p>
                <p>
                  <a
                    style={{ textDecoration: "underline" }}
                    href={clinicLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {clinicLink}
                  </a>
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

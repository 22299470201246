import React, { useEffect, useState, useRef } from "react";
import TopLogo from "../../components/layout/TopLogo";
import { Select, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import moment from "moment/moment";
import helper from "../../functions/helper";
import QuizManiaEmpty from "./QuizManiaEmpty";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SearchQuarterLeaderboard } from "../../services/QuizMania";
import { useLiff } from "react-liff";
import QuizManiaRuleDesc from "./QuizManiaRuleDesc";

const CURRENT_YEAR = +moment().format("YYYY");

export default function QuizManiaLock({ onStartQuiz }) {
  const navigate = useNavigate();
  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;
  const OPTION_YEAR = helper.genRange(2024, CURRENT_YEAR).reverse();

  const [isHasAns, setIsHasAns] = useState(false);
  const [year, setYear] = useState(CURRENT_YEAR);
  // const [seasonList, setSeasonList] = useState([...Array(4)]);
  const [seasonList, setSeasonList] = useState([]);

  let [searchParams] = useSearchParams();
  const quizId = searchParams.get("quiz");

  useEffect(() => {
    fetchQuarterLeaderboard();
  }, [year]);

  const fetchQuarterLeaderboard = async () => {
    try {
      const payload = {
        params: { year: year, lineId: lineId, quizId: quizId },
      };
      const resData = await getQuarterLeaderboard(payload);
      setSeasonList(resData.result.quarterLeaderBoard);
      setIsHasAns(resData.result.isSuccessQuiz === 1);
    } catch (err) {
      console.log(err);
    }
  };

  const getQuarterLeaderboard = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await SearchQuarterLeaderboard(payload);
      if (data.resultCode === 20000) {
        const resData = data.resultData;

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  return (
    <div id="quizManiaLockPage">
      <TopLogo />
      <div className="bContentMain">
        <div className="bContent">
          <div className="bPic">
            <i></i>
          </div>
          <div className="bAction">
            <h1>กรุณาเลือก Leaderboard ที่ต้องการดู</h1>
            <div className="bInput">
              <Select
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
                fullWidth
                name="favoriteProductId"
                value={year}
                onChange={(e) => {
                  // handleChangeYear(e.target.value);
                  console.log("e >>", e.target.value);
                  setYear(e.target.value);
                }}
                variant="standard"
              >
                {OPTION_YEAR.map((item, i) => (
                  <MenuItem value={item} key={i}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div className="bLeadList">
            {seasonList.length > 0 ? (
              seasonList.map((item, i) => {
                return (
                  <a
                    onClick={() =>
                      navigate(
                        `/quiz-mania-rank?quiz=${quizId}&year=${year}&quarter=${item.quarter}`
                      )
                    }
                  >
                    Season {item.quarter}
                  </a>
                );
              })
            ) : (
              <QuizManiaEmpty />
            )}
          </div>
        </div>
        <div className="bBtn">
          {isHasAns && seasonList.length ? (
            <button
              className="btn _outline"
              onClick={() => {
                const year = seasonList[0].year;
                const quarter = seasonList[0].quarter;
                navigate(
                  `/quiz-mania-rank?quiz=${quizId}&year=${year}&quarter=${quarter}`
                );
              }}
            >
              เช็คอันดับคะแนนของคุณ
            </button>
          ) : (
            <button
              className="btn _outline"
              onClick={() => {
                onStartQuiz();
              }}
            >
              เข้าร่วมกิจกรรม Quiz
            </button>
          )}
        </div>
        <QuizManiaRuleDesc/>
      </div>
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

import dogFace from "../../assets/images/icon/dogFace.svg";
import { Autocomplete, FormControl, FormHelperText } from "@mui/material";
import {
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import calendar from "../../assets/images/icon/calendar.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DogPaw from "../../assets/images/icon/DogPaw";
import TopBanner from "../../components/layout/TopBanner";
import TopLogo from "../../components/layout/TopLogo";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomPopup from "../../components/popup/CustomPopup";
import CustomPointPopup from "../../components/popup/CustomPointPopup";
import { SearchPetDetail } from "../../services/Dogbook";
import { CreatePetDiary } from "../../services/PetDaily";
import { SearchVaccineConfig } from "../../services/Dropdown";
// import { tempLineId1 } from "../../services/Api";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { SearchId } from "../../services/SearchId";
import { useLiff } from "react-liff";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { SearchDdlFavHospital } from "../../services/Dogbook";

import { useSwiper } from "swiper/react";
import { Controller } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation } from "swiper";
import helper from "../../functions/helper";
import dayjs from "dayjs";

const initialInputValue = {
  diaryDate: null,
  activity: [],
  clinicPetShopId: null,
  clinicPetShopOther: "",
  weight: "",
  blood: "",
  skin: [],
  remark: "",
  vaccineDate: null,
  diaryRecord: "",
  vaccine: null,
};

const initialErrorInput = {
  diaryDate: false,
  activity: false,
  clinicPetShopId: false,
  clinicPetShopOther: false,
  weight: false,
  blood: false,
  skin: false,
  remark: false,
  vaccineDate: false,
  diaryRecord: false,
  vaccine: false,
};

const skinOption = [
  { id: "ผิวหนังปกติ", name: "ผิวหนังปกติ" },
  {
    id: "ผิวหนังแดง เป็นผื่นนูนแดง มีสะเก็ดรังแค",
    name: "ผิวหนังแดง เป็นผื่นนูนแดง มีสะเก็ดรังแค",
  },
  {
    id: "ผิวหนังเหนียวเหนอะหนะ มีขนร่วงมากกว่าปกติ",
    name: "ผิวหนังเหนียวเหนอะหนะ มีขนร่วงมากกว่าปกติ",
  },
  { id: "ผิวหนังมีกลิ่น", name: "ผิวหนังมีกลิ่น" },
];

// const initialVaccineOption = [
//   { id: 1, name: "วัคซีนโรคพิษสุนัขบ้า" },
//   { id: 2, name: "วัคซีนโรคพิษสุนัขบ้ามาก" },
//   { id: 3, name: "วัคซีนโรคพิษสุนัขบ้าๆๆ" },
// ];

export default function PetDailyForm() {
  const [isAdded, setIsAdded] = useState(false);
  const navigate = useNavigate();

  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  const [openCancelPopup, setOpenCancelPopup] = React.useState(false);
  const [memberId, setMemberId] = React.useState(null);
  const [isOther, setIsOther] = React.useState(false);

  //api data
  const [favHospital, setFavHospital] = useState(null);
  const [vaccineOption, setVaccineOption] = useState([]);

  const [petId, setPetId] = useState(null);
  const [userInput, setUserInput] = useState({ ...initialInputValue });
  const [errorInput, setErrorInput] = useState({ ...initialErrorInput });
  const [isSubmit, setIsSubmit] = useState(false);
  const [petDetail, setPetDetail] = useState([]);

  const [openCreatedPopup, setOpenCreatedPopup] = React.useState(false);
  const [openCreatedPointPopup, setOpenCreatedPointPopup] =
    React.useState(false);
  const [eventPoints, setEventPoints] = React.useState(0);
  const [defaultFavHospital, setDefaultFavHospital] = useState(null);
  const [searchParams] = useSearchParams();
  const qyDiaryDate = searchParams.get("diaryDate");
  const qPetId = searchParams.get("petId");
  const inputEl = useRef(null);

  const activityOption = helper.GETCONFIG.OPTION.activityOption;

  // Not Use memberId
  useEffect(() => {
    handleUserInputChange(null, "diaryDate", qyDiaryDate, false);
    getFavHospital();
    //
  }, []);

  // Use memberId
  useEffect(() => {
    getSearchId();
    //
  }, []);

  // get vaccine data
  useEffect(() => {
    getVaccineOption();
    //
  }, []);

  const getVaccineOption = async () => {
    const vaccineData = await searchVaccineOption();
    if (vaccineData.status) {
      setVaccineOption(vaccineData.result);
    } else {
    }
  };

  const searchVaccineOption = async () => {
    let _return = {
      status: false,
      result: null,
    };
    try {
      const { data } = await SearchVaccineConfig();
      if (data.resultCode === 20000) {
        const resData = data.resultData;
        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  async function getSearchId() {
    return await SearchId({
      params: { lineId: lineId || null },
    })
      .then(async (response) => {
        if (!response.data.body.memberId) {
          window.location.replace("/register");
        } else {
          getPetDetail(response.data.body.memberId);
          setMemberId(response.data.body.memberId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getFavHospital(keyword) {
    return await SearchDdlFavHospital({
      params: { lineId: lineId || null },
      keyword: keyword,
    })
      .then(async (response) => {
        setFavHospital(response.data.body);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleUserInputChange = (
    event,
    fieldName = null,
    newValue = null,
    useVali = true
  ) => {
    let { name, value } = event?.target ?? { name: fieldName, value: newValue };
    const updatedUserInput = { ...userInput };
    updatedUserInput[name] = value;
    console.log("updatedUserInput >>", updatedUserInput);
    console.log("handleUserInputChange >>");
    setUserInput(updatedUserInput);
    // if (useVali) {
    //   console.log('useVali >>', useVali)
    //   validateInputs()
    // }
  };

  function validateInputs() {
    let valid = true;
    let updatedError = {
      // urlPicture: userInput.urlPicture !== null ? false : true,
      diaryDate: userInput.diaryDate !== null ? false : true,
      activity: userInput.activity.length > 0 ? false : true,
      clinicPetShopId: userInput.clinicPetShopId !== null ? false : true,
      clinicPetShopOther:
        isOther && (userInput.clinicPetShopOther !== "" ? false : true),
      // weight: userInput.weight !== "" ? false : true,
      // blood: userInput.blood !== "" ? false : true,
      // skin: userInput.skin !== "" ? false : true,
      // remark: userInput.remark !== "" ? false : true,
      // vaccineDate: userInput.vaccineDate !== null ? false : true,
      // diaryRecord: userInput.diaryRecord !== "" ? false : true,
    };
    console.log("updatedError >>", updatedError);
    valid = !Object.values(updatedError).some((field) => field);
    setErrorInput(updatedError);

    return valid;
  }

  async function getPetDetail(member_id) {
    return await SearchPetDetail({
      params: { lineId: lineId || null },
      member_id: member_id,
    })
      .then(async (response) => {
        if (response.data.resultCode === 20000) {
          let index = 0;
          if (Array.isArray(response.data?.body) && qPetId) {
            index = response.data?.body.findIndex(
              (item) => item.petId == qPetId
            );
          }
          setPetDetail(response.data.body);
          setPetId(response.data?.body[index]?.petId ?? null);

          setTimeout(() => {
            pageSlideTo(index);
          }, 100);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function createPetDaily() {
    console.log("payload userInput >>", userInput);
    console.log("payload petId >>", petId);
    if (validateInputs() && petId) {
      console.log("pass");
    } else {
      console.log("failed");
      return;
    }

    if (userInput.diaryDate) {
      userInput.diaryDate = helper.postUTC(userInput.diaryDate);
    }
    if (userInput.vaccineDate) {
      userInput.vaccineDate = helper.postUTC(userInput.vaccineDate);
    }

    const payload = { memberId, petId, ...userInput };
    console.log("payload Success >>", payload);

    if (!payload.clinicPetShopOther) {
      delete payload.clinicPetShopOther;
    }

    try {
      const { data } = await CreatePetDiary({ data: payload });
      console.log("resData.data >>", data);
      if (data.resultCode === 20000) {
        setIsAdded(true);
        if (data.body.isPoint) {
          setOpenCreatedPointPopup(true);
          setEventPoints(data.body.points);
        } else {
          setOpenCreatedPopup(true);
        }
        console.log("resData.data >>", data.body);
      }
    } catch (error) {
      console.log("create pet error", error);
    }
  }

  function fnRenderFavOption(props, option, { inputValue }) {
    const favHospital = `${option.name}`;

    const matches1 = match(favHospital, inputValue, { insideWords: true });
    const parts1 = parse(favHospital, matches1);
    console.log("matches1 >>", matches1);
    return (
      <li {...props} className="text-left pl-1 border-address-list">
        <div>
          {parts1.map((part, index) => (
            <span
              key={index}
              style={{
                fontWeight: part.highlight ? 700 : 400,
                color: part.highlight ? "#0A4197" : "#8F8F8F",
              }}
            >
              {part.text}
            </span>
          ))}
        </div>
      </li>
    );
  }

  const yesterday = new Date();

  function pageSlideTo(index) {
    if (inputEl.current?.swiper) {
      inputEl.current.swiper.slideTo(index);
    }
  }

  return (
    <>
      <CustomPopup
        title={"บันทึกสุขภาพประจำวันเรียบร้อย"}
        desc={"บันทึกสุขภาพประจำวันน้องหมา ได้รับการบันทึกแล้ว"}
        cancelText={"ปิด"}
        cancelFunction={() => {
          navigate("/pet-diary");
          setOpenCreatedPopup(false);
        }}
        open={openCreatedPopup}
        onClose={() => {
          navigate("/pet-diary");
          setOpenCreatedPopup(false);
        }}
      />
      <CustomPointPopup
        title={"บันทึกสุขภาพประจำวันเรียบร้อย"}
        desc={"บันทึกสุขภาพประจำวันน้องหมา ได้รับการบันทึกแล้ว"}
        point={eventPoints}
        cancelText={"ตกลง"}
        cancelFunction={() => {
          navigate("/pet-diary");
          setOpenCreatedPointPopup(false);
        }}
        open={openCreatedPointPopup}
        onClose={() => {
          navigate("/pet-diary");
          setOpenCreatedPointPopup(false);
        }}
      />
      <div id="adddog-page">
        <div>
          <TopLogo />
          <TopBanner type="dogbook" />
          <div id="add-dog" className="relative mt-[-20px] px-[20px]">
            <div className="bg-white pt-[24px] rounded-[4px] mb-[36px]">
              <p className="text-lightBlue text-[28px] text-left px-[17px]">
                บันทึกสุขภาพ
              </p>
              <div>
                <div>
                  <div>
                    <Swiper
                      ref={inputEl}
                      pagination={{
                        type: "",
                      }}
                      navigation={true}
                      modules={[Pagination, Navigation]}
                      className="mySwiper"
                      onSlideChange={(sw) => {
                        console.log("onSlideChange >>!!", sw.snapIndex);
                        console.log("onSlideChange petDetail >>!!", petDetail);
                        const tmpPetId = petDetail[sw.snapIndex]?.petId;
                        setPetId(tmpPetId);
                      }}
                    >
                      {petDetail &&
                        petDetail.map((item, i) => {
                          return (
                            <SwiperSlide key={item.id + i}>
                              <div
                                className="relative mt-[24px] flex justify-center"
                                onClick={() => setPetId(item.petId)}
                              >
                                <div className="relative flex flex-col justify-center items-center h-[118px] w-[118px]">
                                  {item.urlPicture !== null ? (
                                    <img
                                      className="object-cover min-h-[118px]"
                                      style={{
                                        clipPath: "circle()",
                                      }}
                                      src={
                                        item.urlPicture.startsWith(
                                          "data:image/png;base64"
                                        )
                                          ? item.urlPicture
                                          : `data:image/png;base64,${item.urlPicture}`
                                      }
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      className="w-[60px]"
                                      style={{
                                        clipPath: "circle()",
                                      }}
                                      src={dogFace}
                                      alt=""
                                    />
                                  )}
                                  <p className="text text-[20px] text-left px-[17px]">
                                    {item.petName}
                                  </p>
                                </div>
                              </div>
                            </SwiperSlide>
                          );
                        })}
                    </Swiper>
                    <div className="pb-[24px] px-[20px]">
                      <div className="flex flex-col gap-[15px]">
                        <div className="relative">
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={"th"}
                          >
                            <MobileDatePicker
                              className="w-full"
                              label="วัน เดือน ปี ที่บันทึก"
                              variant="standard"
                              inputFormat="MM/DD/YYYY"
                              maxDate={dayjs()}
                              name="diaryDate"
                              value={userInput.diaryDate}
                              onChange={(value) => {
                                handleUserInputChange(
                                  null,
                                  "diaryDate",
                                  value.toISOString()
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  name="diaryDate"
                                  {...params}
                                  error={errorInput.diaryDate}
                                  helperText="กรุณาเลือก วัน เดือน ปี"
                                  required
                                />
                              )}
                            />
                            <img
                              className={
                                `absolute right-[10px] bottom-[15px] w-[15px] pointer-events-none ` +
                                (errorInput.diaryDate ? "bottom-[35px]" : null)
                              }
                              src={calendar}
                              alt=""
                            />
                          </LocalizationProvider>
                        </div>

                        <FormControl error>
                          <InputLabel
                            id="labelId-activity"
                            sx={{ top: "8px", left: "-14px" }}
                          >
                            กิจกรรม
                          </InputLabel>
                          <Select
                            labelId="labelId-activity"
                            id="activity"
                            displayEmpty
                            className="w-full text-[20px] mt-[10px]"
                            IconComponent={KeyboardArrowDownIcon}
                            value={userInput.activity}
                            onChange={(event) => {
                              handleUserInputChange(event);
                            }}
                            error={errorInput.activity}
                            helperText="กรุณาเลือก กิจกรรม"
                            required
                            fullWidth
                            name="activity"
                            variant="standard"
                            multiple
                            renderValue={(selected) => {
                              console.log("selected >>", selected);
                              const sy = {
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              };
                              return (
                                <Box sx={sy}>
                                  {selected.map((value, i) => {
                                    console.log("selected >>", value);
                                    const txt = activityOption.find(
                                      (item) => item.id == value
                                    );
                                    console.log("selected txt >>", txt);
                                    return (
                                      <Chip
                                        key={i}
                                        label={txt.name}
                                        color="primary"
                                      />
                                    );
                                  })}
                                </Box>
                              );
                            }}
                          >
                            {activityOption &&
                              activityOption.map((item, i) => (
                                <MenuItem value={item.id} key={i}>
                                  <Checkbox
                                    checked={userInput.activity.includes(
                                      item.id
                                    )}
                                  />
                                  {item.name}
                                </MenuItem>
                              ))}
                          </Select>
                          {errorInput.activity && (
                            <FormHelperText sx={{ marginLeft: 0 }}>
                              กรุณาเลือก กิจกรรม
                            </FormHelperText>
                          )}
                        </FormControl>

                        {/* <TextField
                        name="clinicPetShopId"
                        className="w-full text-[20px]"
                        label="สถานที่"
                        error={errorInput.clinicPetShopId}
                        value={userInput.clinicPetShopId}
                        onChange={(event) => {
                          handleUserInputChange(event);
                        }}
                        required
                        variant="standard"
                      /> */}

                        {favHospital && (
                          <Autocomplete
                            disablePortal
                            freeSolo
                            defaultValue={defaultFavHospital}
                            // disabled={hasClinic === "no" ? true : false}
                            onChange={(event, newValue) => {
                              console.log("newValue >>", newValue);
                              setIsOther(newValue?.name === "อื่นๆ");
                              if (newValue !== null) {
                                let updatedInput = {
                                  ...userInput,
                                  clinicPetShopId: newValue.id || 0,
                                };
                                setUserInput(updatedInput);
                              } else {
                                let updatedInput = {
                                  ...userInput,
                                  clinicPetShopId: null,
                                };
                                setUserInput(updatedInput);
                              }
                            }}
                            options={favHospital}
                            getOptionLabel={(item) => item.name || ""}
                            renderInput={(params) => (
                              <TextField
                                // disabled={hasClinic === "no" ? true : false}
                                {...params}
                                onChange={(e) => {
                                  getFavHospital(e.target.value);
                                }}
                                className="w-full text-[20px]"
                                label="สถานที่"
                                error={errorInput.clinicPetShopId}
                                helperText="กรุณากรอก สถานที่"
                                required
                                variant="standard"
                              />
                            )}
                            renderOption={fnRenderFavOption}
                          />
                        )}
                        {isOther == true && (
                          <TextField
                            className="w-full text-[20px]"
                            label="โปรดระบุสถานที่"
                            value={userInput.clinicPetShopOther}
                            onChange={(event) => {
                              handleUserInputChange(event);
                            }}
                            variant="standard"
                            error={errorInput.clinicPetShopOther}
                            helperText="กรุณากรอก สถานที่"
                            required={isOther == true}
                            name="clinicPetShopOther"
                          />
                        )}

                        <TextField
                          className="w-full text-[20px]"
                          label="น้ำหนัก"
                          type="number"
                          value={userInput.weight}
                          onChange={(event) => {
                            handleUserInputChange(event);
                          }}
                          variant="standard"
                          error={errorInput.weight}
                          helperText="น้ำหนักไม่ถูกต้อง"
                          name="weight"
                        />

                        {/* <TextField
                        name="blood"
                        className="w-full text-[20px]"
                        label="เลือด"
                        // error={errorInput.blood}
                        value={userInput.blood}
                        onChange={(event) => {
                          handleUserInputChange(event);
                        }}
                        // required
                        variant="standard"
                      /> */}

                        <FormControl error>
                          <InputLabel
                            id="labelId-skin"
                            sx={{ top: "8px", left: "-14px" }}
                          >
                            สภาพผิว
                          </InputLabel>
                          <Select
                            labelId="labelId-skin"
                            id="skin"
                            displayEmpty
                            className="w-full text-[20px] mt-[10px]"
                            IconComponent={KeyboardArrowDownIcon}
                            value={userInput.skin}
                            onChange={(event) => {
                              handleUserInputChange(event);
                            }}
                            error={false}
                            helperText="สภาพผิว"
                            fullWidth
                            name="skin"
                            variant="standard"
                            multiple
                            renderValue={(selected) => {
                              console.log("selected >>", selected);
                              const sy = {
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              };
                              return (
                                <Box sx={sy}>
                                  {selected.map((value, i) => {
                                    console.log("selected >>", value);
                                    const txt = skinOption.find(
                                      (item) => item.id == value
                                    );
                                    console.log("selected txt >>", txt);
                                    return (
                                      <Chip
                                        key={i}
                                        label={txt.name}
                                        color="primary"
                                      />
                                    );
                                  })}
                                </Box>
                              );
                            }}
                          >
                            {skinOption &&
                              skinOption.map((item, i) => (
                                <MenuItem value={item.id} key={item.id}>
                                  <Checkbox
                                    checked={userInput.skin.includes(item.id)}
                                  />
                                  {item.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>

                        {/* <TextField
                        name="skin"
                        className="w-full text-[20px]"
                        label="สภาพผิว"
                        // error={errorInput.skin}
                        value={userInput.skin}
                        onChange={(event) => {
                          handleUserInputChange(event);
                        }}
                        // required
                        variant="standard"
                      /> */}

                        <TextField
                          name="remark"
                          className="w-full text-[20px]"
                          label="ข้อสังเกต"
                          // error={errorInput.remark}
                          value={userInput.remark}
                          onChange={(event) => {
                            handleUserInputChange(event);
                          }}
                          // required
                          variant="standard"
                        />
                      </div>

                      <div className="flex flex-col gap-[0px] mt-[24px]">
                        <div className="relative">
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={"th"}
                          >
                            <MobileDatePicker
                              className="w-full"
                              label="วันที่พาน้องหมาไปพบสัตวแพทย์"
                              variant="standard"
                              inputFormat="MM/DD/YYYY"
                              name="vaccineDate"
                              maxDate={dayjs()}
                              value={userInput.vaccineDate}
                              onChange={(value) => {
                                handleUserInputChange(
                                  null,
                                  "vaccineDate",
                                  value.toISOString()
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  error={errorInput.vaccineDate}
                                  helperText="วันที่พาน้องหมาไปพบสัตวแพทย์ไม่ถูกต้อง"
                                />
                              )}
                            />
                            <img
                              className={
                                `absolute right-[10px] bottom-[15px] w-[15px] pointer-events-none ` +
                                (errorInput.lastDateProduct
                                  ? "bottom-[35px]"
                                  : null)
                              }
                              src={calendar}
                              alt=""
                            />
                          </LocalizationProvider>
                        </div>
                        <div className="text-gray text-[12px] ">
                          เพื่อการป้องกัน เห็บ หมัด ไรขี้เรื้อนเปียก
                          ไรขี้เรื้อนแห้ง ไรหู พยาธิหนอนหัวใจ พยาธิปากขอ
                          และอื่นๆ
                        </div>
                      </div>

                      <div className="flex flex-col gap-[0px]">
                        <div className="relative">
                          <FormControl error className="w-full">
                            <InputLabel
                              id="labelId-vaccine"
                              sx={{ top: "8px", left: "-14px" }}
                            >
                              วัคซีนที่ฉีด
                            </InputLabel>
                            <Select
                              labelId="labelId-vaccine"
                              id="vaccine"
                              displayEmpty
                              className="w-full text-[20px] mt-[10px]"
                              IconComponent={KeyboardArrowDownIcon}
                              value={userInput.vaccine}
                              onChange={(event, newValue) => {
                                handleUserInputChange(
                                  null,
                                  "vaccine",
                                  event.target.value
                                );
                              }}
                              error={false}
                              helperText="สภาพผิว"
                              fullWidth
                              name="vaccine"
                              variant="standard"
                            >
                              {vaccineOption.map((item, i) => {
                                return (
                                  <MenuItem value={item.id} key={i}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      </div>

                      <div className="flex flex-col gap-[0px] mt-[24px]">
                        <p className="text-[24px] text-left">บันทึกประจำวัน</p>
                        <TextField
                          style={{ height: "auto" }}
                          name="diaryRecord"
                          className="w-full text-[20px] border border-indigo-600 text-area"
                          multiline
                          rows={3}
                          // maxRows={3}
                          label=""
                          error={errorInput.diaryRecord}
                          value={userInput.diaryRecord}
                          onChange={(event) => {
                            handleUserInputChange(event);
                          }}
                          required
                          variant="standard"
                        />
                      </div>

                      <button
                        onClick={createPetDaily}
                        className="mt-[24px] flex justify-center items-center bg-lightBlue2 rounded-[28px] w-full gap-[10px] py-[8px]"
                      >
                        <DogPaw width={20} color="#0A4197" />
                        <p className="text-[24px] text-blue text-center">
                          เขียนบันทึกสุขภาพประจำวัน
                        </p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
